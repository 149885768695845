import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Modal, Button, Spinner } from 'react-bootstrap';
import { isSadmin } from '../../utils';
import { useForm } from "react-hook-form";
import MaterialTable from 'material-table';
import {
    getFirestore,
    collection,
    getDocs,
    addDoc,
    deleteDoc,
    doc,
    setDoc,
    onSnapshot,
    query,
    where,
    orderBy, 
    startAfter, 
    limit
} from "firebase/firestore";
import { getCustomerName } from "../../utils";

function CCDemo(props: any) {
    const customerName = getCustomerName(props.user);
    const db = getFirestore();
    // const collectionName = "cash_txn";
    const collectionName = "txn_summary";
    const colRef = collection(db, collectionName);
    const [isLoading, setIsLoading] = useState(true);
    const [cardList, setCardList] = useState<any>([]);
    const [show, setShow] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    const [fromDate, setFromDate] = useState<any>("");
    const [toDate, setToDate] = useState<any>("");

    useEffect(() => {
        console.log("CC Demo");
        setIsLoading(true);
        loadData();
    }, [])

    const loadData = async () => {
        getDocs(colRef)
            .then((snapshot) => {
                console.log("FIREBASE: CCDemo page getDocs method: Success Response: ", snapshot.docs);
                let cardList: any = [];
                snapshot.docs.forEach((doc) => {
                    cardList.push({ ...doc.data(), id: doc.id })
                });
                console.log("FIREBASE: CCDemo page getDocs method: Final List ", cardList);
                setCardList(cardList);
                setIsLoading(false);
            })
            .catch(error => {
                console.log("Exceiption in CCDemo page getDocs method: ", error);
                setIsLoading(false);
            });

    }
    
    const generateStatusView = (row: any) => {
        if(row && row.time && row.time){
            var myDate = new Date(row.time*1000);
            return (<><div>{myDate.toLocaleString()}</div></>);
        }
    }


    const deviceColumns = [
        { title: '#', field: 'tableData.id', render: (rowData: any) => rowData.tableData.id + 1, headerStyle: { width: "5%" }, cellStyle: { width: "5%", maxWidth: "5%", overflow: "hidden" }, width: "5%" },
        { title: 'customer', field: 'customer', headerStyle: { width: "20%" }, cellStyle: { width: "25%", maxWidth: "25%", overflow: "hidden" }, width: "25%" },
        { title: 'cashTotal', field: 'cashTotal', headerStyle: { width: "20%" }, cellStyle: { width: "15%", maxWidth: "15%", overflow: "hidden" }, width: "15%" },        
        { title: 'id', field: 'id', headerStyle: { width: "25%" }, cellStyle: { width: "25%", maxWidth: "25%", overflow: "hidden" }, width: "25%" },
        { title: 'Date', field: 'date', headerStyle: { width: "25%" }, cellStyle: { width: "25%", maxWidth: "25%", overflow: "hidden" }, width: "25%",},
    ]

    return (
        <div className="page-heading">
            <div className="page-title">
                <div className="row">
                    <div className="col-12 col-md-6 order-md-1 order-last">
                        <h3 className='vv-color-code'>Demo-only for development</h3>
                    </div>
                    <div className="col-12 col-md-6 order-md-2 order-first">
                        <nav aria-label="breadcrumb" className="breadcrumb-header float-start float-lg-end">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Demo</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">CC Demo / Reports</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>

            <section id="form-and-scrolling-components">
                <Modal show={isLoading} aria-labelledby="contained-modal-title-vcenter"
                    centered backdrop="static"
                    keyboard={false}>
                    <Modal.Body> <Spinner animation="border" /> Loading... </Modal.Body>
                </Modal>

            </section>

            <section className="section">
            <div className='row' style={{width:"100%",color:"red",textAlign:"center"}}>
                {errorMsg}
            </div>
                <div className='report-device-table'>
                    <MaterialTable title=""
                        data={cardList}
                        columns={deviceColumns}
                        options={{
                            sorting: true,
                            actionsColumnIndex: -1,
                            headerStyle: { position: 'sticky', top: 0, fontWeight: 'bold', fontSize: 15, color: "#6c757d" },
                            maxBodyHeight: 700,
                            paging: true,
                            pageSize: 10,
                            pageSizeOptions: [10, 20, 50],
                            emptyRowsWhenPaging: false,
                            columnResizable: true,
                            rowStyle: {
                                fontSize: 15,
                                font: "var(--bs-font-sans-serif)"
                            }
                        }} />
                </div>
            </section>

        </div>
    );
};
function mapStateToProps(state: any) {
    const { isLoggedIn } = state.client.isLoggedIn;
    const { message } = state.messages;
    return {
        isLoggedIn,
        user: state.client.user,
        message
    };
}

export default connect(mapStateToProps)(CCDemo);