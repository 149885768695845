import React, { Suspense, lazy, useState } from 'react';
// import Cookies from 'universal-cookie';
import Cookies from 'js-cookie';
import { Route, Routes, useNavigate, useLocation, Outlet } from 'react-router-dom';
import Home from '../components/pages/Home';
import Login from '../components/pages/Login';
import ForgotPassword from '../components/pages/ForgotPassword';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import Register from '../components/pages/Register';
import { logout } from '../redux/actions/auth.action';
import LoggedInRoute from '../routes/LoggedInRoute';
import LoggedOutRoute from "../routes/LoggedOutRoute";
import { auth } from '../utils/firebaseUtils';
import { LOGIN_SUCCESS } from '../redux/types';
import jwt_decode from 'jwt-decode'
import Devices from '../components/pages/Devices';
import Users from '../components/pages/Users';
import Downloads from '../components/pages/Downloads';
import Reports from '../components/pages/Reports';
import Header from '../components/shared/Header';
import { getUserDetails } from '../services/main-service';
import CCManagement from '../components/pages/CCManagement';
import CCMessage from '../components/pages/CCMessage';
import CCReports from '../components/pages/CCReports';
import CCDemo from '../components/pages/CCDemo';
import Diagnose from '../components/pages/Diagnose';

const NotFound = lazy(() => import('../components/pages/NotFound'));
const AccessDenied = lazy(() => import('../components/pages/AccessDenied'));

const Pages = (props: any) => {
  const { isAuthenticated, messages, children } = props;
  const { dispatch } = props;
  const [currentUser, setCurrentUser] = useState(null)
  const navigate = useNavigate()


  useEffect(() => {
    console.log('VV: PAGES isAuthenticated: ', isAuthenticated)
    if (isAuthenticated == false) {
      navigate("/login");
    }
    else if(isAuthenticated == true){
      try
      {
        console.log("VV: Available Cookies-List: ",Cookies.get());
        let cookiesList = Cookies.get();
        console.log("VV: Available application cookies-List: ",cookiesList);
        if(cookiesList && cookiesList['SID']){
          console.log("VV: SID is available and Value-is: ",cookiesList['SID']);
        }
        else{
          console.log("VV: SID Value is not-Available");
          // navigate("/login");
        }
      }
      catch(error){
        console.log("VV: EXCEPTION IN PAGES-COOKIES: ",error);
      }
    }
  }, []);

  // useEffect(() => {
    // var cookie = new Cookies();
    // let sessionCookie = cookie.get('__session');
    // console.log("PAGES: Cookie in useEffect Method: ", sessionCookie);
    // if (sessionCookie) {
    //   console.log("PAGES: Cookie Available");
    //   let userDetail = getUserDetails();
    //   if(userDetail){
    //     dispatch({
    //       type: LOGIN_SUCCESS,
    //       payload: { user: userDetail },
    //     });
    //   }
    // }
    // else{
    //   console.log("PAGES: Cookie not Available");
    // }

    // auth.onAuthStateChanged(async (userAuth: any) => {
    //   try {
    //     // If a user exists or signs in
    //     if (userAuth) {
    //       console.log("USER AVAILABLE");
    //       console.log('userAuth', userAuth, userAuth.multiFactor, userAuth.multiFactor.user, userAuth.multiFactor.user.accessToken);
    //       let decodeData: any = jwt_decode(userAuth.multiFactor.user.accessToken);
    //       console.log(decodeData);
    //       let userDetail = { userDetail: userAuth.multiFactor.user, decodeData: decodeData }
    //       if (!decodeData.email_verified) {
    //         dispatch(logout());
    //       }
    //       else {
    //         var cookie = new Cookies();
    //         console.log("cookie name in onauthState Changed: ", cookie.get('__session'));
    //         dispatch({
    //           type: LOGIN_SUCCESS,
    //           payload: { user: userDetail },
    //         });
    //         setCurrentUser(userAuth);
    //       }
    //     }
    //     //If there is no user present
    //     else {
    //       //Seting current user to null
    //       setCurrentUser(null);
    //       dispatch(logout());
    //     }
    //   } catch (error) {
    //     console.log(error);
    //   }
    // });
  // }, [])
  return (
    <Routes >
      <Route element={<LoggedInRoute />}>
        <Route path="" element={<Home />} />
        <Route path="home" element={<Home />} />
        <Route path="devices" element={<Devices />} />
        <Route path="users" element={<Users />} />
        <Route path="downloads" element={<Downloads />} />
        <Route path="reports" element={<Reports />} />
        <Route path="diagnose" element={<Diagnose />} />
        <Route path="ccmanagement" element={<CCManagement />} />
        <Route path="ccmessages" element={<CCMessage />} />
        <Route path="ccreports" element={<CCReports />} />
        <Route path="ccdemo" element={<CCDemo />} />
      </Route>
      <Route element={<LoggedOutRoute />}>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Register />} />
        <Route path="/forget-password" element={<ForgotPassword />} />
      </Route>

      <Route path="/AccessDenied" element={<AccessDenied />} />
      <Route path="*" element={<NotFound />} />
    </Routes >
  );
};

const mapStateToProps = (state: any) => {
  // console.log('VV: Pages loggedin pages', state);
  // // var cookie = new Cookies();
  // // console.log("cookie name: ", cookie.get('__session'));
  // try
  // {
  //   // let allCookies = document.cookie;
  //   console.log("COOKIES SID: ",Cookies.get('SID'));
  //   console.log("All COOKIES: ",Cookies.get());
  // }
  // catch(error){
  //   console.log("EXCEPTION IN COOKIES-ERROR: ",error);
  // }


  return {
    isAuthenticated: state.client.isLoggedIn,
    decodeData: state.client?.user?.decodeData,
    messages: state.messages
  };
};

export default connect(mapStateToProps)(Pages);
