import { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import { connect } from 'react-redux';
import { getData, postData, deleteData, getSessionDetails } from '../../services/main-service';
import { Link } from 'react-router-dom';
import { Modal, Button, Spinner } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import Swal from 'sweetalert2'
import { logout } from "../../redux/actions/auth.action";

function Users(props: any) {
    const [userList, setUserList] = useState<any>([]);
    const [makeList, setMakeList] = useState<any>({});
    const [show, setShow] = useState(false);
    const [currentuser, setCurrentuser] = useState<any>({})
    const [isLoading, setIsLoading] = useState(true);
    const patternValue = /^[ A-Za-z0-9_+-]*$/
    const handleClose = () => setShow(false);
    // const [isAdmin, setIsAdmin] = useState(false);
    // const [isCC, setIsCC] = useState(false);
    const { register, handleSubmit, setValue, watch, formState: { errors } }: any = useForm(
        {
            mode: "onChange",
        }
    );

    const edituser = async (user: any) => {
        let claimRes: any = await getData('/uclaims/' + user.uid);
        if (claimRes.status == 200) {
            user['claim'] = claimRes.data.claim;
        }
        setCurrentuser(user);
        setValue("customer", user.claim.customer)
        setValue("sadmin", user.claim.sadmin)
        setValue("cc", user.claim?.cc) //set cc instead of sadmin
        setValue("layout", user.claim?.layout)
        setShow(true);
    }

    const deletUser = (user: any) => {
        let index: number = user.tableData.id;
        Swal.fire({
            title: `Do you want to delete ${user.name}?`,
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: 'Delete',
            denyButtonText: `Don't Delete`,
        }).then(async (result: any) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                let res: any = await deleteData('/user/' + user.uid);
                if (res.status == 200) {
                    loadData()
                    Swal.fire('Deleted!', '', 'success')
                }
                else
                    Swal.fire('Somthing went wrong', '', 'error')
            } else if (result.isDenied) {
                Swal.fire('Delete cancelled', '', 'info')
            }
        });
    }

    const onSubmit = async (data: any) => {
        delete (data['name'])
        data['uid'] = currentuser['uid'];
        let res = await postData('/user/update', data);
        Swal.fire({
            title: `Successfully saved`,

        }).then(async (result) => {
            setShow(false);
        });
    }

    useEffect(() => {
        loadData();
    }, [])
    const loadData = async () => {
        let isCookieAvailable: any = await getSessionDetails();
        if(!isCookieAvailable){
            props.dispatch(logout());
        }
        setIsLoading(true)
        let res: any = await getData('/ulist');
        let list_res: any = await getData('/list_makes');
        if (res.status == 200 && Array.isArray(res.data))
            setUserList(res.data);
        if (list_res.status == 200)
            setMakeList(list_res.data)
        setIsLoading(false)
    }

    const usersColumns = [
        { title: '#', field: 'tableData.id', render: (rowData: any) => rowData.tableData.id + 1 },
        { title: 'UID', field: 'uid', cellStyle: { cellWidth: 20, width: '20px' } },
        { title: 'Name', field: 'name', cellStyle: { minWidth: 175, maxWidth: 175 } },
        { title: 'Email', field: 'email', cellStyle: { minWidth: 225, maxWidth: 225 } },
        { title: 'Verified', field: 'email_verified', cellStyle: { minWidth: 50, maxWidth: 50 } }
    ]

    return (
        <div className="page-heading">
            <div className="page-title">
                <div className="row">
                    <div className="col-12 col-md-6 order-md-1 order-last">
                        <h3 className='vv-color-code'>Users</h3>
                    </div>

                    <div className="col-12 col-md-6 order-md-2 order-first">
                        <nav aria-label="breadcrumb" className="breadcrumb-header float-start float-lg-end">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">users</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-6 col-lg-3 col-md-6">
                    <div className="card">
                        <div className="card-body px-3">
                            <div className="row">
                                <div className="col-md-8">
                                    <h6 className="text-muted font-semibold">Total</h6>
                                    <h6 className="font-extrabold mb-0">{userList.length}</h6>
                                </div>
                                <div className="col-md-4">
                                    <i className="bi bi-people-fill card-icon color-user-total"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-lg-3 col-md-6">
                    <div className="card">
                        <div className="card-body px-3">
                            <div className="row">
                                <div className="col-md-8">
                                    <h6 className="text-muted font-semibold">Verified</h6>
                                    <h6 className="font-extrabold mb-0">{userList.filter(function (element: any) {
                                        return element.email_verified;
                                    }).length}</h6>
                                </div>
                                <div className="col-md-4">
                                    <i className="bi bi-person-check-fill card-icon color-user-verified"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-lg-3 col-md-6">
                    <div className="card">
                        <div className="card-body px-3">
                            <div className="row">

                                <div className="col-md-8">
                                    <h6 className="text-muted font-semibold">Unverified</h6>
                                    <h6 className="font-extrabold mb-0">{userList.filter(function (element: any) {
                                        return !element.email_verified;
                                    }).length}</h6>
                                </div>
                                <div className="col-md-4">
                                    <i className="bi bi-person-dash-fill card-icon color-user-unverified"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-lg-3 col-md-6">
                    <div className="card">
                        <div className="card-body px-3">
                            <div className="row">
                                <div className="col-md-8">
                                    <h6 className="text-muted font-semibold">Blocked</h6>
                                    <h6 className="font-extrabold mb-0">0</h6>
                                </div>
                                <div className="col-md-4">
                                    <i className="bi bi-person-x-fill card-icon color-user-blocked"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="section">
                <div className='col-12 text-end'>
                    <Button variant="primary" onClick={loadData} >Refresh</Button>
                </div>

                <div style={{ width: "100%" }}>
                    <MaterialTable title=""
                        data={userList}
                        columns={usersColumns}
                        actions={[
                            {
                                icon: 'edit',
                                tooltip: 'Edit',
                                onClick: (event, rowData) => edituser(rowData)
                            },
                            {
                                icon: 'delete',
                                tooltip: 'Delete',
                                onClick: (event, rowData) => deletUser(rowData)
                            }
                        ]}
                        options={{
                            sorting: true,
                            actionsColumnIndex: -1,
                            headerStyle: { position: 'sticky', top: 0, fontWeight: 'bold', fontSize: 15, color:"#6c757d" },
                            maxBodyHeight: 700,
                            paging: true,
                            pageSize: 10,
                            pageSizeOptions: [10, 20, 50],
                            emptyRowsWhenPaging: false,
                            rowStyle: {
                                fontSize: 15,
                                font: "var(--bs-font-sans-serif)"
                            }
                        }} />
                </div>
            </section>

            <section id="form-and-scrolling-components">
                <Modal show={isLoading} aria-labelledby="contained-modal-title-vcenter"
                    centered backdrop="static"
                    keyboard={false}>
                    <Modal.Body> <Spinner animation="border" /> Loading... </Modal.Body>
                </Modal>

                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit user</Modal.Title>
                    </Modal.Header>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Modal.Body>
                            <div className="modal-body">

                                <label>Customer: </label>
                                <div className="form-group">

                                    <input type="text" placeholder="Customer"
                                        className="form-control"  {...register("customer", {

                                            pattern: {
                                                value: patternValue,
                                                message: "Special charecter not allowd"
                                            }
                                        })} />

                                    {errors.customer ? <span className='text-danger'>{errors.customer?.message}</span> : ''}
                                </div>
                                <div className='row'>
                                    <div className='col-4'>
                                        <label>Admin:  &nbsp; <input name="diabetes" type="checkbox" id="diabetes" {...register('sadmin')} /></label>
                                    </div>
                                    <div className='col-4'>
                                        <label>Contact less Card &nbsp; <input name="diabetes" type="checkbox" id="diabetes" {...register('cc')} /></label> 
                                    </div>
                                    <div className='col-4'>
                                        <label>Layout &nbsp; <input name="diabetes" type="checkbox" id="diabetes" {...register('layout')} /></label> 
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button variant="primary" type='submit'>
                                Save Changes
                            </Button>
                        </Modal.Footer>
                    </form>
                </Modal>
            </section>
        </div>);
};
function mapStateToProps(state: any) {
    const { isLoggedIn } = state.client.isLoggedIn;
    const { message } = state.messages;
    return {
        isLoggedIn,
        message
    };
}

export default connect(mapStateToProps)(Users); 