import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { auth } from '../../utils/firebaseUtils';
function ForgotPassword(props: any) {
    const { dispatch } = props;
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errMsg, setErrMsg] = useState('');

    function isValidEmail(email: any) {
        return /\S+@\S+\.\S+/.test(email);
    }

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        if (!isValidEmail(email)) {
            setErrMsg('Email is invalid');
            return false;
        } else {
            setErrMsg('');
        }
        // const { email, password } = this.state;
        auth.sendPasswordResetEmail(email).then(function () {
            console.log("Link has been sent sucessfully");
            setErrMsg("Check your email for reset link");
            setEmail('');
        }).catch(function (error: any) {
            console.log("Exception: ", error);
            setErrMsg("Unable to send link");
        });
    };
    return (
        <div className="row h-100 mt-4">
            <div className="col-lg-4 d-none d-lg-block"></div>
            <div className="col-lg-4 col-12">
                <div id="auth-left">
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <div>
                            <div className="form-group position-relative has-icon-left mb-4">
                                <input required type="text" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control form-control-xl" placeholder="Email" />
                                <div className="form-control-icon">
                                    <i className="bi bi-person"></i>
                                </div>
                            </div>
                            {errMsg != '' ? <div className="form-group position-relative has-icon-left mb-4">
                                <span style={{ color: "#f00000" }}>{errMsg}</span>
                            </div> : ''}

                            <button className="btn btn-primary btn-block btn-lg shadow-lg">Send Reset</button>
                        </div>
                    </form>
                    <div className="text-center mt-5 text-lg fs-4">
                        <p className="text-gray-600">Don't have an account? <Link to="/signup">Sign up</Link>.</p>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 d-none d-lg-block">
                <div id="auth-right">

                </div>
            </div>
        </div>
    );
}

function mapStateToProps(state: any) {
    const { isLoggedIn } = state.client.isLoggedIn;
    const { message } = state.messages;
    return {
        isLoggedIn,
        message
    };
}

export default connect(mapStateToProps)(ForgotPassword); 
