import React from "react";
import { connect } from "react-redux";
import { useNavigate, useLocation, useParams, Outlet } from "react-router-dom";
import Alert from '../components/modules/Alert'
import { Footer } from '../components'

const LoggedOutRoute = (props: any) => {
  const { isAuthenticated, messages, children } = props;
  const navigate = useNavigate()
  const location = useLocation()

  if (isAuthenticated === true) {
    navigate("/");
  }

  const loadAlert = () => {
    if (typeof messages.message === 'string')
      return (<Alert variant={messages.variant} show={messages.show} message={messages.message} />)
    else {
      const listItems = Array.isArray(messages.message) && messages.message.map((msg: any, id: any) => <Alert key={id} variant={messages.variant} show={messages.show} message={msg} />);
      return listItems;
    }
  }

  return (
    <>
      <div id="auth" className="container">
        <div className="text-center mt-4">
          <img src="/assets/images/logo/logo.png" width={400}></img>
        </div>
        {loadAlert()}
        {children ? children : <Outlet />}
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    isAuthenticated: state.client.isLoggedIn,
    user: state.client.user,
    messages: state.messages
  }
};

export default connect(
  mapStateToProps
)(LoggedOutRoute);
