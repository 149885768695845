import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getData, getSessionDetails } from '../../services/main-service';
import { Link } from 'react-router-dom';
import { Modal, Button, Spinner } from 'react-bootstrap';
import { isSadmin } from '../../utils';
import { logout } from "../../redux/actions/auth.action";
import Swal from 'sweetalert2'
import { useForm } from "react-hook-form";
import MaterialTable from 'material-table';
import {
    getFirestore,
    collection,
    getDocs,
    addDoc,
    deleteDoc,
    doc,
    setDoc,
    query,
    where,
    orderBy,
    startAfter,
    limit,
    onSnapshot
} from "firebase/firestore";
import { getCustomerName } from "../../utils";
import * as XLSX from 'xlsx'

function CCMessage(props: any) {
    const customerName = getCustomerName(props.user);
    const db = getFirestore();
    const collectionName = customerName + "_messages";
    const colRef = collection(db, collectionName);
    const [isLoading, setIsLoading] = useState(true);
    const [cardList, setCardList] = useState<any>([]);
    const [deviceList, setDeviceList] = useState<any>([]);
    const [messageLimit, setmessageLimit] = useState(100);

    useEffect(() => {
        setIsLoading(true);
        loadDeviceData();
    }, [])

    const handleChange = (e:any) => {
        setmessageLimit(e.target.value);
      };

    const loadDeviceData = async () => {
        let isCookieAvailable: any = await getSessionDetails();
        if (!isCookieAvailable) {
            props.dispatch(logout());
        }
        // setIsLoading(true);
        let res: any = await getData('/devices');
        if (res.status == 200 && Array.isArray(res.data)) {
            setDeviceList(res.data);
            setTimeout(() => {
                loadData(res.data);    
            }, 1000);
        }
        // setIsLoading(false);
    }

    const loadData = async (device_List:any) => {
        try {
            const q = query(colRef, orderBy("dateTime", "desc"), limit(messageLimit));
            const unsubscribe = onSnapshot(q, (snapshot) => {
                let temp_cardList: any = [];
                snapshot.docChanges().forEach((change) => {
                    // console.log("change: ", change);
                });
                refreshGrid(device_List);
            },
                (error) => {
                    setIsLoading(false);
                    console.log("Exception in loadData: ", error);
                });
        }
        catch (error) {
            setIsLoading(false);
            console.log("EXCEPTION in loadData Method-Error: ", error);
        }
    }

    const refreshGrid = async (device_List:any) => {
        const first = query(colRef, orderBy("dateTime", "desc"), limit(messageLimit));
        getDocs(first)
            .then((snapshot) => {
                console.log("FIREBASE: Message Response: ", snapshot.docs);
                let cardList: any = [];
                snapshot.docs.forEach((doc) => {
                    cardList.push({ ...doc.data(), id: doc.id })
                });
                cardList.forEach((element:any) => {
                    let test = element['posId'].split('_')[1];
                    var res = device_List.find((device:any) => device.name.toString() === test.toString());
                    element["Location"] = res?.location?res.location:"";
                });
                setCardList(cardList);
                setIsLoading(false);
            })
            .catch(error => {
                console.log("FIREBASE: Exceiption in refreshGrid method: Error is: ", error);
                setIsLoading(false);
            });
    }

    const generateStatusView = (data: any) => {
        if (data && data.dateTime && data.dateTime.seconds) {
            var myDate = new Date(data.dateTime.seconds * 1000);
            return (<><div>{myDate.toLocaleString()}</div></>);
        }
    }

    const refreshData = (data: any) => {
        setIsLoading(true);
        loadData(deviceList);
    }

    const downloadData = async() =>{
        try{
            setIsLoading(true);
            let msgList = [];
            if(cardList.length>0){
                msgList = cardList;
                msgList.forEach((element:any) => {
                    let rowData = element;
                    delete rowData.expires;
                    delete rowData.tableData;
                    delete rowData.id;
                    delete rowData.customer;
                    if (rowData && rowData.dateTime && rowData.dateTime.seconds) {
                        var myDate = new Date(rowData.dateTime.seconds * 1000);
                        rowData["dateTime"] = myDate.toLocaleString();
                    }
                });
                var myFile = customerName + "_message.xlsx";
                var myWorkSheet = XLSX.utils.json_to_sheet(cardList);
                var myWorkBook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(myWorkBook, myWorkSheet, "SHEET1");
                XLSX.writeFile(myWorkBook, myFile);
                setIsLoading(false);
            }
        }
        catch(error){
            setIsLoading(false);
            console.log("Exception: Download method invoked-Error: ",error);
        }
    }

    const deviceColumns = [
        { title: '#', field: 'tableData.id', render: (rowData: any) => rowData.tableData.id + 1, headerStyle: { width: "5%" }, cellStyle: { width: "5%", maxWidth: "5%", overflow: "hidden" }, width: "5%" },
        { title: 'Date and Time', field: 'dateTime', sorting: false, render: (rowData: any) => generateStatusView(rowData), headerStyle: { width: "25%" }, cellStyle: { width: "25%", maxWidth: "25%", overflow: "hidden" }, width: "25%" },
        { title: 'Event Type', field: 'Type', headerStyle: { width: "15%" }, cellStyle: { width: "15%", maxWidth: "15%", overflow: "hidden" }, width: "15%" },
        { title: 'Card ID', field: 'cardId', headerStyle: { width: "15%" }, cellStyle: { width: "15%", maxWidth: "15%", overflow: "hidden" }, width: "15%" },
        { title: 'Pos ID', field: 'posId', headerStyle: { width: "15%" }, cellStyle: { width: "15%", maxWidth: "15%", overflow: "hidden" }, width: "15%" },
        { title: 'Location', field: 'Location', sorting: false, headerStyle: { width: "15%" }, cellStyle: { width: "15%", maxWidth: "15%", overflow: "hidden" }, width: "15%" },
    ]

    return (
        <div className="page-heading">
            <div className="page-title">
                <div className="row">
                    <div className="col-12 col-md-6 order-md-1 order-last">
                        <h3 className='vv-color-code'>Messages</h3>
                    </div>
                    <div className="col-12 col-md-6 order-md-2 order-first">
                        <nav aria-label="breadcrumb" className="breadcrumb-header float-start float-lg-end">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">CC Management / Messages</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>

            <section id="form-and-scrolling-components">
                <Modal show={isLoading} aria-labelledby="contained-modal-title-vcenter"
                    centered backdrop="static"
                    keyboard={false}>
                    <Modal.Body> <Spinner animation="border" /> Loading... </Modal.Body>
                </Modal>
            </section>

            <section className="section">
                <div className='common-row'>
                    <div className='limit' title="Download" onClick={downloadData}><i className="bi bi-download" style={{ verticalAlign: "middle" }}></i></div>
                    <div className='refresh' title="Refresh" onClick={() => refreshData({})} ><i className="bi bi-arrow-repeat"></i></div>
                    <div className='download'><span>Select Limit</span><select value={messageLimit} onChange={handleChange}>
                            <option value="100">100</option>
                            <option value="500">500</option>
                            <option value="1000">1000</option>
                            <option value="1500">1500</option>
                            <option value="2000">2000</option>
                        </select>
                    </div>
                </div>
                <div className='report-device-table'>
                    <MaterialTable title=""
                        data={cardList}
                        columns={deviceColumns}
                        options={{
                            sorting: true,
                            actionsColumnIndex: -1,
                            headerStyle: { position: 'sticky', top: 0, fontWeight: 'bold', fontSize: 15, color: "#6c757d" },
                            maxBodyHeight: 700,
                            paging: true,
                            pageSize: 10,
                            pageSizeOptions: [10, 20, 50],
                            emptyRowsWhenPaging: false,
                            columnResizable: true,
                            rowStyle: {
                                fontSize: 15,
                                font: "var(--bs-font-sans-serif)"
                            }
                        }} />
                </div>
            </section>
        </div>
    );
};
function mapStateToProps(state: any) {
    const { isLoggedIn } = state.client.isLoggedIn;
    const { message } = state.messages;
    return {
        isLoggedIn,
        user: state.client.user,
        message
    };
}

export default connect(mapStateToProps)(CCMessage); 