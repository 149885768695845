import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getData, getSessionDetails } from '../../services/main-service';
import { Link } from 'react-router-dom';
import { Modal, Spinner } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import { logout } from "../../redux/actions/auth.action";
import { storage } from '../../utils/firebaseUtils';
import { getCustomerName } from "../../utils";

const MONTHS =["Jan","Feb","Mar","Apr","May","Jun","July","Aug","Sep","Oct","Nov","Dec"]

function Downloads(props: any) {
    const customerName = getCustomerName(props.user);
    const [reportList, setReportList] = useState<any>([]);
    const [selectedReportList, setSelectedReportList] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        loadReportList();
    }, []);

    const loadReportList = async () =>{
        let isCookieAvailable: any = await getSessionDetails();
        if(!isCookieAvailable){
            props.dispatch(logout());
        }
        const storageRef = storage.ref();
        var listRef = storageRef.child(customerName);    
        // Find all the prefixes and items.
        let listOfReports:any = [];
        listRef.listAll()
            .then((res) => {
                res.prefixes.forEach((folderRef) => {
                    let report = {bucket:folderRef.bucket, fullPath: folderRef.fullPath, name: folderRef.name}
                    listOfReports.push(report);
                });
                setReportList(listOfReports);
                if(listOfReports.length>0){
                    getIndividualReports(listOfReports[0]);
                }
                setIsLoading(false);
            }).catch((error:any) => {
                setIsLoading(false);
                console.log("Exception occured in loadReportList method's listAll() API: ",error);
            });
    }

    const getIndividualReports = (report:any) =>{
        setIsLoading(true);
        const storageRef = storage.ref();
        var listRef = storageRef.child(report.fullPath);
        // Find all the prefixes and items.
        let listOfReports:any = [];
        listRef.listAll()
            .then((res) => {
                res.items.forEach((itemRef) => {
                    let newRec = {fullPath: report.fullPath+"/"+itemRef.name, bucket:itemRef.bucket, name: itemRef.name, size:"", updated: ""}
                    listOfReports.push(newRec);
                });
                //To get meta data
                let totalRecord = listOfReports.length;
                let count = 0;
                let tempRecordList:any = [];
                listOfReports.forEach((element:any) => {
                    var forestRef = storageRef.child(report.fullPath + "/" + element.name);
                    // Get metadata properties
                    forestRef.getMetadata()
                    .then((metadata) => {
                        count++;
                        let availableRecord = listOfReports.find((el:any) => el.fullPath === metadata.fullPath);
                        availableRecord.size = formatBytes(metadata.size);
                        var t = new Date(metadata.timeCreated);
                        var formatted = t.getDate()+"/"+MONTHS[(t.getMonth())]+"/"+t.getFullYear()+" "+(('0' + t.getHours()).slice(-2))+":"+(('0' + t.getMinutes()).slice(-2))+":"+(('0' + t.getSeconds()).slice(-2));
                        availableRecord.updated = formatted;
                        tempRecordList.push(availableRecord);
                        if(count == totalRecord){
                            let clearList: any = [];
                            setSelectedReportList(clearList);
                            setSelectedReportList(tempRecordList);
                        }
                        setIsLoading(false);
                    })
                    .catch((error) => {
                        setIsLoading(false);
                        console.log("Exception on getMetadata: ",error);
                        let clearList: any = [];
                        setSelectedReportList(clearList);
                        setSelectedReportList(tempRecordList);
                    });
                });                
            }).catch((error) => {
                setIsLoading(false);
                console.log("Exception occured in listAll API: ",error);
            });    
    }

    const downloadReport = async(record:any)=>{
        setIsLoading(true);
        const storageRef = storage.ref();
        // Create a reference to the file we want to download
        // var starsRef = storageRef.child("dev/monthly-report/April-2023.csv");
        var starsRef = storageRef.child(record.fullPath);
        // Get the download URL
        starsRef.getDownloadURL()
        .then((url) => {
          // Insert url into an <img> tag to "download"
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', record.name);
            document.body.appendChild(link);
            link.click();
            setIsLoading(false);
        })
        .catch((error) => {
            setIsLoading(false);
          switch (error.code) {
            case 'storage/object-not-found':
              console.log("EXCEPTION in DownloadReport method and error code is: ", error.code);
              break;
            case 'storage/unauthorized':
                console.log("EXCEPTION in DownloadReport method and error code is: ", error.code);
              break;
            case 'storage/canceled':
                console.log("EXCEPTION in DownloadReport method and error code is: ", error.code);
              break;
            case 'storage/unknown':
                console.log("EXCEPTION in DownloadReport method and error code is: ", error.code);
              break;
          }
        });
    }

    const formatBytes = (bytes: any, decimals = 2) => {
        if (bytes === 0) {
            return '0 Bytes';
        }
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    return (
        <div className="page-heading">
            <div className="page-title">
                <div className="row">
                    <div className="col-12 col-md-6 order-md-1 order-last">
                        <h3 className='vv-color-code'>Downloads</h3>
                    </div>
                    <div className="col-12 col-md-6 order-md-2 order-first">
                        <nav aria-label="breadcrumb" className="breadcrumb-header float-start float-lg-end">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Downloads</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>

            <section id="form-and-scrolling-components">
                <Modal show={isLoading} aria-labelledby="contained-modal-title-vcenter"
                    centered backdrop="static"
                    keyboard={false}>
                    <Modal.Body> <Spinner animation="border" /> Loading... </Modal.Body>
                </Modal>
            </section>

            <div className='DownloadList'>
                <Accordion defaultActiveKey="0">
                    {
                        reportList.map((val: any, inx: number) =>
                            <Accordion.Item eventKey={(inx).toString()}>
                                <Accordion.Header onClick={() => getIndividualReports(val)}>{val.name.toUpperCase()}</Accordion.Header>
                                <Accordion.Body>
                                    <table id="downloadItems" style={{ width: "100%", minWidth: "100%" }}>
                                        <thead style={{ width: "100%", minWidth: "100%" }} className="vv-color-code">
                                            <tr style={{ width: "100%", minWidth: "100%" }}>
                                                <th style={{ width: "40%" }}>File Name</th>
                                                <th style={{ width: "40%" }}>Date Time</th>
                                                <th style={{ width: "20%" }}>Size</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                selectedReportList.map((rec: any) =>
                                                    <tr>
                                                        <td>
                                                            <a href='#' onClick={() => downloadReport(rec)} title='Click to download'>{rec.name}</a>
                                                        </td>
                                                        <td>
                                                            {rec.updated}
                                                        </td>
                                                        <td>
                                                            {rec.size}
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </Accordion.Body>
                            </Accordion.Item>
                        )}
                </Accordion>
            </div>            
        </div>
    );
};
function mapStateToProps(state: any) {
    const { isLoggedIn } = state.client.isLoggedIn;
    const { message } = state.messages;
    return {
        isLoggedIn,
        user: state.client.user,
        message
    };
}

export default connect(mapStateToProps)(Downloads); 