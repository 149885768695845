import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getData, getSessionDetails } from '../../services/main-service';
import { Link } from 'react-router-dom';
import { Modal, Button, Spinner } from 'react-bootstrap';
import MaterialTable from 'material-table';
import { logout } from "../../redux/actions/auth.action";
import _ from 'lodash';

function Reports(props: any) {
    const [isLoading, setIsLoading] = useState(true);
    const [deviceList, setDeviceList] = useState<any>([]);
    const [selectedDevice, setSelectedDevice] = useState<any>([]);
    const [reportList, setReportList] = useState<any>([]);
    const [fromDate, setFromDate] = useState<any>("");
    const [toDate, setToDate] = useState<any>("");
    const [reportTotal, setReportTotal] = useState(0);
    const [reportDigital, setReportDigital] = useState(0);
    const [reportCash, setReportCash] = useState(0);
    const [reportRefund, setReportRefund] = useState(0);
    const [errormsg, setErrorMsg] = useState("");
    const [ selectedSummaryGroupBy, setSelectedSummaryGroupBy] = useState("DATE");
    const [summaryCBXValue, setSummaryCBXValue] = useState(false);
    const [showDeviceTable, setShowDeviceTable] = useState(true);
    const [summaryTableData, setSummaryTableData] = useState<any>([]);

    useEffect(() => {
        clearData();
        loadDeviceData();
    }, [])

    const clearData = () => {
        setReportTotal(0);
        setReportDigital(0);
        setReportCash(0);
        setReportRefund(0);
        let clearList: any = [];
        setReportList(clearList);
        setSummaryTableData(clearList);
        setSelectedDevice("");
        setFromDate("");
        setToDate("");
        setErrorMsg("");
        setSummaryCBXValue(false);
        setShowDeviceTable(true);
    }
    const loadDeviceData = async () => {
        let isCookieAvailable: any = await getSessionDetails();
        if (!isCookieAvailable) {
            props.dispatch(logout());
        }
        setIsLoading(true);
        let res: any = await getData('/devices');
        if (res.status === 200 && Array.isArray(res.data)) {
            const strAscending = [...res.data].sort((a, b) =>
                a.name > b.name ? 1 : -1,
            );
            setDeviceList(strAscending);
        }
        setIsLoading(false);
    }

    const disablePastDate = () => {
        var today = new Date();
        var priorDate = new Date(new Date().setDate(today.getDate() - 90));
        const dd = String(priorDate.getDate() + 1).padStart(2, "0");
        const mm = String(priorDate.getMonth() + 1).padStart(2, "0"); //January is 0!
        const yyyy = priorDate.getFullYear();
        return yyyy + "-" + mm + "-" + dd;
    };

    const disableFeatureDate = () => {
        var today = new Date();
        const dd = String(today.getDate() + 0).padStart(2, "0");
        const mm = String(today.getMonth() + 1).padStart(2, "0");
        const yyyy = today.getFullYear();
        return yyyy + "-" + mm + "-" + dd;
    };

    const handleRadioChange = (val:any) => {
        setSelectedSummaryGroupBy(val);
        setSummaryTableData([]);
    }

    const loadReportData = async () => {
        try {
            if (selectedDevice === "" && summaryCBXValue === false) {
                setErrorMsg("Please select Summary or Device");
                return false;
            }
            if (fromDate === "") {
                setErrorMsg("Please select from date");
                return false;
            }
            if (toDate === "") {
                setErrorMsg("Please select to date");
                return false;
            }
            let fDate = new Date(fromDate);
            let tDate = new Date(toDate);
            if (fDate > tDate) {
                setErrorMsg("From date should less than to date");
                return false;
            }
            setErrorMsg("");
            if (summaryCBXValue) {
                setIsLoading(true);
                setShowDeviceTable(false);
                let url = "/txns/trend?fromdate=" + fromDate.replaceAll('-', '') + "&todate=" + toDate.replaceAll('-', '');
                let dashboardList: any = await getData(url).then((dashboardList: any) => {
                    let summaryData = dashboardList.data;
                    let summaryList: any = [];
                    let summaryList_GroupByDevice: any = [];
                    let overAllTotal = 0;
                    let overAllPGTotal = 0;
                    let overAllCashTotal = 0;
                    let overAllRefundTotal = 0;

                    for (var i = 0; i < summaryData.length; i++) {
                        var str = (summaryData[i]['date']).toString();
                        var strYear = str.substring(0, 4);
                        var strMonth = str.substring(4, 6);
                        var strDate = str.substring(6, 8);
                        let summaryDate = strDate + "-" + strMonth + "-" + strYear;
                        overAllTotal = overAllTotal + (summaryData[i]['cashTotal'] + summaryData[i]['pgTotal']);
                        overAllPGTotal = overAllPGTotal + summaryData[i]['pgTotal'];
                        overAllCashTotal = overAllCashTotal + summaryData[i]['cashTotal'];
                        overAllRefundTotal = overAllRefundTotal + summaryData[i]['refundTotal'];

                        let count = 0;
                        let arr = Object.keys(summaryData[i]).map(key => {
                            if(typeof(summaryData[i][key]) === "object"){
                                count = count + 1;
                                let loc_name = "";
                                let loc = deviceList.filter(function (item: any) {
                                    return item.name === key.toString();
                                });
                                if(loc.length>0){
                                    loc_name = loc[0].location;
                                }
                                let cash = typeof(summaryData[i][key].cashTotal) == 'string' ? 0 : summaryData[i][key].cashTotal;
                                let pg = typeof(summaryData[i][key].pgTotal) == 'string' ? 0 : summaryData[i][key].pgTotal;
                                let refund = typeof(summaryData[i][key].refundTotal) == 'string' ? 0 : summaryData[i][key].refundTotal;
                                
                                let refundCount = "";
                                if(summaryData[i][key].refundCount){
                                    refundCount = typeof(summaryData[i][key].refundCount) == 'string' ? "" : summaryData[i][key].refundCount.toString();
                                }
                                
                                summaryList.push(
                                    {
                                        'date': summaryDate,
                                        'cashTotal': cash,
                                        'pgTotal': pg,
                                        'refundTotal': refund,
                                        'device': key,
                                        'location': loc_name,
                                        'total': cash + pg,
                                        'refundCount': refundCount
                                    }
                                )
                            }
                        });
                    }
                    const deviceGroupList = _.groupBy(summaryList, 'device');
                    if(selectedSummaryGroupBy == "DEVICE" && summaryList.length>0){
                        let availableDeviceList = Object.keys(deviceGroupList);
                        //Data summarised only for available devices.
                        deviceList.forEach((rec:any) => {
                            let device = rec.name;
                            if(device && device != undefined && (availableDeviceList.indexOf(device)>-1)){
                                let device_Name = device;
                                let device_total = 0; 
                                let cash_total = 0;
                                let pg_total = 0;
                                let refund_count = 0;
                                let refund_total = 0;
                                let location_name = "";
                                if(deviceGroupList[device].length>0){
                                    for(var v=0; v<deviceGroupList[device].length; v++){
                                        device_total = device_total + deviceGroupList[device][v]["total"];
                                        cash_total = cash_total + deviceGroupList[device][v]["cashTotal"];
                                        pg_total = pg_total + deviceGroupList[device][v]["pgTotal"];
                                        refund_total = refund_total + deviceGroupList[device][v]["refundTotal"];
                                        location_name = deviceGroupList[device][v]["location"];
                                        let ind_count = 0;
                                        if((typeof(deviceGroupList[device][v]["refundCount"]) === 'string' && deviceGroupList[device][v]["refundCount"] !="")){
                                            ind_count = Number(deviceGroupList[device][v]["refundCount"]);
                                        }
                                        else if( deviceGroupList[device][v]["refundCount"] !=""){
                                            ind_count = deviceGroupList[device][v]["refundCount"]
                                        }
                                        refund_count = refund_count + ind_count;
                                    }
                                }
                                summaryList_GroupByDevice.push(
                                    {
                                        'date': "",
                                        'cashTotal': cash_total,
                                        'pgTotal': pg_total,
                                        'refundTotal': refund_total,
                                        'device': device_Name,
                                        'location': location_name,
                                        'total': device_total,
                                        'refundCount': refund_count
                                    }
                                );
                            }
                        });
                    }
                    setReportTotal(overAllTotal);
                    setReportDigital(overAllPGTotal);
                    setReportCash(overAllCashTotal);
                    setReportRefund(overAllRefundTotal);
                    if(selectedSummaryGroupBy == "DEVICE"){
                        setSummaryTableData(summaryList_GroupByDevice);
                    }
                    else{
                        setSummaryTableData(summaryList);
                    }
                    
                });
                setIsLoading(false);
            }
            else if (selectedDevice && selectedDevice.name && fromDate && toDate) {
                setIsLoading(true);
                setShowDeviceTable(true);
                let transactionURL = '/txns/' + selectedDevice.name + '?fromdate=' + fromDate.replaceAll('-', '') + '&todate=' + toDate.replaceAll('-', '');
                let res: any = await getData(transactionURL);
                if (res.status === 200 && Array.isArray(res.data)) {
                    let final_report_list: any = [];
                    if (res && res.data && res.data.length > 0) {
                        setReportTotal(res.data[0].cash + res.data[0].pg);
                        setReportCash(res.data[0].cash);
                        setReportDigital(res.data[0].pg);
                        setReportRefund(res.data[0].refund);
                        res.data[0].cashTxns.map((record: any) => {
                            let rec = {
                                date: record[0],
                                mode: record[1],
                                transId: record[2] === 'NA' ? '' : record[2],
                                selection: record[3],
                                amount: record[4],
                                remarks: '',
                                name: record.length >5 ? record[5]:""
                            }
                            final_report_list.push(rec);
                        });
                        setReportList(final_report_list);

                        res.data[0].pgTxns.map((record: any) => {
                            let rec = {
                                date: record[0],
                                mode: record[1],
                                transId: record[2] === 'NA' ? '' : record[2],
                                selection: record[3],
                                amount: record[4],
                                remarks: '',
                                name: record.length >5 ? record[5]:""
                            }
                            final_report_list.push(rec);
                        });
                        setReportList(final_report_list);

                        res.data[0].refundTxns.map((record: any) => {
                            let rec = {
                                date: record[0],
                                mode: record[1],
                                transId: record[2] === 'NA' ? '' : record[2],
                                selection: record[3],
                                amount: record[4],
                                remarks: record[5],
                                name: record.length >6 ? record[6]:""
                            }
                            final_report_list.push(rec);
                        });

                        final_report_list.sort(function (x: any, y: any) {
                            let date1: any = new Date(x.date);
                            let date2: any = new Date(y.date);
                            return date1 - date2;
                        });
                        setReportList(final_report_list);
                    }
                }
                setIsLoading(false);
            }
        }
        catch (error) {
            console.log("EXCEPTION in LoadReportData Method-Error: ", error);
        }
    }

    const downloadCSV = async () => {
        try{
            if (selectedDevice && selectedDevice.name && toDate && fromDate) {
                setIsLoading(true);
                var csvData: any = "";
                var csvHeaders = ["SNo", "Date", "Mode", "TxnID", "Selection","Name", "Amount", "Remarks"];
                var row_header = "";
                for (var i = 0; i < csvHeaders.length; i++) {
                    row_header += '"' + csvHeaders[i] + '",';
                }
                row_header.slice(0, row_header.length - 1);
                csvData += row_header + "\r\n";

                for (var i = 0; i < reportList.length; i++) {
                    var row = "";
                    let sno = reportList[i]['tableData'].id + 1;
                    row = '"' + sno + '","' + reportList[i]['date'] + '","' + reportList[i]['mode'] + '","=""' + reportList[i]['transId'].toString() + '""","' + reportList[i]['selection'] + '","' + reportList[i]['name'] + '","' + reportList[i]['amount'] + '","' + reportList[i]['remarks'] + '",'
                    row.slice(0, row.length - 1);
                    csvData += row + "\r\n";
                }
                const url = window.URL.createObjectURL(new Blob([csvData]));
                const link = document.createElement('a');
                link.href = url;
                let fileName = selectedDevice.name + "_" + fromDate + "_To_" + toDate + ".csv";
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                setIsLoading(false);
            }
            else if (summaryCBXValue && summaryTableData && toDate && fromDate) {
                setIsLoading(true);
                let header_names = [];
                if(selectedSummaryGroupBy == "DEVICE"){
                    header_names = ["SNo", "Device", "Location", "Total", "Cash", "Digital", "Total-Refund", "Refund-Count"];
                }
                else{
                    header_names = ["SNo", "Date", "Device", "Location", "Total", "Cash", "Digital", "Total-Refund", "Refund-Count"];
                }
                var csvData: any = "";
                var csvHeaders = header_names;
                var row_header = "";
                for (var i = 0; i < csvHeaders.length; i++) {
                    row_header += '"' + csvHeaders[i] + '",';
                }
                row_header.slice(0, row_header.length - 1);
                csvData += row_header + "\r\n";

                for (var i = 0; i < summaryTableData.length; i++) {
                    var row = "";
                    let sno = summaryTableData[i]['tableData'].id + 1;
                    if(selectedSummaryGroupBy == "DEVICE"){
                        row = '"' + sno + '","' + summaryTableData[i]['device'] + '","' + summaryTableData[i]['location'].toString() + '","'+ summaryTableData[i]['total'] +'","'+ summaryTableData[i]['cashTotal'] + '","' + summaryTableData[i]['pgTotal'] + '","' + summaryTableData[i]['refundTotal'] + '","' + + summaryTableData[i]['refundCount'] + '",'
                    }
                    else{
                        row = '"' + sno + '","' + summaryTableData[i]['date'] + '","' + summaryTableData[i]['device'] + '","' + summaryTableData[i]['location'].toString() + '","'+ summaryTableData[i]['total'] +'","'+ summaryTableData[i]['cashTotal'] + '","' + summaryTableData[i]['pgTotal'] + '","' + summaryTableData[i]['refundTotal'] + '","' + + summaryTableData[i]['refundCount'] + '",'
                    }
                    row.slice(0, row.length - 1);
                    csvData += row + "\r\n";
                }
                const url = window.URL.createObjectURL(new Blob([csvData]));
                const link = document.createElement('a');
                link.href = url;
                let fileName = "Summary_" + fromDate + "_To_" + toDate + ".csv";
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                setIsLoading(false);
            }
        }
        catch(error){
            console.log("EXCEPTION in DownloadCSV Method-Error: ", error);
        }
    }

    const reportColumns = [
        {
            title: '#', field: 'tableData.id', render: (rowData: any) => rowData.tableData.id + 1,
            headerStyle: { width: "5%" }, cellStyle: { width: "5%", maxWidth: "5%", overflow: "hidden" }, width: "5%"
        },
        { title: 'Date', field: 'date', headerStyle: { width: "10%" }, cellStyle: { width: "10%", maxWidth: "10%", overflow: "hidden" }, width: "10%" },
        { title: 'Mode', field: 'mode', headerStyle: { width: "5%" }, cellStyle: { width: "5%", maxWidth: "5%", overflow: "hidden" }, width: "5%" },
        { title: 'TxnID', field: 'transId', headerStyle: { width: "25%" }, cellStyle: { width: "25%", maxWidth: "25%", overflow: "hidden" }, width: "25%" },
        { title: 'Selection', field: 'selection', headerStyle: { width: "10%" }, cellStyle: { width: "10%", maxWidth: "10%", overflow: "hidden" }, width: "10%" },
        { title: 'Name', field: 'name', headerStyle: { width: "10%" }, cellStyle: { width: "10%", maxWidth: "10%", overflow: "hidden" }, width: "10%" },
        { title: 'Amount', field: 'amount', render:(rowdata:any)=> "₹ "+rowdata.amount.toLocaleString(), headerStyle: { width: "5%" }, cellStyle: { width: "5%", maxWidth: "5%", overflow: "hidden" }, width: "5%" },
        { title: 'Remarks', field: 'remarks', headerStyle: { width: "15%" }, cellStyle: { width: "15%", maxWidth: "15%", overflow: "hidden" }, width: "15%" }
    ]

    let summaryColumns = [
        {
            title: '#', field: 'tableData.id', render: (rowData: any) => rowData.tableData.id + 1,
            headerStyle: { width: "5%" }, cellStyle: { width: "5%", maxWidth: "5%", overflow: "hidden" }, width: "5%"
        },
        { title: 'Date', field: 'date', headerStyle: { width: "15%" }, cellStyle: { width: "15%", maxWidth: "15%", overflow: "hidden" }, width: "15%" },
        { title: 'Device', field: 'device', headerStyle: { width: "10%" }, cellStyle: { width: "10%", maxWidth: "10%", overflow: "hidden" }, width: "10%" },
        { title: 'Location', field: 'location', headerStyle: { width: "30%" }, cellStyle: { width: "30%", maxWidth: "30%", overflow: "hidden" }, width: "30%" },
        { title: 'Total', field: 'total', render:(rowdata:any)=> "₹ "+rowdata.total.toLocaleString(), headerStyle: { width: "10%" }, cellStyle: { width: "10%", maxWidth: "10%", overflow: "hidden" }, width: "10%" },
        { title: 'Cash', field: 'cashTotal', render:(rowdata:any)=> "₹ "+rowdata.cashTotal.toLocaleString(), headerStyle: { width: "10%" }, cellStyle: { width: "10%", maxWidth: "10%", overflow: "hidden" }, width: "10%" },
        { title: 'Digital', field: 'pgTotal', render:(rowdata:any)=> "₹ "+rowdata.pgTotal.toLocaleString(), headerStyle: { width: "10%" }, cellStyle: { width: "10%", maxWidth: "10%", overflow: "hidden" }, width: "10%" },
        { title: 'Refund', field: 'refundTotal', render:(rowdata:any)=> "₹ "+rowdata.refundTotal.toLocaleString(), headerStyle: { width: "10%" }, cellStyle: { width: "10%", maxWidth: "10%", overflow: "hidden" }, width: "10%" },
        { title: 'Refund Count', field: 'refundCount', render:(rowdata:any)=> rowdata.refundCount, headerStyle: { width: "10%" }, cellStyle: { width: "10%", maxWidth: "10%", overflow: "hidden" }, width: "10%" }
    ];

    let summaryColumns_GroupByDevice = [
        {
            title: '#', field: 'tableData.id', render: (rowData: any) => rowData.tableData.id + 1,
            headerStyle: { width: "5%" }, cellStyle: { width: "5%", maxWidth: "5%", overflow: "hidden" }, width: "5%"
        },
        { title: 'Device', field: 'device', headerStyle: { width: "10%" }, cellStyle: { width: "10%", maxWidth: "10%", overflow: "hidden" }, width: "10%" },
        { title: 'Location', field: 'location', headerStyle: { width: "30%" }, cellStyle: { width: "30%", maxWidth: "30%", overflow: "hidden" }, width: "30%" },
        { title: 'Total', field: 'total', render:(rowdata:any)=> "₹ "+rowdata.total.toLocaleString(), headerStyle: { width: "10%" }, cellStyle: { width: "10%", maxWidth: "10%", overflow: "hidden" }, width: "10%" },
        { title: 'Cash', field: 'cashTotal', render:(rowdata:any)=> "₹ "+rowdata.cashTotal.toLocaleString(), headerStyle: { width: "10%" }, cellStyle: { width: "10%", maxWidth: "10%", overflow: "hidden" }, width: "10%" },
        { title: 'Digital', field: 'pgTotal', render:(rowdata:any)=> "₹ "+rowdata.pgTotal.toLocaleString(), headerStyle: { width: "10%" }, cellStyle: { width: "10%", maxWidth: "10%", overflow: "hidden" }, width: "10%" },
        { title: 'Refund', field: 'refundTotal', render:(rowdata:any)=> "₹ "+rowdata.refundTotal.toLocaleString(), headerStyle: { width: "10%" }, cellStyle: { width: "10%", maxWidth: "10%", overflow: "hidden" }, width: "10%" },
        { title: 'Refund Count', field: 'refundCount', render:(rowdata:any)=> rowdata.refundCount, headerStyle: { width: "10%" }, cellStyle: { width: "10%", maxWidth: "10%", overflow: "hidden" }, width: "10%" }
    ];

    return (
        <div className="page-heading">
            <div className="page-title">
                <div className="row">
                    <div className="col-12 col-md-6 order-md-1 order-last">
                        <h3 className='vv-color-code'>Reports</h3>
                    </div>
                    <div className="col-12 col-md-6 order-md-2 order-first">
                        <nav aria-label="breadcrumb" className="breadcrumb-header float-start float-lg-end">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Reports</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <br />
            <div className="row">
                <div className="col-6 col-lg-3 col-md-6">
                    <div className="card">
                        <div className="card-body px-3">
                            <div className="row">
                                <div className="col-md-8">
                                    <h6 className="text-muted font-semibold">Total</h6>
                                    <h6 className="font-extrabold mb-0">
                                    {"₹ "+reportTotal.toLocaleString()}
                                    </h6>
                                </div>
                                <div className="col-md-4">
                                    <i className="bi bi-wallet-fill card-icon color-lavender"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-lg-3 col-md-6">
                    <div className="card">
                        <div className="card-body px-3">
                            <div className="row">

                                <div className="col-md-8">
                                    <h6 className="text-muted font-semibold">Digital</h6>
                                    <h6 className="font-extrabold mb-0">{"₹ "+reportDigital.toLocaleString()}</h6>
                                </div>
                                <div className="col-md-4">
                                    <i className="bi bi-credit-card-fill card-icon color-digital"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-lg-3 col-md-6">
                    <div className="card">
                        <div className="card-body px-3">
                            <div className="row">

                                <div className="col-md-8">
                                    <h6 className="text-muted font-semibold">Cash</h6>
                                    <h6 className="font-extrabold mb-0">{"₹ "+reportCash.toLocaleString()}</h6>
                                </div>
                                <div className="col-md-4">
                                    <i className="bi bi-cash-stack card-icon color-ping"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-lg-3 col-md-6">
                    <div className="card">
                        <div className="card-body px-3">
                            <div className="row">
                                <div className="col-md-8">
                                    <h6 className="text-muted font-semibold">Refund</h6>
                                    <h6 className="font-extrabold mb-0">{"₹ "+reportRefund.toLocaleString()}</h6>
                                </div>
                                <div className="col-md-4">
                                    <i className="bi bi-arrow-return-left card-icon"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ color: "red" }}>
                {errormsg}
            </div>
            <section id="form-and-scrolling-components">
                <Modal show={isLoading} aria-labelledby="contained-modal-title-vcenter"
                    centered backdrop="static"
                    keyboard={false}>
                    <Modal.Body> <Spinner animation="border" /> Loading... </Modal.Body>
                </Modal>
            </section>

            <div className='DownloadList' style={{ margin: "5px 0px 10px 0px" }}>
                <table>
                    <tr>
                        
                        <td>
                            <label><span style={{verticalAlign:"super"}}>Summary: &nbsp;</span>
                                <input className="" style={{width:"18px", height:"18px"}} type="checkbox" value="" checked={summaryCBXValue} onChange={(e) => {
                            setSummaryCBXValue(e.target.checked);
                            if (e.target.checked) {
                                setSelectedSummaryGroupBy("DATE");
                                setSelectedDevice("");
                            }
                        }} /></label></td>
                        <td>
                        <span style={{ paddingRight: "25px",display:summaryCBXValue ? "inline-block":"none" }} >
                        <span> 
                            <input style={{ marginLeft: "8px" }} className="ios_toggle" type="radio" id="DATE" value="DATE" checked={ 
                                    selectedSummaryGroupBy === "DATE"
                                } 
                                onChange={() => 
                                    handleRadioChange( 
                                        "DATE"
                                    ) 
                                }/>
                            
                        </span>
                        <span><label style={{ paddingLeft: "5px" }} htmlFor="DATE"> Date</label> </span>
                        <span style={{ marginLeft: "8px" }}>
                            <input className="ios_toggle" type="radio" id="DEVICE" value="DEVICE"  checked={ 
                                    selectedSummaryGroupBy === "DEVICE"
                                } 
                                onChange={() => 
                                    handleRadioChange( 
                                        "DEVICE"
                                    ) 
                                }/>
                        </span>
                        <span style={{verticalAlign:"inherit"}}><label style={{ paddingLeft: "5px" }} htmlFor="DEVICE"> Device</label> </span>
                    </span>
                        </td>
                        <td>
                        <span style={{ width: "125px", paddingLeft: "20px", paddingRight: "20px", display:!summaryCBXValue ? "inline":"none" }}> Device:
                    <select className='report-device' onChange={(e) => {
                        if (e && e.target && e.target.value) {
                            setSelectedDevice(deviceList.find((device: any) => {
                                if (e && e.target && e.target.value && device.name === e.target.value) {
                                    return device.name
                                }
                            }
                            ));
                            setSummaryCBXValue(false);
                        }
                        else {
                            setSelectedDevice("");
                        }
                    }
                    }>
                        <option value="" selected={selectedDevice}>Select</option>
                        {deviceList.map((device: any) => (
                            <option value={device.name} selected={selectedDevice.name === device.name} > {device.name} </option>
                        ))}
                    </select>
                </span>
                        </td>

                        <td>
                        <span className='pr-25' style={{ paddingRight: "25px"}}>
                    <label>From:
                        <input type="date" name="fromDate" value={fromDate} className='fromDate' onChange={(event) => setFromDate(event.target.value)} min={disablePastDate()} max={disableFeatureDate()} />
                    </label>
                </span>
                <span className='pr-25'>
                    <label>To:
                        <input type="date" name="toDate" value={toDate} className='toDate' onChange={(event) => setToDate(event.target.value)} max={disableFeatureDate()} />
                    </label>
                </span>
                <span>
                    <Button variant="primary" style={{ verticalAlign: "baseline" }} onClick={loadReportData} >Submit</Button>
                </span>
                </td>
                <td>
                    <span className={"report_download"+ (reportList.length === 0 ? "is-disabled " : '')} title='Download as CSV' style={{ display: showDeviceTable && reportList.length >0 ? 'inline-block' : 'none' }} >
                        <a href='#' onClick={() => downloadCSV()}><i className="bi bi-download" style={{ verticalAlign: "middle" }}></i></a>
                    </span>

                    <span className={"report_download"+ (summaryTableData.length === 0 ? "is-disabled " : '')} title='Download as CSV' style={{ display: !showDeviceTable && summaryTableData.length > 0 ? 'inline-block' : 'none' }} >
                        <a href='#' onClick={() => downloadCSV()}><i className="bi bi-download" style={{ verticalAlign: "middle" }}></i></a>
                    </span>
                </td>
                </tr>
                </table>                
            </div>
            <div className='report-device-table' style={{ marginTop:"25px", display: showDeviceTable ? 'block' : 'none' }}>
                <MaterialTable title=""
                    data={reportList}
                    columns={reportColumns}
                    options={{
                        sorting: true,
                        actionsColumnIndex: -1,
                        headerStyle: { position: 'sticky', top: 0, fontWeight: 'bold', fontSize: 15, color:"#6c757d" },
                        maxBodyHeight: 700,
                        paging: true,
                        pageSize: 10,
                        pageSizeOptions: [10, 20, 50],
                        emptyRowsWhenPaging: false,
                        columnResizable: true,
                        rowStyle: {
                            fontSize: 15,
                            font: "var(--bs-font-sans-serif)"
                        }
                    }} />
            </div>
            <div className='report-device-table' style={{ marginTop:"25px", display: !showDeviceTable ? 'block' : 'none' }}>
                <MaterialTable title=""
                    data={summaryTableData}
                    columns={selectedSummaryGroupBy == 'DEVICE'? summaryColumns_GroupByDevice : summaryColumns}
                    options={{
                        sorting: true,
                        actionsColumnIndex: -1,
                        headerStyle: { position: 'sticky', top: 0, fontWeight: 'bold', fontSize: 15, color:"#6c757d" },
                        maxBodyHeight: 700,
                        paging: true,
                        pageSize: 10,
                        pageSizeOptions: [10, 20, 50],
                        emptyRowsWhenPaging: false,
                        columnResizable: true,
                        rowStyle: {
                            fontSize: 15,
                            font: "var(--bs-font-sans-serif)"
                        }
                    }} />
            </div>

        </div>
    );
};
function mapStateToProps(state: any) {
    const { isLoggedIn } = state.client.isLoggedIn;
    const { message } = state.messages;
    return {
        isLoggedIn,
        user: state.client.user,
        message
    };
}

export default connect(mapStateToProps)(Reports); 
