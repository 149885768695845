import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
// import {auth} from '../../utils/firebaseUtils'
import { login } from '../../redux/actions/auth.action'
function Login(props: any) {
    const { dispatch } = props;
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [errMsg, setErrMsg] = useState('')
    const handleSubmit = async (event: any) => {
        event.preventDefault();
        setErrMsg('');
        dispatch(login(email, password))
    };
    return (
        <div className="row h-100 mt-4">
            <div className="col-lg-4 d-none d-lg-block"></div>
            <div className="col-lg-4 col-12">
                <div id="auth-left">
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <div className='mt-10'>
                            <div className="form-group position-relative has-icon-left mb-4">
                                <input type="text" onChange={(e) => setEmail(e.target.value)} className="form-control form-control-xl" placeholder="Email" />
                                <div className="form-control-icon">
                                    <i className="bi bi-person"></i>
                                </div>
                            </div>
                            <div className="form-group position-relative has-icon-left mb-4">
                                <input type="password" onChange={(e) => setPassword(e.target.value)} className="form-control form-control-xl" placeholder="Password" />
                                <div className="form-control-icon">
                                    <i className="bi bi-shield-lock"></i>
                                </div>
                            </div>
                            {errMsg != '' ? <div className="form-group position-relative has-icon-left mb-4">
                                <h6 style={{ color: "red" }}>{errMsg}</h6>
                            </div> : ''}
                            <button className="btn btn-primary btn-block btn-lg shadow-lg">Log in</button>
                        </div>
                    </form>
                    <div className="text-center mt-5 text-lg fs-4">
                        <p className="text-gray-600">Don't have an account? <Link to="/signup">Sign up</Link>.</p>
                        <p><Link className="font-bold" to="/forget-password">Forgot password?</Link>.</p>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 d-none d-lg-block">
                <div id="auth-right">

                </div>
            </div>
        </div>
    );
}

function mapStateToProps(state: any) {
    const { isLoggedIn } = state.client.isLoggedIn;
    const { message } = state.messages;
    return {
        isLoggedIn,
        message
    };
}

export default connect(mapStateToProps)(Login); 
