import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Spinner } from 'react-bootstrap';
import Chart from "react-apexcharts";
import { getData, getSessionDetails } from '../../services/main-service';
import { logout } from "../../redux/actions/auth.action";
import { getCustomerName } from "../../utils";

function Home(props: any) {
  const customerName = getCustomerName(props.user);
  const [selectedDate, setSelectedDate] = useState<any>([]);
  const [customFromDate, setCustomFromDate] = useState<any>("");
  const [customToDate, setCustomToDate] = useState<any>("");
  const [showCustomeDate, setShowCustomeDate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [invalidCustmer, setInvalidCustmer] = useState(false);
  
  const [errormsg, setErrorMsg] = useState("");
  const [topFiveList, setTopFiveList] = useState<any>([]);
  const [deviceList, setDeviceList] = useState<any>([]);

  const [totalSalesCountChart, setTotalSalesCountChart] = useState<any>(
    {
      series: [{ name: "Total Sales", data: [] }],
      options: {
        chart: { type: 'area', height: 160, zoom: { enabled: false }, sparkline: { enabled: true } },
        dataLabels: { enabled: false },
        stroke: { curve: 'straight' },
        title: { text: '₹ 0', align: 'left', style: { fontSize: '24px', cssClass: 'apexcharts-yaxis-title', color: '#6c757d' } },
        subtitle: { text: 'Total Sales', align: 'left', style: { color: '#6c757d', fontSize: '14px', cssClass: 'apexcharts-yaxis-title' } },
        labels: [],
        colors: ['#5e46dd'],
        fill: { opacity: 0.5 },
        xaxis: { type: 'datetime', show: false },
        yaxis: { min: 0, show: false },
        legend: { horizontalAlign: 'left' },
        tooltip: {
          y: {
            formatter: function (val: any) {
              return "₹ " + val.toLocaleString()
            }
          }
        }
      }
    });

  const [totalPGCountChart, setTotalPGCountChart] = useState<any>(
    {
      series: [{ name: "Total PG", data: [] }],
      options: {
        chart: { type: 'area', height: 160, zoom: { enabled: false }, sparkline: { enabled: true } },
        dataLabels: { enabled: false },
        stroke: { curve: 'straight' },
        title: { text: '₹ 0', align: 'left', style: { fontSize: '24px', cssClass: 'apexcharts-yaxis-title', color: '#6c757d' } },
        subtitle: { text: 'Total Sales', align: 'left', style: { color: '#6c757d', fontSize: '14px', cssClass: 'apexcharts-yaxis-title' } },
        labels: [],
        colors: ['#008FFB'],
        xaxis: { type: 'datetime', show: false },
        yaxis: { min: 0, show: false },
        legend: { horizontalAlign: 'left' },
        tooltip: {
          y: {
            formatter: function (val: any) {
              return "₹ " + val.toLocaleString()
            }
          }
        }
      }
    });

  const [totalCashCountChart, setTotalCashCountChart] = useState<any>(
    {
      series: [{ name: "Total Cash", data: [] }],
      options: {
        chart: { type: 'area', height: 160, zoom: { enabled: false }, sparkline: { enabled: true } },
        dataLabels: { enabled: false },
        stroke: { curve: 'straight' },
        title: { text: '₹ 0', align: 'left', style: { fontSize: '24px', cssClass: 'apexcharts-yaxis-title', color: '#6c757d' } },
        subtitle: { text: 'Total Cash', align: 'left', style: { color: '#6c757d', fontSize: '14px', cssClass: 'apexcharts-yaxis-title' } },
        labels: [],
        colors: ['#00E396'],
        xaxis: { type: 'datetime', show: false },
        yaxis: { min: 0, show: false },
        legend: { horizontalAlign: 'left' },
        tooltip: {
          y: {
            formatter: function (val: any) {
              return "₹ " + val.toLocaleString()
            }
          }
        }
      }
    });

  const [salesTrendChart, setSalesTrendChart] = useState<any>({
    series: [{ name: 'Sales Trend', data: [] }, { name: 'Cash', data: [] }],
    options: {
      chart: { type: 'bar', height: 350 },
      plotOptions: { bar: { horizontal: false, columnWidth: '55%', endingShape: 'rounded' } },
      dataLabels: { enabled: false },
      stroke: { show: true, width: 2, colors: ['transparent'] },
      xaxis: { categories: [] },
      fill: { opacity: 1 },
      colors: ['#008FFB', '#00E396'],
      title: { align: 'left', text: 'Sales Trend', offsetX: 30, style: { color: '#6c757d', fontSize: '24px', cssClass: 'apexcharts-yaxis-title' } },
      tooltip: {
        y: {
          formatter: function (val: any) {
            return "₹ " + val.toLocaleString()
          }
        }
      }
    },
  }
  )

  const [breakupChart, setBreakUpChart] = useState<any>({
    series: [],
    options: {
      labels: ['PG', 'Cash', 'Refund'],
      chart: { width: 380, type: 'donut' },
      plotOptions: { pie: { startAngle: -90, endAngle: 270 } },
      dataLabels: { enabled: true },
      title: { text: 'Txns break up', style: { fontSize: '14px', fontWeight: '800', color: '#6c757d' } },
      tooltip: {
        y: {
          formatter: function (val: any) {
            return "₹ " + val.toLocaleString()
          }
        }
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: { width: 200 },
          legend: { position: 'bottom' }
        }
      }]
    }
  })

  const [billingChart, setBillingChart] = useState<any>({
    options: {
      chart: { id: "basic-bar" },
      xaxis: { categories: [] },
      dataLabels: { enabled: false },
      legend: { show: false },
      colors: ['#D4526E'],
      tooltip: {
        y: {
          formatter: function (val: any) {
            return val.toLocaleString();
          }
        }
      }
    },
    series: [{ name: "Billable devices", data: [] }]
  });

  const dateList: any = [
    { text: "Today", value: 'TODAY' },
    { text: "Last 7 days", value: 'SEVENDAYS' },
    { text: "Custom", value: 'CUSTOM' }
  ]

  useEffect(() => {
    if(customerName){
      setInvalidCustmer(false);
      clearData();
      setTimeout(() => {
        setShowCustomeDate(false);
        setErrorMsg("");
        var today = new Date();
        const tDateDay = String(today.getDate() + 0).padStart(2, "0");
        const tDateMonth = String(today.getMonth() + 1).padStart(2, "0");
        const tDateYear = today.getFullYear();
        let tDate = tDateYear + tDateMonth + tDateDay;
  
        var priorDate = new Date(new Date().setDate(today.getDate() - 7));
        const fDateDay = String(priorDate.getDate()).padStart(2, "0");
        const fDateMonth = String(priorDate.getMonth() + 1).padStart(2, "0");
        const fDateYear = priorDate.getFullYear();
        let fDate = fDateYear + fDateMonth + fDateDay;
        loadData(fDate, tDate);
      }, 1000);
    }else{
      setInvalidCustmer(true);
    }
    

  }, [])

  const GetDashboardData = async () => {
    setSelectedDate(selectedDate);
    if (selectedDate === 'SEVENDAYS') {
      setShowCustomeDate(false);
      setCustomFromDate("");
      setCustomToDate("");
      var today = new Date();
      const tDateDay = String(today.getDate() + 0).padStart(2, "0");
      const tDateMonth = String(today.getMonth() + 1).padStart(2, "0");
      const tDateYear = today.getFullYear();
      let tDate = tDateYear + tDateMonth + tDateDay;

      var priorDate = new Date(new Date().setDate(today.getDate() - 7));
      const fDateDay = String(priorDate.getDate()).padStart(2, "0");
      const fDateMonth = String(priorDate.getMonth() + 1).padStart(2, "0");
      const fDateYear = priorDate.getFullYear();
      let fDate = fDateYear + fDateMonth + fDateDay;
      loadData(fDate, tDate);
    }
    else if (selectedDate === 'TODAY') {
      setShowCustomeDate(false);
      setCustomFromDate("");
      setCustomToDate("");

      var today = new Date();
      const tDateDay = String(today.getDate() + 0).padStart(2, "0");
      const tDateMonth = String(today.getMonth() + 1).padStart(2, "0");
      const tDateYear = today.getFullYear();
      let tDate = tDateYear + tDateMonth + tDateDay;
      // loadData(tDate, tDate);
      loadTodayData(tDate);
    }
    else if (selectedDate === 'CUSTOM') {
      setShowCustomeDate(true);
      if (customFromDate == "") {
        setErrorMsg("Please select from date");
        return false;
      }
      if (customToDate == "") {
        setErrorMsg("Please select to date");
        return false;
      }
      let fDate = new Date(customFromDate);
      let tDate = new Date(customToDate);
      if (fDate > tDate) {
        setErrorMsg("From date should less than to date");
        return false;
      }
      setErrorMsg("");
      loadData(customFromDate.replaceAll('-', ''), customToDate.replaceAll('-', ''));
    }
  }
  const loadTodayData = async (toDate:any)=>{
    setIsLoading(true);
    let isCookieAvailable: any = await getSessionDetails();
    console.log("VV: Home Page After LoadData Method-isCookieAvailable: ",isCookieAvailable);
    if(!isCookieAvailable){
        props.dispatch(logout());
    }
    let todayUrl = "/txns/summary/"+toDate
      let todayList: any = await getData(todayUrl).then((dashboardList: any) => {
        if(dashboardList.status == 200){
          let billingChartCat = [];
          let billingChartData = [];
          let totalSalesCount = [];
          let totalPGCount = [];
          let totalCashCount = [];
          let dateList = [];
          let accTotalSales = 0;
          let accTotalPG = 0;
          let accTotalRefund = 0;
          let accTotalCash = 0;
          let breakUpChartDataToday = [];
          let FinalTopFiveList: any = [];
          let innerList: any = [];
          let tempDashboardData = dashboardList.data;
          let countval = 0;
          for (var i = 0; i < tempDashboardData.length; i++) {
            if(i == 0){
              accTotalSales = tempDashboardData[i]['cash'] + tempDashboardData[i]['pg'];
              accTotalPG = tempDashboardData[i]['pg'];
              accTotalCash = tempDashboardData[i]['cash'];
              accTotalRefund = tempDashboardData[i]['refund'];

              var str = (toDate).toString();
              var strYear = str.substring(0, 4);
              var strMonth = str.substring(4, 6);
              var strDate = str.substring(6, 8);
              billingChartCat.push(strDate + "-" + strMonth + "-" + strYear);
              //sales count for Cards
              dateList.push(strYear + "-" + strMonth + "-" + strDate);
              let cashTotal = typeof(tempDashboardData[i]['cash']) == 'number' ? tempDashboardData[i]['cash']:0;
              let pgTotal = typeof(tempDashboardData[i]['pg']) == 'number' ? tempDashboardData[i]['pg']:0;
              totalSalesCount.push(cashTotal + pgTotal);
              totalPGCount.push(pgTotal);
              totalCashCount.push(cashTotal);

              breakUpChartDataToday.push(accTotalPG);
              breakUpChartDataToday.push(accTotalCash);
              breakUpChartDataToday.push(accTotalRefund);

            }else{
              countval = countval + 1;
              let cash = 0;
              let pg = 0;
              if (typeof (tempDashboardData[i]['cashTotal']) === "number") {
                cash = tempDashboardData[i]['cashTotal'];
              }else{
                tempDashboardData[i]['cashTotal'] = 0;
              }
              if (typeof (tempDashboardData[i]['pgTotal']) === "number") {
                pg = tempDashboardData[i]['pgTotal'];
              }else{
                tempDashboardData[i]['pgTotal'] = 0;
              }

              tempDashboardData[i]['grandTotal'] = cash + pg;
              innerList.push(tempDashboardData[i]);
            }
          }
          let loc = deviceList.filter(function (item: any) {
            return item.status === 'up';
          });
          billingChartData.push(loc.length);
          
          var finalListASC = [...innerList].sort((a: any, b: any) => {
            return a.grandTotal < b.grandTotal ? 1 : -1
          });
          let topFiveDevice = []
          for (var device of finalListASC) {
            if (topFiveDevice.length < 10) {
              if (topFiveDevice.indexOf(device.devname) < 0) {
                let loc = deviceList.filter(function (item: any) {
                    return item.name === device.devname;
                });
                device['location'] = loc[0].location;
                device['device'] = device.devname;
                topFiveDevice.push(device.device);
                FinalTopFiveList.push(device);
              }
            }
            else {
              break;
            }
          }
          setTopFiveList(FinalTopFiveList);

          //chart configuration part
          setBreakUpChart({
            series: breakUpChartDataToday,
            options: {
              labels: ['PG', 'Cash', 'Refund'],
              chart: { width: 380, type: 'donut' },
              plotOptions: { pie: { startAngle: -90, endAngle: 270 } },
              dataLabels: { enabled: true },
              legend: {
                formatter: function (val: any, opts: any) {
                  return val + " - " + (opts.w.globals.series[opts.seriesIndex]).toLocaleString()
                }
              },
              title: { text: 'Txns Break up', style: { fontSize: '16px', fontWeight: 'bold', color: '#6c757d' } },
              tooltip: {
                y: {
                  formatter: function (val: any) {
                    return "₹ " + val.toLocaleString()
                  }
                }
              },
              responsive: [{
                breakpoint: 480,
                options: {
                  chart: { width: 200 },
                  legend: { position: 'bottom' }
                }
              }]
            }
          });

          setTotalSalesCountChart(
            {
              series: [{ name: "Total Sales", data: totalSalesCount }],
              options: {
                chart: { type: 'area', height: 160, zoom: { enabled: false }, sparkline: { enabled: true } },
                dataLabels: { enabled: false },
                stroke: { curve: 'straight' },
                title: { text: '₹ ' + accTotalSales.toLocaleString(), align: 'left', style: { fontSize: '24px', cssClass: 'apexcharts-yaxis-title', color: '#6c757d' } },
                subtitle: { text: 'Total Sales', align: 'left', style: { color: '#6c757d', fontSize: '14px', cssClass: 'apexcharts-yaxis-title' } },
                labels: dateList,
                xaxis: { type: 'datetime', show: false },
                yaxis: { min: 0, show: false },
                colors: ['#5e46dd'],
                fill: { opacity: 0.5 },
                tooltip: {
                  y: {
                    formatter: function (val: any) {
                      return "₹ " + val.toLocaleString()
                    }
                  }
                },
                legend: { horizontalAlign: 'left' }
              }
            });

          setTotalPGCountChart(
            {
              series: [{ name: "Total PG", data: totalPGCount }],
              options: {
                chart: { type: 'area', height: 160, zoom: { enabled: false }, sparkline: { enabled: true } },
                dataLabels: { enabled: false },
                stroke: { curve: 'straight' },
                title: { text: '₹ ' + accTotalPG.toLocaleString(), align: 'left', style: { fontSize: '24px', cssClass: 'apexcharts-yaxis-title', color: '#6c757d' } },
                subtitle: { text: 'Total PG', align: 'left', style: { color: '#6c757d', fontSize: '14px', cssClass: 'apexcharts-yaxis-title' } },
                labels: dateList,
                xaxis: { type: 'datetime', show: false },
                yaxis: { min: 0, show: false },
                colors: ['#008FFB'],
                tooltip: {
                  y: {
                    formatter: function (val: any) {
                      return "₹ " + val.toLocaleString()
                    }
                  }
                },
                legend: { horizontalAlign: 'left' }
              }
            });
  
          setTotalCashCountChart(
            {
              series: [{ name: "Total Cash", data: totalCashCount }],
              options: {
                chart: { type: 'area', height: 160, zoom: { enabled: false }, sparkline: { enabled: true } },
                dataLabels: { enabled: false },
                stroke: { curve: 'straight' },
                title: { text: '₹ ' + accTotalCash.toLocaleString(), align: 'left', style: { fontSize: '24px', cssClass: 'apexcharts-yaxis-title', color: '#6c757d' } },
                subtitle: { text: 'Total Cash', align: 'left', style: { color: '#6c757d', fontSize: '14px', cssClass: 'apexcharts-yaxis-title' } },
                labels: dateList,
                xaxis: { type: 'datetime', show: false },
                yaxis: { min: 0, show: false },
                colors: ['#00E396'],
                tooltip: {
                  y: {
                    formatter: function (val: any) {
                      return "₹ " + val.toLocaleString()
                    }
                  }
                },
                legend: { horizontalAlign: 'left' }
              }
            });
  
          setSalesTrendChart({
            series: [{ name: 'PG', data: totalPGCount }, { name: 'Cash', data: totalCashCount }],
            options: {
              chart: { type: 'bar', height: 350 },
              plotOptions: { bar: { horizontal: false, columnWidth: '55%', endingShape: 'rounded' } },
              dataLabels: { enabled: false },
              stroke: { show: true, width: 2, colors: ['transparent'] },
              xaxis: { categories: dateList },
              colors: ['#008FFB', '#00E396'],
              fill: { opacity: 1 },
              title: { align: 'left', text: 'Sales Trend', offsetX: 30, style: { color: '#6c757d', fontSize: '16px', cssClass: 'apexcharts-yaxis-title' } },
              tooltip: {
                y: {
                  formatter: function (val: any) {
                    return "₹ " + val.toLocaleString()
                  }
                }
              }
            }
          }
          )
  
          setBillingChart(
            {
              options: {
                chart: { id: "basic-bar" },
                xaxis: { categories: billingChartCat },
                dataLabels: { enabled: false },
                colors: ['#D4526E'],
                legend: { show: false },
                title: { align: 'left', text: 'Billable devices', offsetX: 30, style: { color: '#6c757d', fontSize: '16px', cssClass: 'apexcharts-yaxis-title' } },
                tooltip: {
                  y: {
                    formatter: function (val: any) {
                      return val.toLocaleString();
                    }
                  }
                }
              },
              series: [{ name: "Devices ", data: billingChartData }]
            });

        }
      }).catch((error) => {
        console.log("Exception in Get Today summary API in Home page-Error: ", error);
      });

      setIsLoading(false);
  }
  const loadData = async (fromDate: any, toDate: any) => {
    let isCookieAvailable: any = await getSessionDetails();
    console.log("VV: Home Page After LoadData Method-isCookieAvailable: ",isCookieAvailable);
    if(!isCookieAvailable){
        props.dispatch(logout());
    }

    setIsLoading(true);
    let tempDevice:any = [];
    let res: any = await getData('/devices').then((res:any)=>{
      if(res && res.data){
      const strAscending = [...res.data].sort((a, b) =>
          a.name > b.name ? 1 : -1,
      );
      // console.log("strAscending: ",strAscending);
      tempDevice = strAscending;
      setDeviceList(strAscending);
      }
    // setTimeout(() => {
    let url = "/txns/trend?fromdate=" + fromDate + "&todate=" + toDate;
    let dashboardList: any = getData(url).then((dashboardList: any) => {
      if (dashboardList && dashboardList.status && dashboardList.status == 200) {
        let billingChartCat = [];
        let billingChartData = [];
        let totalSalesCount = [];
        let totalPGCount = [];
        let totalCashCount = [];
        let dateList = [];
        let accTotalSales = 0;
        let accTotalPG = 0;
        let accTotalRefund = 0;
        let accTotalCash = 0;
        let breakUpChartData = [];
        let FinalTopFiveList: any = [];
        let innerList: any = [];
        let tempDashboardData = dashboardList.data;
        for (var i = 0; i < tempDashboardData.length; i++) {
          billingChartData.push(tempDashboardData[i]['live'])
          var str = (tempDashboardData[i]['date']).toString();
          var strYear = str.substring(0, 4);
          var strMonth = str.substring(4, 6);
          var strDate = str.substring(6, 8);
          billingChartCat.push(strDate + "-" + strMonth + "-" + strYear);

          //sales count for Cards
          dateList.push(strYear + "-" + strMonth + "-" + strDate);
          totalSalesCount.push((tempDashboardData[i]['cashTotal'] + tempDashboardData[i]['pgTotal']).toString());
          totalPGCount.push((tempDashboardData[i]['pgTotal']).toString());
          totalCashCount.push((tempDashboardData[i]['cashTotal']).toString());
          accTotalSales = accTotalSales + (tempDashboardData[i]['cashTotal'] + tempDashboardData[i]['pgTotal']);
          accTotalPG = accTotalPG + tempDashboardData[i]['pgTotal'];
          accTotalCash = accTotalCash + tempDashboardData[i]['cashTotal'];
          accTotalRefund = accTotalRefund + tempDashboardData[i]['refundTotal'];
          let countval = 0;
          let arr = Object.keys(tempDashboardData[i]).map(key => {
            if(typeof(tempDashboardData[i][key]) === "object"){
              countval = countval + 1;
              let cash = 0;
              let pg = 0;
              if (typeof (tempDashboardData[i][key]['cashTotal']) === "number") {
                cash = tempDashboardData[i][key]['cashTotal'];
              }else{
                tempDashboardData[i][key]['cashTotal'] = 0;
              }
              if (typeof (tempDashboardData[i][key]['pgTotal']) === "number") {
                pg = tempDashboardData[i][key]['pgTotal'];
              }else{
                tempDashboardData[i][key]['pgTotal'] = 0;
              }

              tempDashboardData[i][key]['grandTotal'] = cash + pg;
              tempDashboardData[i][key]['device'] = key;
              innerList.push(tempDashboardData[i][key]);
            }
          })
        }
        var finalListASC = [...innerList].sort((a: any, b: any) => {
          return a.grandTotal < b.grandTotal ? 1 : -1
        });
        // console.log("temp-Device-list: ",tempDevice);
        let topFiveDevice1 = []
        for (var device of finalListASC) {
          if (topFiveDevice1.length < 10) {
            if (topFiveDevice1.indexOf(device.device) < 0) {
              let loc = tempDevice.filter(function (item: any) {
                  return item.name === device.device.toString();
              });
              if(loc && loc.length>0){
                device['location'] = loc[0].location;
              }
              else{
                device['location'] = "";
              }              
              topFiveDevice1.push(device.device);
              FinalTopFiveList.push(device);
            }
          }
          else {
            break;
          }
        }
        setTopFiveList(FinalTopFiveList);

        breakUpChartData.push(accTotalPG);
        breakUpChartData.push(accTotalCash);
        breakUpChartData.push(accTotalRefund);

        setBreakUpChart({
          series: breakUpChartData,
          options: {
            labels: ['PG', 'Cash', 'Refund'],
            chart: { width: 380, type: 'donut' },
            plotOptions: { pie: { startAngle: -90, endAngle: 270 } },
            dataLabels: { enabled: true },
            legend: {
              formatter: function (val: any, opts: any) {
                return val + " - " + (opts.w.globals.series[opts.seriesIndex]).toLocaleString()
              }
            },
            title: { text: 'Txns Break up', style: { fontSize: '16px', fontWeight: 'bold', color: '#6c757d' } },
            tooltip: {
              y: {
                formatter: function (val: any) {
                  return "₹ " + val.toLocaleString()
                }
              }
            },
            responsive: [{
              breakpoint: 480,
              options: {
                chart: { width: 200 },
                legend: { position: 'bottom' }
              }
            }]
          }
        });

        setTotalSalesCountChart(
          {
            series: [{ name: "Total Sales", data: totalSalesCount }],
            options: {
              chart: { type: 'area', height: 160, zoom: { enabled: false }, sparkline: { enabled: true } },
              dataLabels: { enabled: false },
              stroke: { curve: 'straight' },
              title: { text: '₹ ' + accTotalSales.toLocaleString(), align: 'left', style: { fontSize: '24px', cssClass: 'apexcharts-yaxis-title', color: '#6c757d' } },
              subtitle: { text: 'Total Sales', align: 'left', style: { color: '#6c757d', fontSize: '14px', cssClass: 'apexcharts-yaxis-title' } },
              labels: dateList,
              xaxis: { type: 'datetime', show: false },
              yaxis: { min: 0, show: false },
              colors: ['#5e46dd'],
              fill: { opacity: 0.5 },
              tooltip: {
                y: {
                  formatter: function (val: any) {
                    return "₹ " + val.toLocaleString()
                  }
                }
              },
              legend: { horizontalAlign: 'left' }
            }
          });

        setTotalPGCountChart(
          {
            series: [{ name: "Total PG", data: totalPGCount }],
            options: {
              chart: { type: 'area', height: 160, zoom: { enabled: false }, sparkline: { enabled: true } },
              dataLabels: { enabled: false },
              stroke: { curve: 'straight' },
              title: { text: '₹ ' + accTotalPG.toLocaleString(), align: 'left', style: { fontSize: '24px', cssClass: 'apexcharts-yaxis-title', color: '#6c757d' } },
              subtitle: { text: 'Total PG', align: 'left', style: { color: '#6c757d', fontSize: '14px', cssClass: 'apexcharts-yaxis-title' } },
              labels: dateList,
              xaxis: { type: 'datetime', show: false },
              yaxis: { min: 0, show: false },
              colors: ['#008FFB'],
              tooltip: {
                y: {
                  formatter: function (val: any) {
                    return "₹ " + val.toLocaleString()
                  }
                }
              },
              legend: { horizontalAlign: 'left' }
            }
          });

        setTotalCashCountChart(
          {
            series: [{ name: "Total Cash", data: totalCashCount }],
            options: {
              chart: { type: 'area', height: 160, zoom: { enabled: false }, sparkline: { enabled: true } },
              dataLabels: { enabled: false },
              stroke: { curve: 'straight' },
              title: { text: '₹ ' + accTotalCash.toLocaleString(), align: 'left', style: { fontSize: '24px', cssClass: 'apexcharts-yaxis-title', color: '#6c757d' } },
              subtitle: { text: 'Total Cash', align: 'left', style: { color: '#6c757d', fontSize: '14px', cssClass: 'apexcharts-yaxis-title' } },
              labels: dateList,
              xaxis: { type: 'datetime', show: false },
              yaxis: { min: 0, show: false },
              colors: ['#00E396'],
              tooltip: {
                y: {
                  formatter: function (val: any) {
                    return "₹ " + val.toLocaleString()
                  }
                }
              },
              legend: { horizontalAlign: 'left' }
            }
          });

        setSalesTrendChart({
          series: [{ name: 'PG', data: totalPGCount }, { name: 'Cash', data: totalCashCount }],
          options: {
            chart: { type: 'bar', height: 350 },
            plotOptions: { bar: { horizontal: false, columnWidth: '55%', endingShape: 'rounded' } },
            dataLabels: { enabled: false },
            stroke: { show: true, width: 2, colors: ['transparent'] },
            xaxis: { categories: dateList },
            colors: ['#008FFB', '#00E396'],
            fill: { opacity: 1 },
            title: { align: 'left', text: 'Sales Trend', offsetX: 30, style: { color: '#6c757d', fontSize: '16px', cssClass: 'apexcharts-yaxis-title' } },
            tooltip: {
              y: {
                formatter: function (val: any) {
                  return "₹ " + val.toLocaleString()
                }
              }
            }
          }
        }
        )

        setBillingChart(
          {
            options: {
              chart: { id: "basic-bar" },
              xaxis: { categories: billingChartCat },
              dataLabels: { enabled: false },
              colors: ['#D4526E'],
              legend: { show: false },
              title: { align: 'left', text: 'Billable devices', offsetX: 30, style: { color: '#6c757d', fontSize: '16px', cssClass: 'apexcharts-yaxis-title' } },
              tooltip: {
                y: {
                  formatter: function (val: any) {
                    return val.toLocaleString()
                  }
                }
              }
            },
            series: [{ name: "Devices ", data: billingChartData }]
          });        
      }
    }).catch((error) => {
      console.log("Exception in LoadData method in Home page-error: ", error);
    });
  // }, 500);

  }).catch((error) => {
    console.log("Exception in Get Device API in Home page-Error: ", error);
  });

    setIsLoading(false);
  }
  const clearData = () => {
    let clearList: any = [];
    setSelectedDate("SEVENDAYS");
  }

  const onChangeDateList = (val: string) => {
    setSelectedDate(val);
    if (val === 'CUSTOM') {
      setShowCustomeDate(true);
      setCustomFromDate("");
      setCustomToDate("");
    }
    else {
      setShowCustomeDate(false);
    }
  }

  const disablePastDate = () => {
    var today = new Date();
    var priorDate = new Date(new Date().setDate(today.getDate() - 90));
    const dd = String(priorDate.getDate()).padStart(2, "0");
    const mm = String(priorDate.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = priorDate.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  const disableFeatureDate = () => {
    var today = new Date();
    const dd = String(today.getDate() + 0).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  const GoToLogin = () => {
    props.dispatch(logout());
  }
  return (
    <div className='row'>
      <section id="form-and-scrolling-components">
        <Modal show={isLoading} aria-labelledby="contained-modal-title-vcenter"
          centered backdrop="static"
          keyboard={false}>
          <Modal.Body> <Spinner animation="border" /> Loading... </Modal.Body>
        </Modal>
      </section>

      <section id="form-and-scrolling-components">
        <Modal show={invalidCustmer} aria-labelledby="contained-modal-title-vcenter" style={{backgroundColor: invalidCustmer ? '#adadad' : ''}}
          centered backdrop="static"
          keyboard={false}>
          <Modal.Body> 
            <div className='invalid-user-msg'>Please contact Admin</div>
            <div className='invalid-user-button'><Button variant="primary" style={{ margin: "0 50%" }} onClick={GoToLogin} >OK</Button></div>
          </Modal.Body>
        </Modal>
      </section>

      <div className='row error-msg'>
        <p>{errormsg}</p>
      </div>
      <div className='row' style={{ marginBottom: "20px" }}>
        <div className="col-3 col-lg-3 col-md-3">
          <span style={{ width: "125px", paddingRight: "25px" }}> Select:
            <select className='report-device' onChange={(e) => onChangeDateList(e.target.value)}>
              {dateList.map((data: any) => (
                <option value={data.value} selected={selectedDate == data.value} > {data.text} </option>
              ))}
            </select>
          </span>
        </div>
        <div className="col-6 col-lg-6 col-md-6" style={{ display: showCustomeDate ? 'block' : 'none' }}>
          <span className='pr-25'>
            <label>From:
              <input type="date" name="fromDate" value={customFromDate} className='fromDate' onChange={(event) => setCustomFromDate(event.target.value)} min={disablePastDate()} max={disableFeatureDate()} />
            </label>
          </span>
          <span className='pr-25'>
            <label>To:
              <input type="date" name="toDate" value={customToDate} className='fromDate' onChange={(event) => setCustomToDate(event.target.value)} max={disableFeatureDate()} />
            </label>
          </span>
        </div>
        <div className="col-2 col-lg-2 col-md-2">
          <span className='pr-25'>
            <Button variant="primary" style={{ verticalAlign: "baseline" }} onClick={GetDashboardData}>Go</Button>
          </span>
        </div>
      </div><br />
      <div className='row'>
        <div className='col-6 col-lg-4 col-md-6'>
          <div className='sales-total-content'>
            <Chart options={totalSalesCountChart.options} series={totalSalesCountChart.series} type="area" />
          </div>
        </div>
        <div className='col-6 col-lg-4 col-md-6'>
          <div className='sales-total-content'>
            <Chart options={totalPGCountChart.options} series={totalPGCountChart.series} type="area" />
          </div>
        </div>
        <div className='col-6 col-lg-4 col-md-6'>
          <div className='sales-total-content'>
            <Chart options={totalCashCountChart.options} series={totalCashCountChart.series} type="area" />
          </div>
        </div>
      </div><br /><br />


      <div className='row mt-20'>
        <div className="col-6 col-lg-6 col-md-6">
          <div className='sales-total-content'>
            <Chart options={salesTrendChart.options} series={salesTrendChart.series} type="bar" />
          </div>
        </div>
        <div className="col-6 col-lg-6 col-md-6">
          <div className='sales-total-content'>
            <Chart options={breakupChart.options} series={breakupChart.series} type="donut" />
          </div>
        </div>
      </div>


      <div className='row mt-20'>
        <div className="col-6 col-lg-6 col-md-6">
          <div className='sales-total-content'>
            <Chart options={billingChart.options} series={billingChart.series} type="bar" />
          </div>
        </div>
        <div className="col-6 col-lg-6 col-md-6">
          <div className='sales-total-content top-five'>
            <span style={{ width: "100%", padding: "5px", height: "50px", textAlign: "left", fontSize: "17px", fontWeight: "800", color: "#6c757d" }}>Top Ten Devices</span>
            <table style={{ width: "100%", padding: "10px" }} cellPadding="9">
              <tr>
                <th style={{ width: "20%", fontSize: "13px" }}>Device</th>
                <th style={{ width: "40%", fontSize: "13px" }}>Location</th>
                <th style={{ width: "20%", fontSize: "13px" }}>Digital Txns</th>
                <th style={{ width: "20%", fontSize: "13px" }}>Cash Txns</th>
              </tr>
              {topFiveList.map((data: any) => (
                <tr><td>{data.device}</td><td>{data.location}</td><td>{'₹ ' + data.pgTotal.toLocaleString()}</td><td>{'₹ ' + data.cashTotal.toLocaleString()}</td></tr>
              ))}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state: any) {
  const { isLoggedIn } = state.client.isLoggedIn;
  const { message } = state.messages;
  return {
    isLoggedIn,
    message
  };
}

export default connect(mapStateToProps)(Home); 
