// import React from 'react';
// import { Route, Switch } from 'react-router';
// import { App as TodoApp } from 'app/containers/App';
// import { hot } from 'react-hot-loader';

// export const App = hot(module)(() => (
//   <Switch>
//     <Route path="/" component={TodoApp} />
//   </Switch>
// ));


import * as React from 'react';




import Pages from "./routes/Pages"

export const App =  (() => (
  <Pages/>
));