import axios from 'axios';
import { authHeaderNew } from '../services/auth-header';
import AuthService from "../services/auth.service";
import Cookies from 'js-cookie';
// const API_URL = "https://manage.vendviitor.com"//process.env.API_URL;
// const API_URL = "https://stage---portal-apis-rup2dfg24a-el.a.run.app/api";
// const API_URL = "https://portal-apis-rup2dfg24a-el.a.run.app/api";  //To be enable For development
const API_URL = "/api"; //To be enable For Production;
const API_IMAGE = process.env.API_IMAGE;

export const getData = async (url: any): Promise<string | void> => {

  try {
    const result: any = await axios({
      method: 'GET',
      url: `${API_URL}${url}`,
      withCredentials: true,
      validateStatus: function (status) {
        return status < 600;
      },
      headers: { "Access-Control-Allow-Origin": "*", Authorization: "Bearer " + getToken() },//"Accept": "/", ...authHeaderNew(),
    });
    //let response = result;
    return result;
  } catch (error) {
    console.error('Error:', error);
  }
};

export const postData = async (url: any, data: any): Promise<string | void> => {
  try {
    const result: any = await axios({
      method: "POST",
      url: `${API_URL}${url}`,
      withCredentials: true,
      validateStatus: function (status) {
        return status < 600;
      },
      data: data, // data can be `string` or {object}! 
      headers: { "Access-Control-Allow-Origin": "*", Authorization: "Bearer " + getToken() },
    });
    return result;
  } catch (error) {
    console.error('Error:', error);
  }
};

export const postIdToken = async (url: any, data: any): Promise<string | void> => {
  try {
    const result: any = await axios({
      method: "POST",
      url: `${API_URL}${url}`,
      withCredentials: true,
      validateStatus: function (status) {
        return status < 600;
      },
      data: data, // data can be `string` or {object}! 
      // headers: { "Access-Control-Allow-Origin": "*", Authorization: "Bearer " + getToken() },
    });
    return result;
  } catch (error) {
    console.error('Error:', error);
  }
};

export const deleteData = async (url: any): Promise<string | void> => {
  try {
    const result: any = await axios({
      method: "DELETE",
      url: `${API_URL}${url}`,
      withCredentials: true,
      validateStatus: function (status) {
        return status < 600;
      },
      //data: data, // data can be `string` or {object}! 
      headers: { "Access-Control-Allow-Origin": "*", Authorization: "Bearer " + getToken() },
    });
    return result;
  } catch (error) {
    console.error('Error:', error);
  }
};

export const getImagePath = (path: any) => {
  return API_IMAGE + '/' + path;
}

const getToken = () => {
  const user = JSON.parse(window.localStorage.getItem('user') || '{}');
  // console.log('user', user)
  // if (user.decodeData.exp * 1000 < Date.now()) {
    //AuthService.logout()
  // }
  if (user && Object.keys(user).length > 0)
    return user.userDetail.stsTokenManager?.accessToken;
  return
}
export const getUserDetails = () => {
  const user = JSON.parse(window.localStorage.getItem('user') || '{}');
  if (user && Object.keys(user).length > 0)
    return user;
  return
}

export const getSessionDetails = () => {
  try
      {
        let cookiesList = Cookies.get();
        if(cookiesList && cookiesList['SID']){
          console.log("VV: SID is Available and Value is: ",cookiesList['SID']);
          return true
        }
        else{
          return false
        }
      }
      catch(error){
        console.log("VV: EXCEPTION IN GET COOKIES: ",error);
        return false;
      }
}

/*AuthService.signup(data).then(
  (response: any) => {
    console.log('res', response)
    return Promise.resolve();
  },
  (error: any) => {
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
      setErrMsg(message);
    console.log('msg', message);

    return Promise.reject();
  }
);*/

