import { getUserDetails } from "../services/main-service";

export const flatListToTreeViewData = (dataList: any) => {
  var tree = [],
    mappedArr: any = {},
    arrElem,
    mappedElem;

  // First map the nodes of the array to an object -> create a hash table.
  for (var i = 0, len = dataList.length; i < len; i++) {
    arrElem = dataList[i];
    mappedArr[arrElem.id] = arrElem;
    mappedArr[arrElem.id]['children'] = [];
  }

  for (var id in mappedArr) {
    if (mappedArr.hasOwnProperty(id)) {
      mappedElem = mappedArr[id];

      // If the element is not at the root level, add it to its parent array of children.
      if (mappedElem.parentID) {
        mappedArr[mappedElem['parentID']]['children'].push(mappedElem);
      }
      // If the element is at the root level, add it to first level elements array.
      else {
        tree.push(mappedElem);
      }
    }
  }
  return tree;
}

export const isSadmin=(user:any)=>{
  let currentUser = getUserDetails();
  user = currentUser;
  
  // return user && user.decodeData && user.decodeData.sadmin.toLowerCase()=='true';
  if(user && user.decodeData && user.decodeData.sadmin){
    if( typeof(user && user.decodeData && user.decodeData.sadmin) == 'boolean'){
      return user && user.decodeData && user.decodeData.sadmin === true;
    }
    if( typeof(user && user.decodeData && user.decodeData.sadmin) == 'string'){
      return user && user.decodeData && user.decodeData.sadmin.toLowerCase() === "true";
    }
  }
  return user && user.decodeData && user.decodeData.sadmin === true;
}
export const isCC=(user:any)=>{
  let currentUser = getUserDetails();
  user = currentUser;
  if(user && user.decodeData && user.decodeData.cc){
    // return user && user.decodeData && user.decodeData.cc.toLowerCase()=='true';
    return user && user.decodeData && user.decodeData.cc;
  }
  return false;
  
}

export const isLayoutConfigEnabled=(user:any)=>{
  let currentUser = getUserDetails();
  user = currentUser;
  if(user && user.decodeData && user.decodeData.layout){
    return user && user.decodeData && user.decodeData.layout;
  }
  return false;  
}

export const getCustomerName=(user:any)=>{
  let currentUser = getUserDetails();
  user = currentUser;
  if(user && user.decodeData)
  {
    return user && user.decodeData && user.decodeData.customer;
  }
  return null;
  
}

export const getNestedChildren = (arr: any, parent: any) => {
  var children = [];
  for (var i = 0; i < arr.length; ++i) {
    console.log("inn", arr[i].parentID, parent)
    if (typeof arr[i].parentID !== "undefined" || arr[i].parentID === parent) {
      let tmp = arr;
      delete tmp[i];
      var grandChildren = getNestedChildren(tmp, arr[i].parentID)

      if (grandChildren.length) {
        arr[i].children = grandChildren;
      }
      children.push(arr[i]);
    }
    // children.push(arr[i]);
  }
  return children;
}

export const priceFormat = (amount: any, decimalCount: number = 2, decimal: any = ".", thousands: any = "'") => {
  //price = parseFloat(price);
  //return price.toFixed(2);
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i: any = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let j: any = (i.length > 3) ? i.length % 3 : 0;

    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
  } catch (e) {
    console.log(e);
    return ""
  }
}
export const getTypeIcon = (data: any) => {
  if (data === "per Telefon") {
    return "lnr-phone";
  }
  else if (data === "via E-Mail") {
    return "lnr-envelope";
  }
  else if (data === "vor Ort") {
    return "lnr-apartment";
  }
  else if (data === "via Kontaktformular") {
    return "lnr-user";
  }
  else {
    return "fa fa-ticket";
  }

}

export const getType = (txt: string) => {
  let reg: any = /(?:\.([^.]+))?$/;
  let type: any = reg.exec(txt);
  return type[1];
  //console.log(reg.exec("Beleg_Rechnung RE50139 - 1.pdf")[1]);
}

export const base64ToFile = (data: string, filename: string, view: boolean = false) => {
  let image = ['png', 'jpg', 'jpeg', 'gif']
  if (image.indexOf(getType(filename)) > -1) {
    if (view) {
      data = 'data:image/' + getType(filename) + ';base64,' + data;
    }
    else
      data = 'data:application/octet-stream;base64,' + data;
  }
  else if (getType(filename) === 'pdf') {
    data = 'data:application/pdf;base64,' + data;
  }
  if (view) {
    console.log('dddddddd', data);
    window.open(escape(data), '_blank');
  }
  else {
    const downloadLink = document.createElement("a");
    downloadLink.href = data;
    downloadLink.target = '_blank';
    downloadLink.download = filename;
    downloadLink.click();
  }
}

export const convertBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file)
    fileReader.onload = () => {
      resolve(fileReader.result);
    }
    fileReader.onerror = (error) => {
      reject(error);
    }
  })
}
export const getCustList = (data: any[], selectField: any) => {
  let objValue: any = {};
  if (data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      objValue[data[i][selectField]] = data[i].value;
    }
    return objValue;
  }
  else {
    return objValue;
  }
}


export const getHoursCheck = (data: any) => {
  let hn = ((Math.floor(data / 3600)).toString().padStart(2, "0"));
  return hn;
}
export const getMinCheck = (data: any) => {
  let mn = ((Math.floor(data % 3600 / 60)).toString().padStart(2, "0"));
  return mn;
}


export const secondsToTime = (secs: any) => {
  var hours = Math.floor(secs / (60 * 60));

  var divisor_for_minutes = secs % (60 * 60);
  var minutes = Math.floor(divisor_for_minutes / 60);

  var divisor_for_seconds = divisor_for_minutes % 60;
  var seconds = Math.ceil(divisor_for_seconds);

  var obj = {
    "h": hours.toString().padStart(2, "0"),
    "m": minutes.toString().padStart(2, "0"),
    "s": seconds
  };
  return obj;
}

export const baseUrl = process.env.BASE_URL