import firebase  from "firebase/compat/app"
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { setMessage } from '../../redux/actions/message';
import { success } from '../../services/toast-service';
import { auth } from '../../utils/firebaseUtils'
import { sendEmailVerification, createUserWithEmailAndPassword, updateProfile } from "firebase/auth"
function Register(props: any) {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errMsg, setErrMsg] = useState('')
  const {dispatch}=props;
  useEffect(()=>{
    
  },[])
  const handleSubmit = async (event:any) => {
    setErrMsg('')
    event.preventDefault();
   try{
    const { user } = await createUserWithEmailAndPassword(auth, email, password)
    if(user && user.uid){
        console.log(`New User ${user.uid} created`);
        await updateProfile(user, {
            displayName: name
        }).then(function() {
            sendEmailVerification(user)
            .then(() => {
                console.log("VERIFICATION MAIL SENT");
                dispatch(setMessage('User registered. Check your email for verification link.','success'));
            });            
        }, function(error:any) {
            console.log("Error: Facing error when updateProfile Method()-error: ",error);
        });
        
    }
    else{
        console.log("Error: Facing error when createUserWithEmailAndPassword Method()-error: ",user);
    }
   }
   catch(error:any){
    console.log("Exception in handlSubmit Method-Error:",error);
    if (error && error.code && error.code == 'auth/weak-password') {
        setErrMsg('The password is too weak.')
        console.log("The password is too weak.");
    } else {
        setErrMsg('Registration failed');
        console.error(error);
    }
   }

    // auth.createUserWithEmailAndPassword(email, password).then(function(user:any) {
        // // [END createwithemail]
        // // callSomeFunction(); Optional
        // // var user = firebase.auth().currentUser;
        // user.updateProfile({
        //     displayName: name
        // }).then(function() {
        //     // Update successful.
        //     dispatch(setMessage('Registration complete. once admin verify you can login','success'))
        //     console.log('Updated successfully');
        // }, function(error:any) {
        //     // An error happened.
        //     console.log('Updated error',error);
        // });        
    // }, function(error) {
    //     // Handle Errors here.
    //     var errorCode = error.code;
    //     var errorMessage = error.message;
    //     // [START_EXCLUDE]
    //     if (errorCode == 'auth/weak-password') {
    //         //alert('The password is too weak.');
    //         setErrMsg('The password is too weak.')
    //     } else {
    //         setErrMsg('Unable to Register');
    //         console.error(error);
    //     }
    //     // [END_EXCLUDE]
    // });
    
  }
    return (
        <div className="row h-100 mt-4">
        <div className="col-lg-4 d-none d-lg-block"></div>
        <div className="col-lg-4 col-12">
       
              
                            <div className="loginForm register">
                            {/* <h1 className="auth-title text-center">Sign up.</h1> */}
                                <form onSubmit={(e) => handleSubmit(e)}>
                                  
                                      
                                            {/* <label> Name</label> */}
                                            <div className="form-group">
                                                <input required type="text"  placeholder="Name"  onChange={(e)=>setName(e.target.value)} className="form-control" />
                                                {/* <span class="errorMsg">Field is Required</span>*/}
                                            </div>
                                      
                                       
                       
                                        
                                            <div className="form-group">
                                                {/* <label>Email</label> */}
                                                <input  type="email"  placeholder="Email"  onChange={(e)=>setEmail(e.target.value)}  className="form-control" />
                                                {/* <span class="errorMsg">Field is Required</span>*/}
                                            </div>
                                        
                                      
                                            <div className="form-group">
                                                {/* <label>Password</label> */}
                                                <input type="password"  onChange={(e)=>setPassword(e.target.value)}  className="form-control" placeholder="Password" />
                                                {/* <span class="errorMsg">Field is Required</span>*/}
                                            </div>
                                       
                                        
                                            {/* <div className="form-group">
                                                <label>Confirm Password</label>
                                                <input type="text" className="form-control" />
                                             
                                            </div> */}
                                             {errMsg!=''?<div className="form-group position-relative has-icon-left mb-4">
                              <h6 style={{color:"red"}}>{errMsg}</h6>
                          </div>:''}
                          
                                            <button className="btn btn-primary btn-block btn-lg shadow-lg">Signup</button>
                                        <div className="text-center mt-5 text-lg fs-4">
              <p className="text-gray-600">Don't have an account? <Link to="/login">Login</Link>.</p>
          </div>

                                       
                                   
                                </form>
                            </div>
                     

        </div>
        </div>
    );
}

function mapStateToProps(state: any) {
    const { isLoggedIn } = state.client.isLoggedIn;
    const { message } = state.messages;
    return {
        isLoggedIn,
        message
    };
}
export default connect(mapStateToProps)(Register);
