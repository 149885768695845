import React from 'react';
import ReactDOM from 'react-dom/client';
import "./assets/css/bootstrap.css";
import "./assets/vendors/bootstrap-icons/bootstrap-icons.css";
import "./assets/css/app-dark.css";
import "./assets/css/app.css";
import "./assets/css/font.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import {App} from './app/index';
import reportWebVitals from './reportWebVitals';
import { configureStore } from './app/redux/store';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

// import "../node_modules/react-mazer-ui/dist/datatable.js";
// import "../node_modules/react-mazer-ui/dist/style.css";
// import "react-mazer-ui"
// import "./assets/vendors/simple-datatables/simple-datatables.js";
// import "./assets/vendors/simple-datatables/style.css";
// import "./assets/css/bootstrap.css";
// import "./assets/js/bootstrap.bundle.min.js"

// const root = ReactDOM.createRoot(
//   document.getElementById('root') as HTMLElement
// );
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const store = configureStore();
// const container = document.getElementById('root') as HTMLElement;
// const root = ReactDOM.createRoot(container!);
root.render(
  <Provider store={store}>
    <BrowserRouter basename={process.env.BASE_URL}>
    {/* <React.StrictMode> */}
      <App />
      {/* </React.StrictMode> */}
    </BrowserRouter>
  </Provider>
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
