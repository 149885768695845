import firebase  from "firebase/compat/app"
import "firebase/compat/auth"
import "firebase/compat/firestore"
import "firebase/compat/storage"

// var firebaseConfig = {
//     apiKey: "AIzaSyBj7N4w9QoDCpnX-fNCN4E0LuWcpf6grto",
//     authDomain: "vend-managed.firebaseapp.com",
//     projectId: "vend-managed",
//     storageBucket: "vend-managed.appspot.com",
//     messagingSenderId: "611850593680",
//     appId: "1:611850593680:web:5c92c8b477d95e8c993f15",
//     measurementId: "G-6RDXB39C09"
// };
const firebaseConfig = {
    apiKey: "AIzaSyBtlzAHBwWf4pKskdg2hpS6nLcld6xs3p0",
    authDomain: "vendviitor-web.firebaseapp.com",
    databaseURL: "https://vendviitor-web.firebaseio.com",
    projectId: "vendviitor-web",
    storageBucket: "vendviitor-web.appspot.com",
    messagingSenderId: "237637426555",
    appId: "1:237637426555:web:1ec98a15646cdd73c571de"
  };

  // const idToken = await firebase.auth().currentUser.getIdToken();
  
firebase.initializeApp(firebaseConfig);

// As httpOnly cookies are to be used, do not persist any state client side.
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE);



firebase.firestore().settings({
    cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED
})

export const firestore = firebase.firestore();
export const auth = firebase.auth();
export const storage = firebase.storage();
export const storageRef = firebase.storage().ref



export default firebase




