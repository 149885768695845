import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getData, getSessionDetails } from '../../services/main-service';
import { Link } from 'react-router-dom';
import { Modal, Button, Spinner } from 'react-bootstrap';
import { isSadmin } from '../../utils';
import { logout } from "../../redux/actions/auth.action";
import Swal from 'sweetalert2'
import { useForm } from "react-hook-form";
import MaterialTable from 'material-table';
import {
    getFirestore,
    collection,
    getDocs,
    addDoc,
    deleteDoc,
    doc,
    setDoc,
    onSnapshot,
    query,
    where,
    orderBy, 
    startAfter, 
    limit
} from "firebase/firestore";
// import { collection, query, orderBy, startAfter, limit, getDocs } from "firebase/firestore"; 
import { getCustomerName } from "../../utils";

const MakeList = {
    SEAGA : 'Seaga' ,
    NEWSEAGA : 'NewSeaga',
    TCN : 'TCN',
    CRANEDN : 'Crane-DN',
    VENDSTOP : 'Vendstop'
} as const

const MONTHS =["Jan","Feb","Mar","Apr","May","Jun","July","Aug","Sep","Oct","Nov","Dec"]
function CCReports(props: any) {
    const customerName = getCustomerName(props.user);
    const db = getFirestore();
    const summarydb = getFirestore();
    // const collectionName = 'Alfa_card_management';
    const collectionName = customerName + "_cc_txns";
    const colRef = collection(db, collectionName);

    const summaryCollectionName = customerName + "_cc_txns_summary";
    const summaryColRef = collection(summarydb, summaryCollectionName);

    const [isLoading, setIsLoading] = useState(true);
    const [cardList, setCardList] = useState<any>([]);
    const [summaryList, setSummaryList] = useState<any>([]);
    const [show, setShow] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [deviceList, setDeviceList] = useState<any>([]);
    const [fromDate, setFromDate] = useState<any>("");
    const [toDate, setToDate] = useState<any>("");
    const [summaryCBXValue, setSummaryCBXValue] = useState(false);
    const [selectedDevice, setSelectedDevice] = useState<any>([]);
    const [holderName, setHolderName] = useState('');
    // const hextoDecimal = hex:String => parseInt(hex,16);

    useEffect(() => {
        setIsLoading(false);
        loadDeviceData();
        loadData();
    }, [])

    const loadDeviceData = async () => {
        let isCookieAvailable: any = await getSessionDetails();
        if (!isCookieAvailable) {
            props.dispatch(logout());
        }
        setIsLoading(true);
        let res: any = await getData('/devices');
        if (res.status == 200 && Array.isArray(res.data)) {
            const strAscending = [...res.data].sort((a, b) =>
                a.name > b.name ? 1 : -1,
            );
            if(strAscending.length>0){
                setDeviceList(strAscending.filter((x:any)=>x.cards == true));
            }            
            setIsLoading(false);
        }
        setIsLoading(false);
    }

    const loadData = async () => {
        let clearList: any = [];
        setCardList(clearList);
        setSummaryList(clearList);
        setFromDate("");
        setToDate("");
        setSummaryCBXValue(false);
        setSelectedDevice("");
        setHolderName("");
        setDeviceList(clearList);
    }

    const generateStatusView = (row: any) => {
        if(row && row.time && row.time){
            var t = new Date(row.time * 1000);
            var formatted = t.getDate()+"/"+MONTHS[(t.getMonth())]+"/"+t.getFullYear()+" "+(('0' + t.getHours()).slice(-2))+":"+(('0' + t.getMinutes()).slice(-2))+":"+(('0' + t.getSeconds()).slice(-2));
            return (<><div>{formatted.toLocaleString()}</div></>);
        }
    }
    
    const updateSelectionView = (row:any) => {
        if(row && row.Selection && row.make){
            if (row.make === MakeList.SEAGA){
                return (<><div>{ Number(row.Selection) + 101}</div></>);
            }
            else if(row.make === MakeList.TCN || row.make === MakeList.CRANEDN){
                return (<><div>{parseInt(row.Selection)}</div></>);    
            }
            else if(row.make === MakeList.VENDSTOP){
                return (<><div>{parseInt(row.Selection,16)}</div></>);
            }
            else{
                return (<><div>{row.Selection}</div></>);
            }
        }
        else{
            return (<><div></div></>);
        }
    }

    const updateSelectionName = (row:any) => {
        if(row && row['SelectionName'] != undefined){
                return (<><div>{row.SelectionName}</div></>);
        }
        else{
            return (<><div></div></>);
        }
    }

    const generateSummaryDateView = (row: any) => {
        if(row && row.date && row.date){
            var str = (row.date).toString();
            var strYear = str.substring(0, 4);
            var strMonth = str.substring(4, 6);
            var strDate = str.substring(6, 8);
            return (<><div>{strDate + "-" + strMonth + "-" + strYear}</div></>);
        }
    }

    const loadReportData = async () => {
        try {
            if (fromDate == "") {
                setErrorMsg("Please select from date");
                return false;
            }
            if (toDate == "") {
                setErrorMsg("Please select to date");
                return false;
            }
            let fDate = new Date(fromDate);
            let tDate = new Date(toDate);
            
            if (fDate > tDate) {
                setErrorMsg("From date should less than to date");
                return false;
            }
            setErrorMsg("");
            setIsLoading(true);
            let summaryFromDate = fromDate.replaceAll('-','');
            let summaryToDate = toDate.replaceAll('-','');

            console.log("tDate: ",tDate.setHours(23, 59, 0));
            var fromDateInSeconds = fDate.getTime() / 1000; //1440516958
            console.log("from Date In Seconds: ",fromDateInSeconds);

            var toDateInSeconds = tDate.getTime() / 1000; //1440516958
            
            if(summaryCBXValue){
                let filterQuery : any ="";
                if(holderName){
                    console.log("Holdername available: ",summaryCollectionName);
                    filterQuery = query(summaryColRef, where("date", ">=", Number(summaryFromDate)), where("date", "<=", Number(summaryToDate)),where("cardHolderName", "==", holderName),  orderBy("date"));
                }
                else{
                    console.log("Holder name not available");
                    filterQuery = query(summaryColRef, where("date", ">=", Number(summaryFromDate)), where("date", "<=", Number(summaryToDate)), orderBy("date"));
                }
                getDocs(filterQuery)
                .then((snapshot) => {
                    let summaryData: any = [];
                    snapshot.docs.forEach((doc:any) => {
                        summaryData.push({ ...doc.data(), id: doc.id })
                    });
                    setSummaryList(summaryData);
                    setIsLoading(false);
                })
                .catch(error => {
                    setIsLoading(false);
                    console.log("FIREBASE: Exceiption SUMMARY Data: ", error);
                });
            }
            else{
                console.log("Summary Un selected");
                // const filterData = query(colRef, where("status", "==", "processed"), where("time", ">=", fromDateInSeconds), where("time", "<=", toDateInSeconds), orderBy("time"));
                let filterQuery : any ="";
                if(holderName && selectedDevice && selectedDevice.name){
                    filterQuery = query(colRef, where("cardHolderName", "==", holderName), where("posId", "==", customerName+"_"+selectedDevice.name), where("status", "==", "processed"), where("time", ">=", fromDateInSeconds), where("time", "<=", toDateInSeconds), orderBy("time"));
                }
                else if(holderName){
                    filterQuery = query(colRef, where("cardHolderName", "==", holderName), where("status", "==", "processed"), where("time", ">=", fromDateInSeconds), where("time", "<=", toDateInSeconds), orderBy("time"));
                }
                else if(selectedDevice && selectedDevice.name){
                    filterQuery = query(colRef, where("posId", "==", customerName+"_"+selectedDevice.name), where("status", "==", "processed"), where("time", ">=", fromDateInSeconds), where("time", "<=", toDateInSeconds), orderBy("time"));
                }
                else{
                    filterQuery = query(colRef, where("status", "==", "processed"), where("time", ">=", fromDateInSeconds), where("time", "<=", toDateInSeconds), orderBy("time"));
                }

                getDocs(filterQuery)
                .then((snapshot) => {
                    let cardList: any = [];
                    snapshot.docs.forEach((doc:any) => {
                        cardList.push({ ...doc.data(), id: doc.id })
                    });
                    setCardList(cardList);
                    setIsLoading(false);
                })
                .catch(error => {
                    setIsLoading(false);
                    console.log("FIREBASE: Exceiption in GetSummary Report method: ", error);
                });
            }

        }
        catch (error) {
            console.log("EXCEPTION in LoadReportData Method-Error: ", error);
        }
    }

    const downloadCSV = async () => {
        try{
            if(summaryCBXValue){
                if (summaryList ) {
                    setIsLoading(true);
                    var csvData: any = "";
                    var csvHeaders = ["SNo", "Card ID","Holder Name","UserIdentity", "Date", "Display Card Number", "Txns","Amount"];
                    var row_header = "";
                    for (var i = 0; i < csvHeaders.length; i++) {
                        row_header += '"' + csvHeaders[i] + '",';
                    }
    
                    row_header.slice(0, row_header.length - 1);
                    csvData += row_header + "\r\n";
    
                    for (var i = 0; i < summaryList.length; i++) {
                        var row = "";
                        // let sno = summaryList[i]['tableData'].id + 1;
                        let sno = i+1;
                        // cardId, cardHolderName, Employee/UserIdentity, date, displayCardNumber, txns, amount 
                        var str = summaryList[i]['date'].toString();
                        var strYear = str.substring(0, 4);
                        var strMonth = str.substring(4, 6);
                        var strDate = str.substring(6, 8);
                        let dt = strDate + "-" + strMonth + "-" + strYear;
                        row = '"' + sno + '","=""' + summaryList[i]['cardId'] + '""","' + summaryList[i]['cardHolderName'] + '","' + summaryList[i]['Employee/UserIdentity'] + '","' + dt + '","'+ summaryList[i]['displayCardNumber'] + '","' + summaryList[i]['txns'] + '","' + summaryList[i]['amount'] + '",'
                        row.slice(0, row.length - 1);
                        csvData += row + "\r\n";
                    }
    
                    const url = window.URL.createObjectURL(new Blob([csvData]));
                    const link = document.createElement('a');
                    link.href = url;
                    let fileName = "Summary_"+customerName +"_"+ fromDate + "_" + toDate + ".csv";
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                    setIsLoading(false);
                }
            }
            else{
                if (cardList ) {
                    setIsLoading(true);
                    var csvData: any = "";
                    var csvHeaders = ["SNo", "Card ID","Holder Name","Employee/UserIdentity", "Datetime","Display Card Number", "Customer", "PosID", "make","location","Selection","SlotName", "Txn Amount","Txn ID"];
    
                    var row_header = "";
                    for (var i = 0; i < csvHeaders.length; i++) {
                        row_header += '"' + csvHeaders[i] + '",';
                    }
    
                    row_header.slice(0, row_header.length - 1);
                    csvData += row_header + "\r\n";
    
                    for (var i = 0; i < cardList.length; i++) {
                        var row = "";
                        // let sno = cardList[i]['tableData'].id + 1;
                        let sno = i+1;
                        var t = new Date(cardList[i]["time"] * 1000);
                        var formatted = t.getDate()+"/"+MONTHS[(t.getMonth())]+"/"+t.getFullYear()+" "+(('0' + t.getHours()).slice(-2))+":"+(('0' + t.getMinutes()).slice(-2))+":"+(('0' + t.getSeconds()).slice(-2));

                        let selectionVal = null;
                        if(cardList[i]['Selection'] && cardList[i]['make']){
                            if (cardList[i]['make'] === MakeList.SEAGA){
                                selectionVal = Number(cardList[i]['Selection']) + 101;
                            }
                            else if(cardList[i]['make'] === MakeList.TCN || cardList[i]['make'] === MakeList.CRANEDN){
                                selectionVal = parseInt(cardList[i]['Selection']);
                            }
                            else if(cardList[i]['make'] === MakeList.VENDSTOP){
                                selectionVal = parseInt(cardList[i]['Selection'],16);
                            }
                            else{
                                selectionVal = cardList[i]['Selection'];
                            }
                        }
                        let slotName = "";
                        if(cardList[i]['SelectionName'] != undefined && cardList[i]['SelectionName']){
                            slotName = cardList[i]['SelectionName'];
                        }
                        else{
                            slotName = "";
                        }
                        row = '"' + sno + '","' + cardList[i]['cardId'] + '","' + cardList[i]['cardHolderName'] + '","' + cardList[i]['Employee/UserIdentity'] + '","' + formatted.toLocaleString() + '","' + cardList[i]['displayCardNumber'] + '","' + cardList[i]['customer'].toString() + '","' + cardList[i]['posId'] + '","' + cardList[i]['make'] + '","' + cardList[i]['location'] + '","' + selectionVal + '","' + slotName + '","' + cardList[i]['txnAmount'] + '","=""' + cardList[i]['txnId'] + '""",'
                        row.slice(0, row.length - 1);
                        csvData += row + "\r\n";
                    }
    
                    const url = window.URL.createObjectURL(new Blob([csvData]));
                    const link = document.createElement('a');
                    link.href = url;
                    let fileName = "Txns_"+customerName+"_" + fromDate + "_" + toDate + ".csv";
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                    setIsLoading(false);
                }
            }
            
        }
        catch(error){
            console.log("EXCEPTION in DownloadCSV Method-Error: ", error);
        }
    }

    const disableFeatureDate = () => {
        var today = new Date();
        const dd = String(today.getDate() + 0).padStart(2, "0");
        const mm = String(today.getMonth() + 1).padStart(2, "0");
        const yyyy = today.getFullYear();
        return yyyy + "-" + mm + "-" + dd;
    };

    const disablePastDate = () => {
        var today = new Date();
        var priorDate = new Date(new Date().setDate(today.getDate() - 90));
        const dd = String(priorDate.getDate() + 1).padStart(2, "0");
        const mm = String(priorDate.getMonth() + 1).padStart(2, "0"); //January is 0!
        const yyyy = priorDate.getFullYear();
        return yyyy + "-" + mm + "-" + dd;
    };

    const handleHolderNameChange = (event:any) => {
        const result = event.target.value.replace(/[^a-zA-Z0-9_-]/g, '');
        // setValue("DisplayCardNumber", result);
        setHolderName(result);
    };

    const txnColumns = [
        { title: '#', field: 'tableData.id', render: (rowData: any) => rowData.tableData.id + 1, headerStyle: { width: "5%" }, cellStyle: { width: "5%", maxWidth: "5%", overflow: "hidden" }, width: "5%" },
        { title: 'CardID', field: 'cardId', headerStyle: { width: "15%" }, cellStyle: { width: "15%", maxWidth: "15%", overflow: "hidden" }, width: "15%" },
        { title: 'Holder Name', field: 'cardHolderName', headerStyle: { width: "15%" }, cellStyle: { width: "15%", maxWidth: "15%", overflow: "hidden" }, width: "15%" },
        { title: 'UserIdentity', field: 'Employee/UserIdentity', headerStyle: { width: "10%" }, cellStyle: { width: "10%", maxWidth: "10%", overflow: "hidden" }, width: "10%" },
        { title: 'DateTime', field: 'date', headerStyle: { width: "30%" }, cellStyle: { width: "30%", maxWidth: "30%", overflow: "hidden" }, width: "30%",
            render: (rowData: any) => generateStatusView(rowData)
        },
        { title: 'Display Card Number', field: 'displayCardNumber', headerStyle: { width: "5%" }, cellStyle: { width: "10%", maxWidth: "10%", overflow: "hidden" }, width: "10%" },
        { title: 'PosId', field: 'posId', headerStyle: { width: "5%" }, cellStyle: { width: "5%", maxWidth: "5%", overflow: "hidden" }, width: "5%" },
        { title: 'Location', field: 'location', headerStyle: { width: "10%" }, cellStyle: { width: "10%", maxWidth: "10%", overflow: "hidden" }, width: "10%" },
        // { title: 'Selection', field: 'Selection', headerStyle: { width: "5%" }, cellStyle: { width: "5%", maxWidth: "5%", overflow: "hidden" }, width: "5%" },
        { title: 'Selection', field: 'Selection', headerStyle: { width: "5%" }, cellStyle: { width: "5%", maxWidth: "5%", overflow: "hidden" }, width: "5%",
            render: (rowData: any) => updateSelectionView(rowData)
        },
        { title: 'SlotName', field: 'SelectionName', headerStyle: { width: "5%" }, cellStyle: { width: "5%", maxWidth: "5%", overflow: "hidden" }, width: "5%",
            render: (rowData: any) => updateSelectionName(rowData)
        },
        { title: 'Txn Amount', field: 'txnAmount', headerStyle: { width: "5%" }, cellStyle: { width: "5%", maxWidth: "5%", overflow: "hidden" }, width: "5%" },
    ]

    const summaryTableColumns = [
        { title: '#', field: 'tableData.id', render: (rowData: any) => rowData.tableData.id + 1, headerStyle: { width: "5%" }, cellStyle: { width: "5%", maxWidth: "5%", overflow: "hidden" }, width: "5%" },
        { title: 'CardID', field: 'cardId', headerStyle: { width: "20%" }, cellStyle: { width: "20%", maxWidth: "20%", overflow: "hidden" }, width: "20%" },
        { title: 'Holder Name', field: 'cardHolderName', headerStyle: { width: "20%" }, cellStyle: { width: "20%", maxWidth: "20%", overflow: "hidden" }, width: "20%" },
        { title: 'UserIdentity', field: 'Employee/UserIdentity', headerStyle: { width: "20%" }, cellStyle: { width: "20%", maxWidth: "20%", overflow: "hidden" }, width: "20%" },
        { title: 'Date', field: 'date', headerStyle: { width: "15%" }, cellStyle: { width: "15%", maxWidth: "15%", overflow: "hidden" }, width: "15%",
            render: (rowData: any) => generateSummaryDateView(rowData)
        },
        { title: 'Display Card Number', field: 'displayCardNumber', headerStyle: { width: "10%" }, cellStyle: { width: "10%", maxWidth: "10%", overflow: "hidden" }, width: "10%" },
        { title: 'Txns', field: 'txns', headerStyle: { width: "5%" }, cellStyle: { width: "5%", maxWidth: "5%", overflow: "hidden" }, width: "5%" },
        { title: 'Amount', field: 'amount', headerStyle: { width: "5%" }, cellStyle: { width: "5%", maxWidth: "5%", overflow: "hidden" }, width: "5%" }
    ]

    return (
        <div className="page-heading">
            <div className="page-title">
                <div className="row">
                    <div className="col-12 col-md-6 order-md-1 order-last">
                        <h3 className='vv-color-code'>Reports</h3>
                    </div>
                    <div className="col-12 col-md-6 order-md-2 order-first">
                        <nav aria-label="breadcrumb" className="breadcrumb-header float-start float-lg-end">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">CC Management / Reports</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>

            <section id="form-and-scrolling-components">
                <Modal show={isLoading} aria-labelledby="contained-modal-title-vcenter"
                    centered backdrop="static"
                    keyboard={false}>
                    <Modal.Body> <Spinner animation="border" /> Loading... </Modal.Body>
                </Modal>

            </section>

            <section className="section">
                <div className='cc-reports-filter'>
                    <span className='filter-row-1'>
                        Summary: <input className="ios_toggle cc-summary-cbx" type="checkbox" value="" checked={summaryCBXValue} onChange={(e) => {
                            setSummaryCBXValue(e.target.checked);
                            if (e.target.checked) {
                                setSelectedDevice("");
                            }
                        }} />
                    </span>
                    <span className={"filter-row-1 " + (summaryCBXValue == true ? "is-disabled " : '')}> Device:
                        <select className='cc-report-device' onChange={(e) => {
                            if (e && e.target && e.target.value) {
                                setSelectedDevice(deviceList.find((device: any) => {
                                    if (e && e.target && e.target.value && device.name == e.target.value) {
                                        return device.name
                                    }
                                }
                                ));
                                setSummaryCBXValue(false);
                            }
                            else {
                                setSelectedDevice("");
                            }
                        }
                        }>
                            <option value="" selected={selectedDevice}>Select</option>
                            {deviceList.map((device: any) => (
                                <option value={device.name} selected={selectedDevice.name == device.name} > {device.name} </option>
                            ))}
                        </select>
                    </span>
                    <span className='filter-row-1'>
                        Holder Name: <input type="text" placeholder="Case sensitive" className='holder-name-input' name="message" onChange={handleHolderNameChange} value={holderName} />
                    </span>
                </div>

                <div className='cc-reports-filter'>
                    <span className='date-range-filter-row'>
                        <label>From:
                            <input type="date" name="fromDate" value={fromDate} className='cc-fromDate' onChange={(event) => setFromDate(event.target.value)} min={disablePastDate()} max={disableFeatureDate()} />
                        </label>
                    </span>
                    <span className='date-range-filter-row'>
                        <label>To:
                            <input type="date" name="toDate" value={toDate} className='cc-toDate' onChange={(event) => setToDate(event.target.value)} max={disableFeatureDate()} />
                        </label>
                    </span>
                    <span className='button-filter'>
                    <span className=''>
                        <Button variant="primary" style={{ verticalAlign: "baseline" }} onClick={loadReportData} >Submit</Button>
                    </span>
                    <span className={"cc-report_download " + (cardList.length == 0 ? "is-disabled " : '')}  style={{ display: !summaryCBXValue ? 'inline-block' : 'none' }} title='Download as CSV' >
                        <a href='#' onClick={() => downloadCSV()}><i className="bi bi-download" style={{ verticalAlign: "middle" }}></i></a>
                    </span>
                    <span className={"cc-report_download " + (summaryList.length == 0 ? "is-disabled " : '')}  style={{ display: summaryCBXValue ? 'inline-block' : 'none' }} title='Download as CSV' >
                        <a href='#' onClick={() => downloadCSV()}><i className="bi bi-download" style={{ verticalAlign: "middle" }}></i></a>
                    </span>
                    </span>
                    
                </div>
                <div className='row' style={{width:"100%",color:"red",textAlign:"center"}}>
                    {errorMsg}
                </div>
                <div className='report-device-table' style={{ display: !summaryCBXValue ? 'block' : 'none' }}>
                    <MaterialTable title=""
                        data={cardList}
                        columns={txnColumns}
                        options={{
                            sorting: true,
                            actionsColumnIndex: -1,
                            headerStyle: { position: 'sticky', top: 0, fontWeight: 'bold', fontSize: 15, color: "#6c757d" },
                            maxBodyHeight: 700,
                            paging: true,
                            pageSize: 10,
                            pageSizeOptions: [10, 20, 50],
                            emptyRowsWhenPaging: false,
                            columnResizable: true,
                            rowStyle: {
                                fontSize: 15,
                                font: "var(--bs-font-sans-serif)"
                            }
                        }} />
                </div>

                <div className='report-device-table' style={{ display: summaryCBXValue ? 'block' : 'none' }}>
                    <MaterialTable title=""
                        data={summaryList}
                        columns={summaryTableColumns}
                        options={{
                            sorting: true,
                            actionsColumnIndex: -1,
                            headerStyle: { position: 'sticky', top: 0, fontWeight: 'bold', fontSize: 15, color:"#6c757d" },
                            maxBodyHeight: 700,
                            paging: true,
                            pageSize: 10,
                            pageSizeOptions: [10, 20, 50],
                            emptyRowsWhenPaging: false,
                            columnResizable: true,
                            rowStyle: {
                                fontSize: 15,
                                font: "var(--bs-font-sans-serif)"
                            }
                        }} />
                </div>

            </section>

            <div className='DownloadList'>

            </div>
        </div>
    );
};
function mapStateToProps(state: any) {
    const { isLoggedIn } = state.client.isLoggedIn;
    const { message } = state.messages;
    return {
        isLoggedIn,
        user: state.client.user,
        message
    };
}

export default connect(mapStateToProps)(CCReports);