import axios from "axios";
import firebase from "firebase/compat/app";
import { auth } from '../utils/firebaseUtils'
import { getAuth, signOut } from "firebase/auth";
import jwt_decode from 'jwt-decode'
import { getData, postData, deleteData, postIdToken } from './main-service';

const API_URL = process.env.API_URL + '/memoria';
// const API_SERVICE_URL = "https://portal-apis-rup2dfg24a-el.a.run.app/api";  //To be enable For development
const API_SERVICE_URL = "/api";  //To be enable For Production

class AuthService {
  async login(username: any, password: any) {
    const checkSignIn = await auth.signInWithEmailAndPassword(username, password).then(async (userCredential: any) => {
      console.log("VV: SIGNIN METHOD-signInWithEmailAndPassword()");
      let idToken = userCredential.user.multiFactor.user.accessToken;
      let decodeData: any = jwt_decode(userCredential.user.multiFactor.user.accessToken);
      let userDetail = { userDetail: userCredential.user.multiFactor.user, decodeData: decodeData }
      localStorage.setItem("user", JSON.stringify(userDetail));
      let inputData = {
        idToken: idToken.toString(),
        csrfToken: ''
      }
      // let loginSession =  postIdToken('/sessionLogin', inputData).then((res: any) => {
      //   console.log("==SIGN IN METHOD sessionLogin API response: ", res);
      // })
      try {
        if (!decodeData.email_verified) {
          throw 'Your email not verified by admin. Please contact admin then try'
        }
        else {
          // const response = await axios.get('https://api.npms.io/v2/search?q=react');

          const article = { title: 'React POST Request Example' };
          const result = await axios.post(API_SERVICE_URL+"/sessionLogin", inputData);
          // this.setState({ articleId: response.data.id });

          // const result = async () => axios({
          //   method: "POST",
          //   url: API_SERVICE_URL+"/sessionLogin",
          //   withCredentials: true,
          //   validateStatus: function (status) {
          //     console.log("VV: SESSION LOGIN API Response STATUS is: ",status);
          //     return status < 600;
          //   },
          //   data: inputData, // data can be `string` or {object}! 
          //   // headers: { "Access-Control-Allow-Origin": "*", Authorization: "Bearer " + getToken() },
          // });
          console.log("VV: SESSION LOGIN API Response: ",result);
          console.log("VV: Login Method will return userDetails: ",userDetail);
          return userDetail;
        }
      } catch (error) {
        console.error('Exception in Login Method: Error:', error);
        return Promise.reject(error);
      }
    });
  }
  // login(username: any, password: any) {
  //   return auth.signInWithEmailAndPassword(username, password).then((userCredential: any) => {
  //     console.log("==SIGN IN METHOD");
  //     let idToken = userCredential.user.multiFactor.user.accessToken;
  //     let decodeData: any = jwt_decode(userCredential.user.multiFactor.user.accessToken);
  //     let userDetail = { userDetail: userCredential.user.multiFactor.user, decodeData: decodeData }
  //     localStorage.setItem("user", JSON.stringify(userDetail));
  //     let inputData = {
  //       idToken: idToken.toString(),
  //       csrfToken: ''
  //     }
  //     let loginSession = postIdToken('/sessionLogin', inputData).then((res: any) => {
  //       console.log("==SIGN IN METHOD sessionLogin API response: ", res);
  //     })
  //     try {
  //       console.log("==SIGN IN METHOD return");
  //       if (!decodeData.email_verified) {
  //         throw 'Your email not verified by admin. Please contact admin then try'
  //       }
  //       else {
  //         return userDetail;
  //       }
  //     } catch (e: any) {
  //       return Promise.reject(e);
  //     }
  //   });
  // }

  logout() {
    const auth = getAuth();
    signOut(auth).then(() => {
      localStorage.removeItem("user");
      let data = {}
      let loginSession = postIdToken('/sessionLogout', data).then((res: any) => {
        console.log("sessionLogout API response: ", res);
      })
    }).catch((error) => {
      console.log("Exception in SIGNOUT");
      // An error happened.
    });
    // cookies.remove(globalId, cookieOption)
  }

  register(customerData: any) {
    // return auth.signInWithEmailAndPassword(username, password).then((userCredential: any) => {

    //   console.log('userCredential', userCredential);
    //   localStorage.setItem("user", JSON.stringify(userCredential.user));
    //   return userCredential.user.multiFactor.user
    // });
    return axios.post(API_URL + "/signup", customerData);
  }

  myprofile() {
    //localStorage.getItem("user");
    //return axios.get(API_URL + '')
    return "testing";
  }
  checkVerifyToken(data: any) {
    return axios.post(API_URL + "/check-token", data);
  }
  verifyToken(data: any) {
    return axios.post(API_URL + "/create/password", data);
  }

}

export default new AuthService();