import { connect } from "react-redux";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import Alert from '../components/modules/Alert'
import { Footer } from '../components'
import Header from "../components/shared/Header";
import Sidebar from "../components/shared/Sidebar";

const LoggedInRoute = (props: any) => {
  const { isAuthenticated, messages, children } = props;
  const navigate = useNavigate()
  const location = useLocation()
  console.log('isAuthenticated', isAuthenticated)
  if (isAuthenticated == false) {
    navigate("/login");
  }

  if (isAuthenticated === true) {
    // console.log('locationlocation',location,user)
    // if(location.pathname.indexOf(`/${user.companyCode}/`)<0){
    //   navigate(`/${user.companyCode}${location.pathname}`);
    //   return null;
    // }
    // if(!user.userDetails){
    //       navigate(`/${user.companyCode}/AccessDenied`);
    //       return null;
    //  }
    // navigate("/");

    // alert("this is a logged in route, you are logged out, redirected to log in");
  }

  const loadAlert = () => {
    if (typeof messages.message === 'string')
      return (<Alert variant={messages.variant} show={messages.show} message={messages.message} />)
    else {
      const listItems = messages.message.map((msg: any, id: any) => <Alert key={id} variant={messages.variant} show={messages.show} message={msg} />);
      return listItems;
    }

  }

  return (
    <>
      {loadAlert()}
      <div id="app">
        <Sidebar></Sidebar>
        <div id="main">
          <Header isAuthenticated={isAuthenticated} />
          <div className="page-heading" style={{ padding: "20px 0px 0px 25px", border: "transparent", borderRadius: "4px", backgroundColor: "#f8f9fa" }}>

            <div className="content-wrapper container min-vh-100">
              {children ? children : <Outlet />}
            </div>
          </div>
          {/* <Footer /> */}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  // console.log('logged---in', state);
  return {
    isAuthenticated: state.client.isLoggedIn,
    user: state.client.user,
    messages: state.messages
    // showProfile:false
  }
};

export default connect(
  mapStateToProps
)(LoggedInRoute);
