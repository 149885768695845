import { connect } from 'react-redux';
import { useMemo, useEffect, useState } from 'react';
import '../../../assets/css/extra-styles.css';
import { logout } from "../../redux/actions/auth.action"
// import { getCustomerName } from "../../utils"

function Header(props: any) {
  const [showProfile, setShowProfile] = useState(false);
  const [CurrentTime,setCurrentTime] = useState("");
  // const [customerName, setCustomerName] = useState(getCustomerName(props.user)) //getCustomerName(props.user);
  useEffect(() => {
  
    //Implementing the setInterval method
    const interval = setInterval(() => {
      let cdate = new Date()
      setCurrentTime(cdate.toLocaleString('en-IN').toUpperCase().replace(',',''));
    }, 1000);

    //Clearing the interval
    return () => clearInterval(interval);
}, [CurrentTime]);

  useEffect(() => {
    // showCurrentTime();
    function handleClickOutside(event: any) {
      if (event.target.className == "bi bi-person-circle user-profile") {
        if (showProfile) {
          setShowProfile(false);
        }
        else {
          setShowProfile(true);
        }
      }
      else if (event.target.className.includes("logoutBtn")) {
        props.dispatch(logout());
      }
      else {
        setShowProfile(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
  }, []);

  const ShowHideProfile = (val: any) => {
    if (val) {
      setShowProfile(false);
    }
    else {
      setShowProfile(true);
    }
  }

  return (
    <header className="mb-5">
      <div className='row'>
        <div className='col-6'>
            {/* <span className='customer-name'>{customerName.toString()}</span> */}
        </div>
        <div className='col-6'>
        <div className="dropdown-temp">
        <div className='prof-icon-list'>
        <span className="notify-icon" style={{ marginRight: "20px" }}>
            {/* <i className="bi bi-bell-fill profile-notifications"></i> */}
            {/* 02/10/2023 05:50 */}
            {CurrentTime}
          </span>
          <span className="notify-icon" style={{ marginRight: "20px" }} title='Notifications'>
            <i className="bi bi-bell-fill profile-notifications"></i>
          </span>
          <span className="profile-icon" title='Profile'>
            <i className="bi bi-person-circle user-profile"></i>
          </span>
        </div>
        <div id="myDropdown" className="dropdown-content-temp" style={{ display: showProfile ? 'block' : 'none' }}>
          {showProfile ? <div id="myDropdown" className="dropdown-content-temp">
            <div className='profile-content'>
              <h6>
                Welcome <b style={{ fontSize: "16px" }}> {props?.user?.userDetail.displayName}</b>
              </h6>
            </div>
            <a className='logoutBtn' href="#home">
              <span className='logoutBtn'><i className="bi bi-power logoutBtn" style={{ marginTop: "5px" }}></i></span>
              <span className='logoutBtn' style={{ verticalAlign: "text-bottom", paddingLeft: "10px" }}>Logout</span>
            </a>
          </div> : null
          }
        </div>
      </div>
        </div>
      </div>
      
    </header>
  )
}
function mapStateToProps(state: any) {
  return {
    isAuthenticated: state.client.isLoggedIn,
    user: state.client.user,
    messages: state.messages
  };
}

export default connect(mapStateToProps)(Header);
