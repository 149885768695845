import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getData, getSessionDetails } from '../../services/main-service';
import { Link } from 'react-router-dom';
import { Modal, Button, Spinner } from 'react-bootstrap';
import { isSadmin } from '../../utils';
import { logout } from "../../redux/actions/auth.action";
import Swal from 'sweetalert2'
import { useForm } from "react-hook-form";
import MaterialTable from 'material-table';
import * as XLSX from 'xlsx'


import {
    getFirestore,
    collection,
    getDocs,
    getDoc,
    addDoc,
    deleteDoc,
    doc,
    setDoc,
    updateDoc,
    FieldPath
} from "firebase/firestore";
import { getCustomerName } from "../../utils";
import { async } from '@firebase/util';


function CCManagement(props: any) {
    const customerName = getCustomerName(props.user);
    const db = getFirestore();
    const db_stateMachine = getFirestore();
    const collectionName = customerName + "_card_management";
    const colName_StateMachine = customerName + "_cc_state_machine";
    const colRef_StateMachine = collection(db_stateMachine, colName_StateMachine);
    const [file, setFile] = useState();
    const [showModalImport, setShowModalImport] = useState(false);
    const [isExcel, setIsExcel] = useState(false);
    const [finalResult, setFinalResult] = useState(false);
    const colRef = collection(db, collectionName);
    const handleCloseModalImport = () => {
        setShowModalImport(false);
        setIsExcel(false);
        setFinalResult(false);
        loadData();
    }
    const [errorMsgImport, setErrorMsgImport] = useState('');
    const { 
        register: register2, 
        handleSubmit: handleSubmitImport, formState: { errors2 } }: any = useForm(
        {
            mode: "onChange",
        }
    );
    const [cardListImport, setCardListImport] = useState<any>([]);
    const [finalUploadedList, setFinalUploadedList] = useState<any>([]);
    const [isUploadValid, setIsUploadValid] = useState(false);
    let uploadErrorMsg = "";

    const [maxDailyDocsList, setMaxDailyDocsList] = useState<any>([]);
    const [maxDailyDoc, setMaxDailyDoc] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [cardList, setCardList] = useState<any>([]);
    const [show, setShow] = useState(false);
    const [currentDevice, setCurrentDevice] = useState<any>({})
    const patternValue = /^[ A-Za-z0-9_+-]*$/
    const [errorMsg, setErrorMsg] = useState('');
    const [isEditmode, setIsEditMode] = useState(false);
    const handleClose = () => setShow(false);
    const { register, handleSubmit, setValue, watch, formState: { errors } }: any = useForm(
        {
            mode: "onChange",
        }
    );
    

    const [IsActive, setIsActive] = useState(true);
    const [MobilePhoneNumber, setMobilePhoneNumber] = useState(0);
    const [CardId, setCardId] = useState(0);
    const [Currency, setCurrency] = useState("");
    const [Employee, setEmployee] = useState("");
    const [MonthlyReload, setMonthlyReload] = useState(0);
    const [IsCashRevalue, setIsCashRevalue] = useState(false);
    const [DisplayCardNumber, setDisplayCardNumber] = useState("");
    const [MaxDaily, setMaxDaily] = useState(10);
    const [IsAccumulated, setIsAccumulated] = useState(false);
    const [CardType, setCardType] = useState("");
    const [CardHolderNameBak, setCardHolderName] = useState("");
    const [MaxMonthly, setMaxMonthly] = useState("");
    const [Customer, setCustomer] = useState("");
    const [CardCredit, setCardCredit] = useState(0);
    const [created_at, setcreated_at] = useState("");
    const [EMailAddress, setEMailAddress] = useState("");
    const [IsCashRevalueDisabled, setIsCashRevalueDisabled] = useState(false);
    const [IsAccumulatedDisabled, setIsAccumulatedDisabled] = useState(false);

    const charecterLength = {
        CARDID: 25,
        DISPLAYCARDNUMBER: 25,
        CARDHOLDERNAME: 25,
        EMPLOYEE: 70,
        MAXDAILY: 4,
        MAXMONTHLY: 5,
        MOBILENUMBER: 10,
        MONTHLYRELOAD: 5,
        WEEKDAYMAXAMT: 4,
        WEEKDAYMAXTXN: 4,
        CARDCREDIT: 5
    };
    const [maxLengthItems, setMaxLengthItems] = useState<any>(charecterLength);

    const changeCardId = (event: any) => {
        const result = event.target.value.replace(/\D/g, '');
        setValue("CardId", result);
    };
    const changeDisplayCardNumber = (event: any) => {
        const result = event.target.value.replace(/[^a-zA-Z0-9_-]/g, '');
        setValue("DisplayCardNumber", result);
    };
    const changeCardHolderName = (event: any) => {
        const result = event.target.value.replace(/[^a-zA-Z0-9_-]/g, '');
        setValue("CardHolderName", result);
    };
    const changeEmployee = (event: any) => {
        const result = event.target.value.replace(/[^a-zA-Z ]/g, "");
        setValue("Employee", result);
    };
    const changeMaxDaily = (event: any) => {
        const result = event.target.value.replace(/\D/g, '');
        setValue("MaxDaily", result);
    };
    const changeMaxMonthly = (event: any) => {
        const result = event.target.value.replace(/\D/g, '');
        setValue("MaxMonthly", result);
    };
    const handleEmail = (event: any) => {
        if (!isValidEmail(event.target.value)) {
            setErrorMsg('Enter valid Email');
            setValue("EMailAddress", "");
        } else {
            setErrorMsg("");
            setValue("EMailAddress", event.target.value);
        }
    };
    const changeMobileNumber = (event: any) => {
        const result = event.target.value.replace(/\D/g, '');
        setValue("MobilePhoneNumber", result);
    };
    const changeMonthlyReload = (event: any) => {
        const result = event.target.value.replace(/\D/g, '');
        setValue("MonthlyReload", result);
    };

    const changeCardCredit = (event: any) => {
        const result = event.target.value.replace(/\D/g, '');
        setValue("CardCredit", result);
    };
    function isValidEmail(email: any) {
        return /\S+@\S+\.\S+/.test(email);
    }
    const changeIsActive = (event: any) => {
        setValue("IsActive", event.target.checked);
        if(!event.target.checked){
            setValue("IsCashRevalue", false);
            setValue("IsAccumulated", false);
            setIsCashRevalueDisabled(true);
            setIsAccumulatedDisabled(true);
        }
        else{
            setIsCashRevalueDisabled(false);
            setIsAccumulatedDisabled(false);
        }
    };

    useEffect(() => {
        setIsLoading(true);
        loadData();
    }, [])

    const loadData = async () => {
        getDocs(colRef)
            .then((snapshot) => {
                let cardList: any = [];
                snapshot.docs.forEach((doc) => {
                    cardList.push({ ...doc.data(), id: doc.id })
                });
                setCardList(cardList);
                setIsLoading(false);
            })
            .catch(error => {
                console.log("FIREBASE: Exceiption in getDocs method: Error is: ", error);
                setIsLoading(false);
            });

    }
    const downloadData = async() =>{
        try{
            setIsLoading(true);
            if(cardList.length>0){
                var myFile = customerName + "_cc.xlsx";
                var myWorkSheet = XLSX.utils.json_to_sheet(cardList);
                var myWorkBook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(myWorkBook, myWorkSheet, "SHEET1");
                XLSX.writeFile(myWorkBook, myFile);
                setIsLoading(false);
            }
        }
        catch(error){
            setIsLoading(false);
            console.log("Exception: Download method invoked-Error: ",error);
        }
    }
    const importData = async () => {
        setIsUploadValid(false);
        setShowModalImport(true);
        loadData_statemachine();
    }

    const loadData_statemachine = async () => {
        getDocs(colRef_StateMachine)
            .then((snapshot_StateMachine) => {
                let docIDList: any = [];
                snapshot_StateMachine.docs.forEach((docsm) => {
                    docIDList.push(docsm.id);
                });
                setMaxDailyDocsList(docIDList);
            })
            .catch(error => {
                console.log("FIREBASE: getDocs-STATE MACHINE-Exceiption in Getdocs STATE MACHINE method: Error is: ", error);
            });

        }

    const get_statemachine = async (cardeID:any) => {
        var toDate = new Date();
        const dd = String(toDate.getDate()).padStart(2, "0");
        const mm = String(toDate.getMonth() + 1).padStart(2, "0"); //January is 0!
        const yyyy = toDate.getFullYear();
        let document_id = cardeID+"_"+ yyyy + mm + dd;    
        let docRef = doc(db_stateMachine,colName_StateMachine,document_id);
        getDoc(docRef)
            .then((snapshot_StateMachine) => {
                if(snapshot_StateMachine.exists()){
                    let docIDList: any = [];
                    docIDList.push(snapshot_StateMachine.data());
                    setMaxDailyDoc(docIDList);
                }
            })
            .catch(error => {
                console.log("FIREBASE: getDocs-STATE MACHINE-Exceiption in Getdocs STATE MACHINE method: Error is: ", error);
            });

        }

    const duplicateDevice = (device:any) =>{
        setCurrentDevice({});
        setErrorMsg("");
        delete device['id'];
        setIsEditMode(false);
        setValue("Customer", device.Customer);
        setValue("CardId", "");
        setValue("DisplayCardNumber", device.DisplayCardNumber);
        setValue("CardHolderName", device.CardHolderName);
        setValue("Currency", "INR");
        setValue("CardType", device.CardType);
        setValue("Employee", device['Employee/UserIdentity']);
        setValue("EMailAddress", device['E-MailAddress']);
        setValue("MaxDaily", device.MaxDaily);
        setValue("MaxMonthly", device.MaxMonthly);
        setValue("MobilePhoneNumber", device.MobilePhoneNumber);
        setValue("MonthlyReload", device.MonthlyReload);
        setValue("CardCredit", device.CardCredit);
        setValue("created_at", "");
        setValue("IsActive", device.IsActive);
        setValue("IsCashRevalue", device.IsCashRevalue);
        setValue("IsAccumulated", device.IsAccumulated);
        if(!device.IsActive){
            setIsCashRevalueDisabled(true);
            setIsAccumulatedDisabled(true);
        }
        else{
            setIsCashRevalueDisabled(false);
            setIsAccumulatedDisabled(false);
        }
        setShow(true);
    }

    const editDevice = (device: any) => {
        setErrorMsg("");
        if (device && device.id) {
            setCurrentDevice(device);
            setIsEditMode(true);
            setValue("Customer", device.Customer);
            setValue("IsActive", device.IsActive);
            setValue("IsCashRevalue", device.IsCashRevalue);
            setValue("IsAccumulated", device.IsAccumulated);
            setValue("CardId", device.CardId);
            setValue("Employee", device['Employee/UserIdentity']);
            setValue("MobilePhoneNumber", device.MobilePhoneNumber);

            setValue("MonthlyReload", device.MonthlyReload);
            setValue("DisplayCardNumber", device.DisplayCardNumber);
            setValue("MaxDaily", device.MaxDaily);
            setValue("CardType", device.CardType);
            setValue("CardHolderName", device.CardHolderName);
            setValue("MaxMonthly", device.MaxMonthly);
            setValue("CardCredit", device.CardCredit);        
            setValue("EMailAddress", device['E-MailAddress']);

            if(!device.IsActive){
                setIsCashRevalueDisabled(true);
                setIsAccumulatedDisabled(true);
            }
            else{
                setIsCashRevalueDisabled(false);
                setIsAccumulatedDisabled(false);
            }

            get_statemachine(device.CardId);
            if (device && device.created_at && device.created_at.seconds) {
                var myDate = new Date(device.created_at.seconds * 1000);
                setValue("created_at", myDate);
            }
        }
        else {
            setCurrentDevice({});
            setIsEditMode(false);
            let defCustomer = getCustomerName(props.user); //getCustomerName(props.user);
            setValue("CardId", "");
            setValue("MobilePhoneNumber", "");
            setValue("Customer", defCustomer);
            setValue("Employee", "");
            setValue("IsActive", true);
            setValue("IsCashRevalue", false);
            setValue("IsAccumulated", false);
            setIsCashRevalueDisabled(false);
            setIsAccumulatedDisabled(false);
            setValue("created_at", "");

            setValue("MonthlyReload", "");
            setValue("DisplayCardNumber", "");
            setValue("MaxDaily", "");
            setValue("CardType", "");
            setValue("CardHolderName", "");
            setValue("MaxMonthly", "");
            setValue("CardCredit", "");        
            setValue("EMailAddress", "");
        }
        setShow(true);
        setValue("Currency", "INR");
    }

    const onSubmitUpload = async (event: any) => {
        setIsUploadValid(false);
        setIsLoading(true);
        let bak_uploadingData =  cardListImport;

        let successRecordCount = 0;
        let totalRecordCount = bak_uploadingData.length;
        bak_uploadingData.forEach((element:any) => {
            //update on db start
            if (element && element.id) {
                const docRef = doc(db, collectionName, element.id);
                if(!element['Employee/UserIdentity']){
                    element['Employee/UserIdentity'] = "";
                }
                if(!element.MobilePhoneNumber){
                    element.MobilePhoneNumber = "";
                }
                if(!element['E-MailAddress']){
                    element['E-MailAddress'] = "";
                }
                updateDoc(docRef, 
                    new FieldPath('Employee/UserIdentity'), element['Employee/UserIdentity'],
                    "DisplayCardNumber", element.DisplayCardNumber,
                    "CardCredit", Number(element.CardCredit),
                    "MonthlyReload", Number(element.MonthlyReload),
                    "IsActive", Boolean(element.IsActive),
                    "CardType", element.CardType,
                    "MaxDaily", Number(element.MaxDaily),
                    "Currency", element.Currency,
                    "Customer", element.Customer,
                    "E-MailAddress", element['E-MailAddress'],
                    "IsCashRevalue", Boolean(element.IsCashRevalue),
                    "IsAccumulated", Boolean(element.IsAccumulated),
                    "CardHolderName", element.CardHolderName,
                    "MaxMonthly", Number(element.MaxMonthly),
                    "MobilePhoneNumber", element.MobilePhoneNumber,
                    "updated_at", new Date()
                    )
                    .then(docRef => {
                        cardListImport.find((i:any)=>i.SNo == element.SNo && i.CardCredit == element.CardCredit).UploadStatus = "UPDATED"
                        successRecordCount = successRecordCount +1;
                        if(element.IsCashRevalue){
                            var toDate = new Date();
                            const dd = String(toDate.getDate()).padStart(2, "0");
                            const mm = String(toDate.getMonth() + 1).padStart(2, "0"); //January is 0!
                            const yyyy = toDate.getFullYear();
                            let document_id = element.CardId+"_"+ yyyy + mm + dd;
                            var isDocumentAvailable = maxDailyDocsList.filter(function(card:any){
                                if(card === document_id){
                                    return card;
                                }
                            });
                            //To update maxDaily if document is created for this card id
                            if(isDocumentAvailable.length>0){
                                const docRef_stateMachine = doc(db_stateMachine, colName_StateMachine, isDocumentAvailable[0]);
                                // const docRef_stateMachine = doc(db_stateMachine, colName_StateMachine, document_id);
                                const input_stateMachine = {
                                    "MaxDaily": Number(element.MaxDaily)
                                };
                                setDoc(docRef_stateMachine, input_stateMachine)
                                    .then(stateMachine => {
                                        // console.log("State machine updated successfully for SNo: ", element.SNo);
                                    })
                                    .catch(excep => {
                                        console.log("Exception during stateMachine update", excep);
                                    });
                                }
                                else{
                                    console.log("State machine document id is not available for this SNo:", element.SNo);
                                }
                        }
                    })
                    .catch(error => {
                        successRecordCount = successRecordCount +1;
                        console.log("Exception occured when uploading bulk data to update: ", error);
                        cardListImport.find((i:any)=>i.SNo == element.SNo && i.CardCredit == element.CardCredit).UploadStatus = "EXCEPTION"
                    });
            }
            else{
                if(!element['Employee/UserIdentity']){
                    element['Employee/UserIdentity'] = "";
                }
                if(!element.MobilePhoneNumber){
                    element.MobilePhoneNumber = "";
                }
                if(!element['E-MailAddress']){
                    element['E-MailAddress'] = "";
                }
                const inputData = {
                    "DisplayCardNumber": element.DisplayCardNumber,
                    "Employee/UserIdentity": element['Employee/UserIdentity'],
                    "CardCredit": Number(element.CardCredit),
                    "MonthlyReload": Number(element.MonthlyReload),
                    "IsActive": Boolean(element.IsActive),
                    "CardType": element.CardType,
                    "MaxDaily": Number(element.MaxDaily),
                    "Currency": element.Currency,
                    "Customer": element.Customer,
                    "CardId": (element.CardId).toString(),
                    "E-MailAddress": element['E-MailAddress'],
                    "IsCashRevalue": Boolean(element.IsCashRevalue),
                    "IsAccumulated": Boolean(element.IsAccumulated),
                    "CardHolderName": element.CardHolderName,
                    "MaxMonthly": Number(element.MaxMonthly),
                    "MobilePhoneNumber": element.MobilePhoneNumber,
                    "created_at" : new Date()
                };
                // Add document
                addDoc(colRef, inputData)
                    .then(() => {
                        successRecordCount = successRecordCount +1;
                        cardListImport.find((i:any)=>i.SNo == element.SNo && i.CardCredit == element.CardCredit).UploadStatus = "CREATED"
                        // loadData();
                    })
                    .catch((error) => {
                        successRecordCount = successRecordCount +1;
                        cardListImport.find((i:any)=>i.SNo == element.SNo && i.CardCredit == element.CardCredit).UploadStatus = "EXCEPTION"
                        console.log("Exceiption in addDoc method: Error: ", error);
                    });
            }
        });


        const saveInterval = setInterval(() => {
            if(successRecordCount >= totalRecordCount){
                clearInterval(saveInterval);
                setFinalUploadedList(cardListImport);
                setFinalResult(true);
                setIsLoading(false);
                loadData();
            }
        },100);        
    }

    function processExcel(data:any) {
        try{
            //Read the Excel File data.
            const workbook = XLSX.read(data, {type: 'binary'});
            const firstSheet = workbook.SheetNames[0];
            //Read all rows from First Sheet into an JSON array.
            let rowsObject = XLSX.utils.sheet_to_json(
                workbook.Sheets[workbook.SheetNames[0]]
            );
            let inValidRecordAvailable = false;
            if(rowsObject.length>0){
                setIsExcel(true);
                var i = 0;
                rowsObject.forEach((x:any)=>{
                   let rec_error_msg = "";
                   if(!x['CardId']){
                        rec_error_msg = 'Enter valid CardId'
                    }
                    if(x['id']){
                        //updating
                    }
                    else{
                     if(x['CardId']){
                         var existingCard = cardList.filter(function(card:any){
                             if(card.CardId === x['CardId']){
                                 return card;
                             }
                         });
                         if(existingCard.length>0){
                             rec_error_msg = 'Card ID already exist'
                         }
                     }
                    }

                    if(!x['DisplayCardNumber']){
                        rec_error_msg = rec_error_msg != "" ? rec_error_msg + ', Enter valid display card number' : 'Enter valid display card number';
                    }
                    if(!x['CardHolderName']){
                        rec_error_msg = rec_error_msg != "" ? rec_error_msg + ', Enter valid card holder name' : 'Enter valid card holder name';
                    }
                    if(!x['Currency']){
                        rec_error_msg = rec_error_msg != "" ? rec_error_msg + ', Select Currency' : 'Select Currency';
                    }
                    if(!x['MaxMonthly']){
                        rec_error_msg = rec_error_msg != "" ? rec_error_msg + ', Enter valid max monthly amount' : 'Enter valid max monthly amount';
                    }
                    if(Number(x['MaxDaily']) > Number(x['MaxMonthly'])){
                        rec_error_msg = rec_error_msg != "" ? rec_error_msg + ', Max Daily amount should be less than Max Monthly' : 'Max Daily amount should be less than Max Monthly';
                    }
                    if(!x['MonthlyReload']){
                        rec_error_msg = rec_error_msg != "" ? rec_error_msg + ', Enter valid Monthly Reload' : 'Enter valid Monthly Reload';
                    }
                    if(!x['CardCredit']){
                        rec_error_msg = rec_error_msg != "" ? rec_error_msg + ', Enter valid Card Credit' : 'Enter valid Card Credit';
                    }
                    i = i+1;
                    if(rec_error_msg){
                        if(!inValidRecordAvailable){
                            inValidRecordAvailable = true;
                        }
                        x['UploadStatus'] = "ERROR";
                        x['SNo'] = i;
                        x['UploadMsg'] = rec_error_msg;
                    }
                    else{
                        x['UploadStatus'] = "VALID";
                        x['SNo'] = i;
                        x['UploadMsg'] = "";
                    }
                   //validation ending
                });
                if(inValidRecordAvailable){
                    setIsUploadValid(false);
                }
                else{
                    setIsUploadValid(true);
                }
                setCardListImport(rowsObject);
                setIsLoading(false);
            }
            else{
                setIsExcel(false);
                setIsLoading(false);               
            }
        }
        catch(error){
            setIsLoading(false);
            console.log("Exception: File selection method-Error: ",error);
        }
        
    }

    function handleChange(event:any) {
        //check file type and file name 
        // if (event.target.files[0].name.toLowerCase() == customerName+".xlsx"){
            setIsLoading(true);
            setFile(event.target.files[0]);
            let fileName = event.target.files[0].name;
            if (typeof (FileReader) !== 'undefined') {
                const reader = new FileReader();
                if (reader.readAsBinaryString) {
                    reader.onload = (e) => {
                        processExcel(reader.result);
                    };
                    reader.readAsBinaryString(event.target.files[0]);
                }
            } else {
                setIsLoading(false);
                return false;
            }
    }

    const onSubmit = async (data: any) => {
        //validation part
        if(!data.CardId){
            setErrorMsg('Enter valid CardId');
            return false;
        }
        if(!data.DisplayCardNumber){
            setErrorMsg('Enter valid display card number');
            return false;
        }
        if(!data.CardHolderName){
            setErrorMsg('Enter valid card holder name');
            return false;
        }

        if(!data.Employee){
            data.Employee = "";
        }
        if(!data.Currency){
            setErrorMsg('Select Currency');
            return false;
        }
        if(!data.EMailAddress){
            data.EMailAddress = "";
        }
        
        if(!data.MaxDaily){
            data.MaxDaily = 0;
        }
        if(!data.MaxMonthly){
            setErrorMsg('Enter valid max monthly amount');
            return false;
        }
        if(Number(data.MaxDaily) > Number(data.MaxMonthly)){
            setErrorMsg('Max Daily amount should be less than Max Monthly');
            return false;
        }
        if(!data.MobilePhoneNumber){
            data.MobilePhoneNumber = "";
        }
        if(!data.MonthlyReload){
            setErrorMsg('Enter valid Monthly Reload');
            return false;
        }
        if(!data.CardCredit){
            setErrorMsg('Enter valid Card Credit');
            return false;
        }
        setErrorMsg("");
        
        if (currentDevice && currentDevice.id) {
            setIsLoading(true);
            setShow(false);
            const docRef = doc(db, collectionName, currentDevice.id);
            // await updateDoc(docRef, new FieldPath('Employee/UserIdentity'), data.Employee)
            await updateDoc(docRef, 
                new FieldPath('Employee/UserIdentity'), data.Employee,
                'DisplayCardNumber', data.DisplayCardNumber,
                'CardCredit', Number(data.CardCredit),
                'MonthlyReload', Number(data.MonthlyReload),
                'IsActive', Boolean(data.IsActive),
                'CardType', data.CardType,
                'MaxDaily', Number(data.MaxDaily),
                'Currency', data.Currency,
                'Customer', data.Customer,
                'E-MailAddress', data.EMailAddress,
                'IsCashRevalue', Boolean(data.IsCashRevalue),
                'IsAccumulated', Boolean(data.IsAccumulated),
                'CardHolderName', data.CardHolderName,
                'MaxMonthly', Number(data.MaxMonthly),
                'MobilePhoneNumber', data.MobilePhoneNumber,
                'updated_at', new Date())
                .then(docRef => {
                    loadData();
                    if(data.IsCashRevalue){
                        var toDate = new Date();
                        const dd = String(toDate.getDate()).padStart(2, "0");
                        const mm = String(toDate.getMonth() + 1).padStart(2, "0"); //January is 0!
                        const yyyy = toDate.getFullYear();
                        let document_id = currentDevice.CardId+"_"+ yyyy + mm + dd;
                        //To update maxDaily if document is created for this card id
                        if(maxDailyDoc.length>0){
                            const docRef_stateMachine = doc(db_stateMachine, colName_StateMachine, document_id);
                            const input_stateMachine = {
                                "MaxDaily": Number(data.MaxDaily)
                            };
                            setDoc(docRef_stateMachine, input_stateMachine)
                                .then(stateMachine => {
                                    clearFormData();
                                     Swal.fire({
                                        title: `Successfully updated`,
                            
                                    }).then(async (result) => {
                                        setShow(false);
                                    });
                                })
                                .catch(excep => {
                                    setIsLoading(false);
                                    console.log("Exception during stateMachine update", excep);
                                });
                            }
                            else{
                                setIsLoading(false);
                                clearFormData();
                                Swal.fire({
                                    title: `Successfully updated`,
                        
                                }).then(async (result) => {
                                    setShow(false);
                                });
                            }
                    }
                    else{
                        setIsLoading(false);
                         clearFormData();
                        Swal.fire({
                            title: `Successfully updated`,
                
                        }).then(async (result) => {
                            setShow(false);
                        });
                    }
                })
                .catch(error => {
                    console.log("Exception: ", error);
                    setIsLoading(false);
                });
        } 
        else {
            let deviceNames:any = [];
            var existingCard = cardList.filter(function(card:any){
                if(card.CardId === data.CardId){
                    return card;
                }
            });
            if(existingCard.length>0){
                setErrorMsg('Card ID already exist');
                return false;
            }
            else{
                setErrorMsg('');
                setShow(false);
                const inputData = {
                    "DisplayCardNumber": data.DisplayCardNumber,
                    "Employee/UserIdentity": data.Employee,
                    "CardCredit": Number(data.CardCredit),
                    "MonthlyReload": Number(data.MonthlyReload),
                    "IsActive": Boolean(data.IsActive),
                    "CardType": data.CardType,
                    "MaxDaily": Number(data.MaxDaily),
                    "Currency": data.Currency,
                    "Customer": data.Customer,
                    "CardId": (data.CardId).toString(),
                    "E-MailAddress": data.EMailAddress,
                    "IsCashRevalue": Boolean(data.IsCashRevalue),
                    "IsAccumulated": Boolean(data.IsAccumulated),
                    "CardHolderName": data.CardHolderName,
                    "MaxMonthly": Number(data.MaxMonthly),
                    "MobilePhoneNumber": data.MobilePhoneNumber,
                    "created_at": new Date()
                };
                // Add document
                addDoc(colRef, inputData)
                    .then(() => {
                        setShow(false);
                        loadData();
                        clearFormData();
                        Swal.fire({
                            title: `Successfully Added`,
                
                        }).then(async (result) => {
                            setShow(false);
                        });
                    })
                    .catch((error) => {
                        console.log("FIREBASE: Exceiption in addDoc method: Error: ", error);
                    });
            }
        }
    }

    const clearFormData = () => {
        setMobilePhoneNumber(0);
        setCardId(0);
        setCurrency("");
        setEmployee("");
        setMonthlyReload(0);
        setIsCashRevalue(false);
        setDisplayCardNumber("");
        setMaxDaily(0);
        setIsAccumulated(false);
        setCardType("");
        setCardHolderName("");
        setMaxMonthly("");
        setCustomer("");
        setCardCredit(0);
        setEMailAddress("");
        setcreated_at("");
        let clearList: any = [];
        setMaxDailyDocsList(clearList);
        setMaxDailyDoc(clearList);
    }

    const generateStatusView = (status: any) => {
        if (status) {
            return (<>
                <div className='card-status-up'>✓</div>
            </>)
        }
        else {
            return (<>
                <div className='card-status-down'>x</div>
            </>)
        }
    }

    const deviceColumns = [
        { title: '#', field: 'tableData.id', render: (rowData: any) => rowData.tableData.id + 1, headerStyle: { width: "5%" }, cellStyle: { width: "5%", maxWidth: "5%", overflow: "hidden" }, width: "5%" },
        { title: 'Display Number', field: 'DisplayCardNumber', headerStyle: { width: "20%" }, cellStyle: { width: "15%", maxWidth: "15%", overflow: "hidden" }, width: "15%" },
        { title: 'Holder Name', field: 'CardHolderName', headerStyle: { width: "20%" }, cellStyle: { width: "25%", maxWidth: "25%", overflow: "hidden" }, width: "25%" },
        { title: 'Card ID', field: 'CardId', headerStyle: { width: "25%" }, cellStyle: { width: "25%", maxWidth: "25%", overflow: "hidden" }, width: "25%" },
        { title: 'Employee Name', field: 'Employee/UserIdentity', headerStyle: { width: "25%" }, cellStyle: { width: "25%", maxWidth: "25%", overflow: "hidden" }, width: "25%" },
        {
            title: 'Status', field: 'IsActive', headerStyle: { width: "5%" }, cellStyle: { width: "5%", maxWidth: "5%", overflow: "hidden" }, width: "5%",
            render: (rowData: any) => generateStatusView(rowData.IsActive),
        },
    ]

    const deletDevice = (device: any) => {
        setCurrentDevice({});
        let index: number = device.tableData.id;
        Swal.fire({
            title: `Do you want to delete ${device.CardId}?`,
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: 'Delete',
            denyButtonText: `Don't Delete`,
        }).then(async (result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {

                const docRef = doc(db, collectionName, device.id);
                deleteDoc(docRef).then(() => {
                        loadData();
                        Swal.fire({
                            title: `Successfully Deleted`,
                
                        }).then(async (result) => {
                            setShow(false);
                        });
                    });
            } else if (result.isDenied) {
                Swal.fire('Delete cancelled', '', 'info')
            }
        })
    }

    return (
        <div className="page-heading">
            <div className="page-title">
                <div className="row">
                    <div className="col-12 col-md-6 order-md-1 order-last">
                        <h3 className='vv-color-code'>CC Management</h3>
                    </div>
                    <div className="col-12 col-md-6 order-md-2 order-first">
                        <nav aria-label="breadcrumb" className="breadcrumb-header float-start float-lg-end">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">CC Management</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>

            <section id="form-and-scrolling-components">
                <Modal show={isLoading} aria-labelledby="contained-modal-title-vcenter"
                    centered backdrop="static"
                    keyboard={false}>
                    <Modal.Body> <Spinner animation="border" /> Loading... </Modal.Body>
                </Modal>

                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <span style={{display:isEditmode ? "block":"none"}}>Edit Card</span>
                            <span style={{display:!isEditmode ? "block":"none"}}>New Card</span>
                             &nbsp;&nbsp;<span className='text-danger'>{errorMsg}</span>
                            </Modal.Title>
                    </Modal.Header>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Modal.Body className='popup-content-sec'>
                            
                            <div className="modal-body">
                                <div className='row'>
                                    <div className='col-6'>
                                        <label>Customer: </label>
                                        <div className="form-group">
                                            <input type="text" disabled={true} placeholder="Customer" className="form-control"  {...register("Customer")} />
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <label>Card Id<span className='required-field'>*</span> : </label>
                                        <div className="form-group">
                                            <input type="text" disabled={isEditmode} maxLength={maxLengthItems.CARDID} placeholder="Card ID" className="form-control"  {...register("CardId")} onChange={changeCardId} />
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-6'>
                                        <label>Display Card Number<span className='required-field'>*</span> : </label>
                                        <div className="form-group">
                                            <input type="text" maxLength={maxLengthItems.DISPLAYCARDNUMBER} placeholder="Display Card Number"
                                                className="form-control"  {...register("DisplayCardNumber")} onChange={changeDisplayCardNumber} />
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <label>Card Holder Name<span className='required-field'>*</span> : </label>
                                        <div className="form-group">
                                            <input type="text" maxLength={maxLengthItems.CARDHOLDERNAME} placeholder="CardHolderName" className="form-control"
                                                {...register("CardHolderName")} onChange={changeCardHolderName} />
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-6'>
                                        <label>Currency: </label>
                                        <div className="form-group">
                                            <select className="form-control"  {...register("Currency")} disabled={true}>
                                                <option key="inr" value="INR">INR</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <label>CardType: </label>
                                        <div className="form-group">
                                            <select className="form-control"  {...register("CardType")}>
                                                <option key="Prepaid" value="Prepaid">Prepaid</option>
                                                <option key="Postpaid" value="Postpaid">Postpaid</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-6'>
                                        <label>Employee Name : </label>
                                        <div className="form-group">
                                            <input type="text" maxLength={maxLengthItems.EMPLOYEE} placeholder="Employee Name"
                                                className="form-control"  {...register("Employee")} onChange={changeEmployee} />
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <label>E-MailAddress: </label>
                                        <div className="form-group">
                                            <input type="text" placeholder="E-MailAddress"
                                                className="form-control"  {...register("EMailAddress")} onBlur={handleEmail} />
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-6'>
                                        <label>MaxDaily: </label>
                                        <div className="form-group">
                                            <input type="text" maxLength={maxLengthItems.MAXDAILY} placeholder="Max Daily"
                                                className="form-control"  {...register("MaxDaily")} onChange={changeMaxDaily} />
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <label>MaxMonthly<span className='required-field'>*</span> : </label>
                                        <div className="form-group">
                                            <input type="text" maxLength={maxLengthItems.MAXMONTHLY} placeholder="Max Monthly" className="form-control"  {...register("MaxMonthly")} onChange={changeMaxMonthly} />
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-6'>
                                        <label>Mobile/Phone Number: </label>
                                        <div className="form-group">
                                            <input type="text" maxLength={maxLengthItems.MOBILENUMBER} placeholder="Mobile/Phone Number" className="form-control"  {...register("MobilePhoneNumber")} onChange={changeMobileNumber} />
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <label>MonthlyReload<span className='required-field'>*</span> : </label>
                                        <div className="form-group">
                                            <input type="text" maxLength={maxLengthItems.MONTHLYRELOAD} placeholder="Monthly Reload"
                                                className="form-control"  {...register("MonthlyReload")} onChange={changeMonthlyReload} />
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-6'>
                                        <label>CardCredit<span className='required-field'>*</span> : </label>
                                        <div className="form-group">
                                            <input type="text" maxLength={maxLengthItems.CARDCREDIT} placeholder="Card Credit" className="form-control"  {...register("CardCredit")} onChange={changeCardCredit} />
                                        </div>
                                    </div>
                                    <div className='col-6' style={{marginTop:"30px"}}>
                                        <label><input name="diabetes" type="checkbox" id="diabetes" {...register('IsActive')} onChange={changeIsActive}/> &nbsp; Is Active</label>
                                    </div>
                                </div>
                                <div className='row' style={{display:isEditmode ? "block":"none"}}>
                                    <div className='col-6'>
                                        <label>Created on : </label>
                                        <div className="form-group">
                                            <input type="text" disabled={isEditmode} placeholder="" className="form-control"  {...register("created_at")} />
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-6'>
                                        <label><input name="diabetes" type="checkbox" id="diabetes" disabled={IsCashRevalueDisabled} {...register('IsCashRevalue')} />&nbsp; Is Cash Revalue </label>
                                    </div>
                                    <div className='col-6'>
                                        <label><input name="diabetes" type="checkbox" id="diabetes" disabled={IsAccumulatedDisabled} {...register('IsAccumulated')} /> &nbsp; IsAccumulated</label>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button variant="primary" type='submit'>
                                Save Changes
                            </Button>
                        </Modal.Footer>
                    </form>
                </Modal>

            </section>

            <section id="form-and-scrolling-components">
                <Modal show={isLoading} aria-labelledby="contained-modal-title-vcenter"
                    centered backdrop="static"
                    keyboard={false}>
                    <Modal.Body> <Spinner animation="border" /> Loading... </Modal.Body>
                </Modal>

                <Modal show={showModalImport} onHide={handleCloseModalImport}>
                    <Modal.Header closeButton>
                        <Modal.Title>Upload Cards &nbsp;&nbsp;<span className='text-danger'>{errorMsgImport}</span></Modal.Title>
                    </Modal.Header>
                    <form onSubmit={handleSubmitImport(onSubmitUpload)}>
                        <Modal.Body className='popup-content-sec'>
                            
                            <div className="modal-body">
                                <div className='row'>
                                    <div className='col-12'>
                                        <label>Upload Excel file: </label>
                                        <div className="form-group">
                                            <input type="file" onChange={handleChange} />
                                            {/* <button type="submit">Upload</button> */}
                                        </div>
                                    </div>
                                </div>
                                
                                <div className='row' style={{display:isExcel && !finalResult?"block":"none"}}>
                                    <div className='col-12'>
                                        <table className='import-excel'  style={{width:"100%"}} border={1}>
                                            <tr>
                                                <th className='import-excel-th'>SNo.</th>
                                                <th className='import-excel-th'>CardCredit</th>
                                                <th className='import-excel-th'>CardHolderName</th>
                                                <th className='import-excel-th'>CardId</th>
                                                <th className='import-excel-th'>CardType</th>
                                                <th className='import-excel-th'>Currency</th>
                                                <th className='import-excel-th'>Customer</th>
                                                <th className='import-excel-th'>DisplayCardNumber</th>
                                                <th className='import-excel-th'>MailAddress</th>
                                                <th className='import-excel-th'>Employee Name</th>
                                                <th className='import-excel-th'>IsAccumulated</th>
                                                <th className='import-excel-th'>IsActive</th>
                                                <th className='import-excel-th'>IsCashRevalue</th>
                                                <th className='import-excel-th'>MaxDaily</th>
                                                <th className='import-excel-th'>MaxMonthly</th>
                                                <th className='import-excel-th'>MobilePhoneNumber</th>
                                                <th className='import-excel-th'>MonthlyReload</th>
                                                <th className='import-excel-th'>UploadStatus</th>
                                                <th className='import-excel-th'>Error Message</th>
                                            </tr>
                                            { cardListImport.map((data: any) => (
                                                <tr style={{background:data.UploadStatus =='ERROR'?"#f1ee7e":""}}>
                                                    <td className='import-excel-td'>{data.SNo}</td>
                                                    <td className='import-excel-td'>{data.CardCredit}</td>
                                                    <td className='import-excel-td'>{data.CardHolderName}</td>
                                                    <td className='import-excel-td'>{data.CardId}</td>
                                                    <td className='import-excel-td'>{data.CardType}</td>
                                                    <td className='import-excel-td'>{data.Currency}</td>
                                                    <td className='import-excel-td'>{data.Customer}</td>
                                                    <td className='import-excel-td'>{data.DisplayCardNumber}</td>
                                                    <td className='import-excel-td'>{data['E-MailAddress']}</td>
                                                    <td className='import-excel-td'>{data['Employee/UserIdentity']}</td>
                                                    <td className='import-excel-td'>{data.IsAccumulated?"TRUE":"FALSE"}</td>
                                                    <td className='import-excel-td'>{data.IsActive?"TRUE":"FALSE"}</td>
                                                    <td className='import-excel-td'>{data.IsCashRevalue?"TRUE":"FALSE"}</td>
                                                    <td className='import-excel-td'>{data.MaxDaily}</td>
                                                    <td className='import-excel-td'>{data.MaxMonthly}</td>
                                                    <td className='import-excel-td'>{data.MobilePhoneNumber}</td>
                                                    <td className='import-excel-td'>{data.MonthlyReload}</td>
                                                    <td className='import-excel-td'>{data.UploadStatus}</td>
                                                    <td className='import-excel-td'>{data.UploadMsg}</td>

                                                </tr>
                                            ))}
                                        </table>
                                    </div>
                                </div>

                                <div className='row' style={{display:isExcel && finalResult?"block":"none"}}>
                                    <div className='col-12'>
                                        <table className='import-excel'  style={{width:"100%"}} border={1}>
                                            <tr>
                                                <th className='import-excel-th'>SNo.</th>
                                                <th className='import-excel-th'>CardCredit</th>
                                                <th className='import-excel-th'>CardHolderName</th>
                                                <th className='import-excel-th'>CardId</th>
                                                <th className='import-excel-th'>CardType</th>
                                                <th className='import-excel-th'>Currency</th>
                                                <th className='import-excel-th'>Customer</th>
                                                <th className='import-excel-th'>DisplayCardNumber</th>
                                                <th className='import-excel-th'>MailAddress</th>
                                                <th className='import-excel-th'>Employee Name</th>
                                                <th className='import-excel-th'>IsAccumulated</th>
                                                <th className='import-excel-th'>IsActive</th>
                                                <th className='import-excel-th'>IsCashRevalue</th>
                                                <th className='import-excel-th'>MaxDaily</th>
                                                <th className='import-excel-th'>MaxMonthly</th>
                                                <th className='import-excel-th'>MobilePhoneNumber</th>
                                                <th className='import-excel-th'>MonthlyReload</th>
                                                <th className='import-excel-th'>UploadStatus</th>
                                                <th className='import-excel-th'>Error Message</th>
                                            </tr>
                                            
                                            { finalUploadedList.map((data: any) => (
                                                <tr style={{background:data.UploadStatus=='UPDATED' || data.UploadStatus=='CREATED'?"#a4e3a4":"#f1ee7e"}}>
                                                    <td className='import-excel-td'>{data.SNo}</td>
                                                    <td className='import-excel-td'>{data.CardCredit}</td>
                                                    <td className='import-excel-td'>{data.CardHolderName}</td>
                                                    <td className='import-excel-td'>{data.CardId}</td>
                                                    <td className='import-excel-td'>{data.CardType}</td>
                                                    <td className='import-excel-td'>{data.Currency}</td>
                                                    <td className='import-excel-td'>{data.Customer}</td>
                                                    <td className='import-excel-td'>{data.DisplayCardNumber}</td>
                                                    <td className='import-excel-td'>{data['E-MailAddress']}</td>
                                                    <td className='import-excel-td'>{data['Employee/UserIdentity']}</td>
                                                    <td className='import-excel-td'>{data.IsAccumulated?"TRUE":"FALSE"}</td>
                                                    <td className='import-excel-td'>{data.IsActive?"TRUE":"FALSE"}</td>
                                                    <td className='import-excel-td'>{data.IsCashRevalue?"TRUE":"FALSE"}</td>
                                                    <td className='import-excel-td'>{data.MaxDaily}</td>
                                                    <td className='import-excel-td'>{data.MaxMonthly}</td>
                                                    <td className='import-excel-td'>{data.MobilePhoneNumber}</td>
                                                    <td className='import-excel-td'>{data.MonthlyReload}</td>
                                                    <td className='import-excel-td'>{data.UploadStatus}</td>
                                                    <td className='import-excel-td'>{data.UploadMsg}</td>

                                                </tr>
                                            ))}
                                        </table>
                                    </div>
                                </div>

                                <div className='row'>

                                </div>
                                
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleCloseModalImport}>
                                Close
                            </Button>
                            <Button variant="primary" type='submit'  style={{ display: isUploadValid ? 'inline-block' : 'none' }}>
                                UPLOAD
                            </Button>
                        </Modal.Footer>
                    </form>
                </Modal>

            </section>

            <section className="section">
                
                <div className='col-12 text-end' style={{marginBottom:"5px"}}>
                    <Button variant="primary mx-2" onClick={() => editDevice({})} >New</Button>
                    <Button className='mr-8' style={{display:cardList.length>0?"inline-block":"none"}} variant="primary" onClick={downloadData} >Download</Button>
                    <Button variant="primary" onClick={importData} >Upload</Button>
                    
                </div>
                
                <div className='report-device-table'>
                    <MaterialTable title=""
                        data={cardList}
                        columns={deviceColumns}
                        actions={[
                            {
                                icon: 'edit',
                                tooltip: 'Edit',
                                onClick: (event, rowData) => editDevice(rowData),
                            },
                            {
                                icon: 'file_copy',
                                tooltip: 'Copy',
                                onClick: (event, rowData) => duplicateDevice(rowData),
                            },
                            {
                                icon: 'delete',
                                tooltip: 'Delete',
                                onClick: (event, rowData) => deletDevice(rowData)
                            }
                        ]}
                        options={{
                            sorting: true,
                            actionsColumnIndex: -1,
                            headerStyle: { position: 'sticky', top: 0, fontWeight: 'bold', fontSize: 15, color: "#6c757d" },
                            maxBodyHeight: 700,
                            paging: true,
                            pageSize: 10,
                            pageSizeOptions: [10, 20, 50],
                            emptyRowsWhenPaging: false,
                            columnResizable: true,
                            rowStyle: {
                                fontSize: 15,
                                font: "var(--bs-font-sans-serif)"
                            }
                        }} />
                </div>
            </section>

            <div className='DownloadList'>

            </div>
        </div>
    );
};
function mapStateToProps(state: any) {
    const { isLoggedIn } = state.client.isLoggedIn;
    const { message } = state.messages;
    return {
        isLoggedIn,
        user: state.client.user,
        message
    };
}

export default connect(mapStateToProps)(CCManagement); 