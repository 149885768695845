import { connect } from "react-redux";
import { auth } from "../../utils/firebaseUtils";
import { logout } from "../../redux/actions/auth.action"
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { isSadmin,isCC,getCustomerName } from "../../utils";

const Sidebar = (props: any) => {
    const [showCCSubMenu, setShowCCSubMenu] = useState(false);
    const location = useLocation();
    const [show, setShow] = useState(true);
    const [isDark, setIsDark] = useState(false);
    const [customerName, setCustomerName] = useState(getCustomerName(props.user)) //getCustomerName(props.user);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
    useEffect(() => {
        if (isDark) {
            document.body.className = 'theme-dark';
        }
        else {
            document.body.className = 'theme-light';
        }
    }, [isDark])
    const changeTheme = () => {
        setIsDark(!isDark)
    }

    useEffect(() => {
        window.addEventListener("resize", () => {
            const ismobile = window.innerWidth < 1200;
            if (ismobile) {
                setShow(false);
                setIsMobile(ismobile);
            }
            else {
                setShow(true);
                setIsMobile(ismobile);
            }
        }, false);
    }, [isMobile]);

    const sideMenu = () => {
        if (show) {
            setShow(false);
        }
        else {
            setShow(true);
        }

    }

    const showHideCCSubMenu = () => {
        if (showCCSubMenu) {
            setShowCCSubMenu(false);
        }
        else {
            setShowCCSubMenu(true);
        }

    }

    return (
        <div id="sidebar" className={`${isMobile && !show ? "" : "active"}`}>
            <header className="mb-3">
                <Link to="#" onClick={() => sideMenu()} className="ms-5 burger-btn d-block d-xl-none">
                    <i className="bi bi-justify fs-3"></i>
                </Link>
            </header>
            <div className={"sidebar-wrapper " + (show ? "active" : "d-none")}>
                <div className="sidebar-header position-relative">
                    <div className="  justify-content-between align-items-center">
                        <div className="logo">
                            <Link to="/"><img src="/assets/images/logo/logo.png" alt="Logo" style={{ width: "240px", height: "auto" }} /></Link>
                        </div>
                        <div className="sidebar-toggler  x">
                            <Link to="#" onClick={() => setShow(false)} className="sidebar-hide d-xl-none d-block"><i className="bi bi-x bi-middle"></i></Link>
                        </div>
                    </div>
                </div>
                
                <div className='row'>
                    <div className='customer-name'>{customerName?customerName.toString():""}</div>
                </div>
                <hr></hr>
                <div className="sidebar-menu">
                    <ul className="menu">
                        <li className="sidebar-title">Menu</li>

                        <li className={"sidebar-item " + (location.pathname == '/' ? "active " : '')}>
                            <Link to="/" className="sidebar-link">
                                <i className="bi bi-grid-fill"></i>
                                <span>Dashboard</span>
                            </Link>
                        </li>
                        <li className={"sidebar-item " + (location.pathname == '/devices' ? "active " : '')}>
                            <Link to="/devices" className="sidebar-link"> <i className="bi bi-cpu"></i>
                                <span>Devices</span></Link>
                        </li>
                        {isSadmin(props.user) ? <li className={"sidebar-item " + (location.pathname == '/users' ? "active " : '')}>
                            <Link to="/users" className="sidebar-link"> <i className="bi bi-people-fill"></i>
                                <span>Users</span></Link>
                        </li> : null}

                        <li className={"sidebar-item " + (location.pathname == '/downloads' ? "active " : '')}>
                            <Link to="/downloads" className="sidebar-link"> <i className="bi bi-cloud-arrow-down-fill"></i>
                                <span>Downloads</span>
                            </Link>
                        </li>

                        <li className={"sidebar-item " + (location.pathname == '/reports' ? "active " : '')}>
                            <Link to="/reports" className="sidebar-link"> <i className="bi bi-table"></i>
                                <span>Reports</span>
                            </Link>
                        </li>
                        <li className={"sidebar-item " + (location.pathname == '/diagnose' ? "active " : '')}>
                            <Link to="/diagnose" className="sidebar-link"> <i className="bi bi-bug"></i>
                                <span>Diagnose</span>
                            </Link>
                        </li>
                        {isCC(props.user) ? <li className="sidebar-item has-sub">
                            <a href="#" className="sidebar-link" onClick={() => showHideCCSubMenu()}>
                                <i className="bi bi-person-badge-fill"></i>
                                <span>CC Management</span>
                            </a>
                            <ul className="submenu"  style={{ display: showCCSubMenu ? 'block' : 'none' }}>
                                <li className={"submenu-item " + (location.pathname == '/ccmanagement' ? "active " : '')}>
                                    <Link to="/ccmanagement" className="sidebar-link"> Mananagement</Link>
                                </li>
                                <li className={"submenu-item " + (location.pathname == '/ccmessages' ? "active " : '')}>
                                    <Link to="/ccmessages" className="sidebar-link"> Messages </Link>
                                </li>
                                <li className={"submenu-item " + (location.pathname == '/ccreports' ? "active " : '')}>
                                    <Link to="/ccreports" className="sidebar-link"> Reports </Link>
                                </li>
                                {/* <li className={"submenu-item " + (location.pathname == '/ccdemo' ? "active " : '')}>
                                    <Link to="/ccdemo" className="sidebar-link"> POC </Link>
                                </li> */}
                            </ul>
                            </li> : null}
                        {/* <li className={"sidebar-item " + (location.pathname == '/ccmanagement' ? "active " : '')}>
                            <Link to="/ccmanagement" className="sidebar-link"> <i className="bi bi-table"></i>
                                <span>CC Management</span>
                            </Link>
                        </li> */}
                        {/* {isCC(props.user) ? <li className={"sidebar-item " + (location.pathname == '/ccmanagement' ? "active " : '')}>
                            <Link to="#" onClick={() => showHideCCSubMenu()} className="sidebar-link"> 
                                <i className="bi bi-table"></i>
                                <span>CC Management</span>
                            </Link>
                        </li> : null} */}

                    </ul>
                </div>

                <div className="copy-right">
                    2022 © Vendviitor
                </div>
            </div>
        </div>
    );
};
const mapStateToProps = (state: any) => {
    return {
        isAuthenticated: state.client.isLoggedIn,
        user: state.client.user,
        messages: state.messages
    };
};

export default connect(mapStateToProps)(Sidebar);