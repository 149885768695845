import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getData, getSessionDetails } from '../../services/main-service';
import { Link } from 'react-router-dom';
import { Modal, Button, Spinner } from 'react-bootstrap';
import { logout } from "../../redux/actions/auth.action";
import MaterialTable from 'material-table';
import {
    getFirestore,
    collection,
    getDocs,
    query,
    orderBy,
    startAfter,
    limit,
    onSnapshot
} from "firebase/firestore";
import { getCustomerName } from "../../utils";

const MakeList = {
    SEAGA : 'Seaga' ,
    NEWSEAGA : 'NewSeaga',
    TCN : 'TCN',
    CRANEDN : 'Crane-DN',
    VENDSTOP : 'Vendstop'
} as const


function Diagnose(props: any) {
    const customerName = getCustomerName(props.user);
    const db = getFirestore();
    const collectionName = customerName + "_diagnose";
    const colRef = collection(db, collectionName);
    const [isLoading, setIsLoading] = useState(true);
    const [diagnoseList, setDiagnoseList] = useState<any>([]);
    const [deviceList, setDeviceList] = useState<any>([]);
    const [messageLimit, setmessageLimit] = useState(100);

    useEffect(() => {
        setIsLoading(true);
        loadDeviceData();
    }, [])

    const handleChange = (e:any) => {
        setmessageLimit(e.target.value);
      };

    const loadDeviceData = async () => {
        let isCookieAvailable: any = await getSessionDetails();
        if (!isCookieAvailable) {
            props.dispatch(logout());
        }
        let res: any = await getData('/devices');
        if (res.status == 200 && Array.isArray(res.data)) {
            setDeviceList(res.data);
            setTimeout(() => {
                loadData(res.data);    
            }, 1000);
        }
    }

    const loadData = async (device:any) => {
        try {
            const q = query(colRef, orderBy("dateTime", "desc"), limit(messageLimit));
            const unsubscribe = onSnapshot(q, (snapshot) => {
                // let temp_diagnoseList: any = [];
                snapshot.docChanges().forEach((change) => {
                    // console.log("Diagnose change: ", change);
                });
                refreshGrid(device);
            },
                (error) => {
                    setIsLoading(false);
                    console.log("Exception occured in Diagnose loadData: ", error);
                });
        }
        catch (error) {
            setIsLoading(false);
            console.log("EXCEPTION in Diagnose loadData Method-Error: ", error);
        }
    }

    const refreshGrid = async (device:any) => {
        const first = query(colRef, orderBy("dateTime", "desc"), limit(messageLimit));
        getDocs(first)
            .then((snapshot) => {
                let diagnoseList: any = [];
                snapshot.docs.forEach((doc) => {
                    diagnoseList.push({ ...doc.data(), id: doc.id })
                });

                diagnoseList.forEach((element:any) => {
                    let keysList = ['dateTime','Type','Device','expires','id']
                    let keysListForMessage = Object.keys(element);
                    for(var i=0;i<keysList.length;i++){
                        const index = keysListForMessage.indexOf(keysList[i]);
                        if (index > -1) { // only splice array when item is found
                            keysListForMessage.splice(index, 1); // 2nd parameter means remove one item only
                        }
                    }
                    let messageValue = "";
                    let firstItem = true;
                    let deviceName = "";
                    let cDevice = device.find((e:any)=>e.name === element['Device']);
                    if(cDevice && cDevice['make'] != undefined){
                        deviceName = cDevice['make'];
                    }
                    for(var i=0;i<keysListForMessage.length;i++){
                        let mkey = "";
                        let mkeyValue = "";
                        if(keysListForMessage[i] === "slot"){
                            mkey = keysListForMessage[i];                            
                            if (deviceName === MakeList.SEAGA){
                                mkeyValue = (Number(element[keysListForMessage[i]]) + 101).toString();
                            }
                            else if(deviceName === MakeList.TCN || deviceName === MakeList.CRANEDN){
                                mkeyValue = (parseInt(element[keysListForMessage[i]])).toString();
                            }
                            else if(deviceName === MakeList.VENDSTOP){
                                mkeyValue = (parseInt(element[keysListForMessage[i]],16)).toString();
                            }
                            else{
                                mkeyValue = element[keysListForMessage[i]].toString();
                            }
                        }
                        else{
                            mkey = keysListForMessage[i];
                            mkeyValue = element[keysListForMessage[i]].toString();
                        }
                        if(firstItem)
                        {
                            messageValue = mkey +"=" + mkeyValue;
                            firstItem = false;
                        }
                        else{
                            messageValue = messageValue + ", " + mkey +"=" + mkeyValue;
                        }
                    }
                    element["Message"] = messageValue;
                });
                setDiagnoseList(diagnoseList);
                setIsLoading(false);
            })
            .catch(error => {
                console.log("FIREBASE: Exceiption in refreshGrid method: Error is: ", error);
                setIsLoading(false);
            });
    }

    const generateStatusView = (data: any) => {
        if (data && data.dateTime && data.dateTime.seconds) {
            var myDate = new Date(data.dateTime.seconds * 1000);
            return (<><div>{myDate.toLocaleString()}</div></>);
        }
    }

    const refreshData = (data: any) => {
        setIsLoading(true);
        loadData(deviceList);
    }

    const diagnoseColumns = [
        { title: '#', field: 'tableData.id', render: (rowData: any) => rowData.tableData.id + 1, headerStyle: { width: "5%" }, cellStyle: { width: "5%", maxWidth: "5%", overflow: "hidden" }, width: "5%" },
        { title: 'Date and Time', field: 'dateTime', sorting: false, render: (rowData: any) => generateStatusView(rowData), headerStyle: { width: "35%" }, cellStyle: { width: "25%", maxWidth: "25%", overflow: "hidden" }, width: "25%" },
        { title: 'Event Type', field: 'Type', headerStyle: { width: "15%" }, cellStyle: { width: "15%", maxWidth: "15%", overflow: "hidden" }, width: "15%" },
        { title: 'Device', field: 'Device', headerStyle: { width: "15%" }, cellStyle: { width: "15%", maxWidth: "15%", overflow: "hidden" }, width: "15%" },
        { title: 'Message', field: 'Message', headerStyle: { width: "30%" }, cellStyle: { width: "30%", maxWidth: "30%", overflow: "hidden" }, width: "30%" },
    ]

    return (
        <div className="page-heading">
            <div className="page-title">
                <div className="row">
                    <div className="col-12 col-md-6 order-md-1 order-last">
                        <h3 className='vv-color-code'>Diagnose</h3>
                    </div>
                    <div className="col-12 col-md-6 order-md-2 order-first">
                        <nav aria-label="breadcrumb" className="breadcrumb-header float-start float-lg-end">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Diagnose</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>

            <section id="form-and-scrolling-components">
                <Modal show={isLoading} aria-labelledby="contained-modal-title-vcenter"
                    centered backdrop="static"
                    keyboard={false}>
                    <Modal.Body> <Spinner animation="border" /> Loading... </Modal.Body>
                </Modal>
            </section>

            <section className="section">
                <div className='common-row'>
                    <div className='refresh' title="Refresh" onClick={() => refreshData({})} ><i className="bi bi-arrow-repeat"></i></div>
                    <div className='download'><span>Select Limit</span><select value={messageLimit} onChange={handleChange}>
                            <option value="100">100</option>
                            <option value="500">500</option>
                            <option value="1000">1000</option>
                            <option value="1500">1500</option>
                            <option value="2000">2000</option>
                        </select>
                    </div>
                </div>
                <div className='report-device-table'>
                    <MaterialTable title=""
                        data={diagnoseList}
                        columns={diagnoseColumns}
                        options={{
                            sorting: true,
                            actionsColumnIndex: -1,
                            headerStyle: { position: 'sticky', top: 0, fontWeight: 'bold', fontSize: 15, color: "#6c757d" },
                            maxBodyHeight: 700,
                            paging: true,
                            pageSize: 10,
                            pageSizeOptions: [10, 20, 50],
                            emptyRowsWhenPaging: false,
                            columnResizable: true,
                            rowStyle: {
                                fontSize: 15,
                                font: "var(--bs-font-sans-serif)"
                            }
                        }} />
                </div>
            </section>
        </div>
    );
};
function mapStateToProps(state: any) {
    const { isLoggedIn } = state.client.isLoggedIn;
    const { message } = state.messages;
    return {
        isLoggedIn,
        user: state.client.user,
        message
    };
}

export default connect(mapStateToProps)(Diagnose); 